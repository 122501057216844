import React from 'react';
import {Dots} from 'react-activity';
import styles from './PFPrimaryButton.module.scss';

interface PFPrimaryButtonProps {
	text: string;
	onClick: () => void;
	style?: string;
	type?: 'submit' | 'button' | 'reset' | undefined;
	disabled?: boolean;
	isLoading?: boolean;
}
export const PFPrimaryButton: React.FC<PFPrimaryButtonProps> = ({
	onClick,
	text,
	style = {},
	type = 'button',
	isLoading,
	disabled = false,
}) => {
	return (
		<button
			disabled={disabled}
			className={`${styles.loginBtn} ${style} ${disabled && styles.disabled}`}
			onClick={onClick}
			type={type}>
			{isLoading ? <Dots /> : text}
		</button>
	);
};

export default PFPrimaryButton;
