import {initializeApp} from 'firebase/app';
import {
	getMessaging,
	getToken,
	onMessage,
	deleteToken,
} from 'firebase/messaging';
import {
	setNotificationToken,
	removeNotificationToken,
	addNotification,
} from './store/actions/NotificationActions';
import store from '../src/store/store';

const firebaseConfig = {
	apiKey: 'AIzaSyBKBDew5djZb4NuStk7Kx4GET3DiTDBmac',
	authDomain: 'performative.firebaseapp.com',
	projectId: 'performative',
	storageBucket: 'performative.appspot.com',
	messagingSenderId: '143174937861',
	appId: '1:143174937861:web:f5d21d60a97a05825e5b6c',
	measurementId: 'G-X63DTC9GBS',
};

const firebaseApp = initializeApp(firebaseConfig);

const messaging =
	navigator.userAgent.search('Safari') >= 0 &&
	navigator.userAgent.search('Chrome') < 0
		? null
		: getMessaging(firebaseApp);

if (messaging) {
	const broadcast = new BroadcastChannel('background-message');
	broadcast.onmessage = event => {
		var notification = {
			id: event.data.data.Id,
			title: event.data.notification.title,
			body: event.data.notification.body,
			isRead: event.data.data.isRead,
			isSent: event.data.data.isSent,
		};
		store.dispatch(addNotification(notification));
	};
}

export const fetchToken = () => dispatch => {
	if (messaging) {
		return getToken(messaging, {
			vapidKey:
				'BBibwFzx5QOwosMM4MMxEieFBPgFFnE5iGiq7peikToWDZqt9TZ64vPd5MqHO57lZOj5kT6RijbLD85BmG9UBsk',
		})
			.then(currentToken => {
				if (currentToken) {
					dispatch(setNotificationToken(currentToken, true));
				} else {
					console.log(
						'No registration token available. Request permission to generate one.',
					);
					dispatch(setNotificationToken(null, false));
				}
			})
			.catch(err => {
				dispatch(setNotificationToken(null, false));
				console.log('An error occurred while retrieving token. ', err);
			});
	}
};

export const onMessageListener = () =>
	new Promise(resolve => {
		if (messaging) {
			onMessage(messaging, payload => {
				resolve(payload);
			});
		}
	});

export const deleteNotificationToken = notificationToken => async dispatch => {
	if (messaging) {
		await deleteToken(messaging).then(
			notificationToken !== 'None'
				? dispatch(removeNotificationToken(notificationToken))
				: () => {},
		);
		return await dispatch(fetchToken());
	}
};
