import React from 'react';
import {Icons} from '../../../../constants/Icons';
import styles from './PFCreatorTableRow.module.scss';

interface PFCreatorTableRowProps {
	onDelete: (val: number, fullName: string | undefined) => void;
	onClickAccept: (id: number) => void;
	onClickDecline: (id: number) => void;
	onActivate: (id: number) => void;
	fullName?: string;
	facebookLink?: string;
	instagramLink?: string;
	tiktokLink?: string;
	email?: string;
	phoneNumber?: string;
	status?: string;
	id: number;
}
export const PFCreatorTableRow: React.FC<PFCreatorTableRowProps> = ({
	onDelete,
	onClickAccept,
	onClickDecline,
	onActivate,
	fullName,
	facebookLink,
	instagramLink,
	tiktokLink,
	email,
	phoneNumber,
	status,
	id,
}) => {
	const instagramValid = instagramLink !== '' && instagramLink !== null;
	const tiktokValid = tiktokLink !== '' && tiktokLink !== null;

	const handleInstagramLink = () => {
		window.open(`https://www.instagram.com/${instagramLink}/?hl=en`);
	};
	const handleTiktokLink = () => {
		window.open(`https://www.tiktok.com/@${tiktokLink}?lang=en`);
	};
	return (
		<>
			<tr className={styles.tr}>
				<td
					className={`${styles.label}`}
					style={status === 'Deleted' ? stylesObject.deleted : {}}>
					{fullName}
				</td>
				<td>
					<span
						className={instagramValid ? styles.icons : styles.grayIcons}
						style={stylesObject.instagram}
						onClick={instagramValid ? handleInstagramLink : () => {}}>
						{instagramValid ? Icons.INSTAGRAM : Icons.INSTAGRAM_GRAY}
					</span>
					<span
						className={tiktokValid ? styles.icons : styles.grayIcons}
						style={stylesObject.tiktok}
						onClick={instagramValid ? handleTiktokLink : () => {}}>
						{tiktokValid ? Icons.TIKTOK : Icons.TIKTOK_GRAY}
					</span>
				</td>
				<td style={status === 'Deleted' ? stylesObject.deleted : {}}>
					{email}
				</td>
				<td style={status === 'Deleted' ? stylesObject.deleted : {}}>
					{phoneNumber}
				</td>
				<td>
					<span
						style={
							status === 'Declined' || status === 'Deleted'
								? stylesObject.red
								: status === 'Pending'
								? stylesObject.orange
								: stylesObject.green
						}>
						{status}
					</span>
				</td>
				<td>
					<span
						className={styles.icons}
						style={stylesObject.pending}
						onClick={() => onClickAccept(id)}
						title="Accept creators request">
						{status === 'Pending' ? Icons.TICK : null}
					</span>
					{status === 'Pending' ? (
						<span
							className={styles.icons}
							onClick={() => onClickDecline(id)}
							title="Decline creators request">
							{Icons.X}
						</span>
					) : (
						<span
							className={styles.icons}
							onClick={() =>
								status === 'Deleted' ? onActivate(id) : onDelete(id, fullName)
							}
							title={
								status === 'Deleted' ? 'Activate creator' : 'Delete creator'
							}>
							{status === 'Deleted' ? Icons.RELOAD : Icons.BIN}
						</span>
					)}
				</td>
			</tr>
		</>
	);
};

const stylesObject = {
	red: {
		color: 'rgba(234, 67, 53, 1)',
	},
	orange: {
		color: 'rgba(251, 139, 2, 1)',
	},
	green: {
		color: 'rgba(104, 204, 88, 1)',
	},
	deleted: {
		color: 'rgba(234, 67, 53, 1)',
	},
	pending: {
		paddingRight: '15px',
	},
	facebook: {
		float: 'left' as 'left',
		paddingRight: '12px',
		paddingBottom: '1px',
	},
	instagram: {
		float: 'left' as 'left',
		paddingRight: '12px',
	},
	tiktok: {
		float: 'left' as 'left',
		paddingTop: '-1px',
	},
};

export default PFCreatorTableRow;
