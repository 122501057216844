// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PFTargetGoalSelectMenu_select__U6q-w {\n  width: 100%;\n  padding-top: 5px;\n  padding-bottom: 10px;\n  font-size: 14px;\n  font-family: \"Bulldog\";\n}\n.PFTargetGoalSelectMenu_select__U6q-w:hover {\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/atoms/TargetGoalSelectMenu/PFTargetGoalSelectMenu.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,gBAAA;EACA,oBAAA;EACA,eAAA;EACA,sBAAA;AACD;AAAC;EACC,eAAA;AAEF","sourcesContent":[".select {\n\twidth: 100%;\n\tpadding-top: 5px;\n\tpadding-bottom: 10px;\n\tfont-size: 14px;\n\tfont-family: 'Bulldog';\n\t&:hover {\n\t\tcursor: pointer;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "PFTargetGoalSelectMenu_select__U6q-w"
};
export default ___CSS_LOADER_EXPORT___;
