import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CampaignModals} from '../../../../constants/CampaignModals';
import {getUserStatus} from '../../../../helper/helper';
import {Creator} from '../../../../models/Creator';
import {EditUser} from '../../../../models/EditUser';
import {User} from '../../../../models/User';
import {
	activateCreatorAction,
	deleteCreator,
	editCreator,
	filterCreatorsAction,
	setFilterKeyAction,
	setSortOrderAction,
} from '../../../../store/actions/UserActions';
import {RootState} from '../../../../store/reducers/RootReducer';
import PFCreatorTableRow from '../../atoms/CreatorTableRow/PFCreatorTableRow';
import PFSortButton from '../../atoms/SortButton/PFSortButton';
import DeleteUserModal from '../DeleteUserModal/DeleteUserModal';
import styles from './CreatorTable.module.scss';

interface CreatorTableProps {
	users: Array<Creator>;
}

type SortKeys = 'email' | 'fullName' | 'status';
type SortOrder = 'asc' | 'desc';

const CreatorTable: React.FC<CreatorTableProps> = ({users}) => {
	const [sortKey, setSortKey] = useState<SortKeys>('email');
	const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
	const [fullName, setFullName] = useState<string | undefined>(undefined);
	const [id, setId] = useState(-1);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const dispatch = useDispatch<any>();

	const rowsPerPage: number = useSelector(
		(state: RootState) => state.user.rowsPerPage,
	);

	const currentPage: number = useSelector(
		(state: RootState) => state.user.currentPage,
	);

	const changeSort = (key: SortKeys) => {
		dispatch(setFilterKeyAction(key));
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
		dispatch(setSortOrderAction(sortOrder.toUpperCase()));
		dispatch(
			filterCreatorsAction(
				key,
				sortOrder.toUpperCase(),
				currentPage,
				rowsPerPage,
			),
		);
	};

	const handleDelete = (id: number, fullName: string | undefined) => {
		setShowDeleteModal(true);
		setId(id);
		setFullName(fullName);
	};

	const handleDeleteClick = () => {
		const data: User = {
			id: id,
			fullName: fullName,
		};
		dispatch(deleteCreator(data));
		setShowDeleteModal(false);
	};

	const handleCancel = () => {
		setShowDeleteModal(false);
	};

	const handleActivate = (value: number) => {
		const data: User = {
			id: value,
		};
		dispatch(activateCreatorAction(data));
	};

	const handleClickAccept = (value: number) => {
		const data: EditUser = {
			id: value,
			newStatus: 1,
		};
		dispatch(editCreator(data));
	};

	const handleClickDecline = (value: number) => {
		const data: EditUser = {
			id: value,
			newStatus: 2,
		};
		dispatch(editCreator(data));
	};

	useEffect(() => {
		dispatch(setFilterKeyAction(''));
	}, []);

	return (
		<div className={`${styles.table}`}>
			<table id={styles.users}>
				<thead>
					<tr>
						<th>
							Full Name{' '}
							<PFSortButton
								columnKey="Name"
								onClick={() => changeSort('fullName')}
								{...{
									sortOrder,
									sortKey,
								}}
							/>
						</th>
						<th>Social Media</th>
						<th>
							Email
							<PFSortButton
								columnKey="email"
								onClick={() => changeSort('email')}
								{...{
									sortOrder,
									sortKey,
								}}
							/>
						</th>
						<th>Phone Number</th>
						<th>
							Status
							<PFSortButton
								columnKey="status"
								onClick={() => changeSort('status')}
								{...{
									sortOrder,
									sortKey,
								}}
							/>
						</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{users.map(user => {
						return (
							<PFCreatorTableRow
								onClickAccept={handleClickAccept}
								onClickDecline={handleClickDecline}
								onActivate={handleActivate}
								id={user.id}
								key={user.id}
								onDelete={handleDelete}
								fullName={user.Name}
								facebookLink=""
								instagramLink={user.instagramHandle}
								tiktokLink={user.tikTokHandle}
								email={user.email}
								phoneNumber={user.phoneNumber}
								status={getUserStatus(user.status)}
							/>
						);
					})}
				</tbody>
			</table>
			{users.length === 0 && (
				<div className={styles.empty}>There are no creators</div>
			)}
			<DeleteUserModal
				show={showDeleteModal}
				onHide={handleCancel}
				onDelete={handleDeleteClick}
				onCancel={handleCancel}
				fullName={fullName}
				label={CampaignModals.DELETE_USER}
			/>
		</div>
	);
};

export default CreatorTable;
