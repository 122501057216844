import {ReactComponent as DarkProfile} from './../assets/icons/dark_profile.svg';
import {ReactComponent as DarkRocket} from './../assets/icons/dark_rocket.svg';
import {ReactComponent as LightProfile} from './../assets/icons/light_profile.svg';
import {ReactComponent as LightRocket} from './../assets/icons/light_rocket.svg';
import {ReactComponent as Logout} from './../assets/icons/logout.svg';
import {ReactComponent as Facebook} from './../assets/icons/facebook.svg';
import {ReactComponent as FacebookGray} from './../assets/icons/facebook_gray.svg';
import {ReactComponent as Instagram} from './../assets/icons/instagram.svg';
import {ReactComponent as InstagramGray} from './../assets/icons/instagram_gray.svg';
import {ReactComponent as TikTok} from './../assets/icons/tiktok.svg';
import {ReactComponent as TikTokGray} from './../assets/icons/tiktok_gray.svg';
import {ReactComponent as Warning} from './../assets/icons/warning.svg';
import {ReactComponent as Bin} from './../assets/icons/bin.svg';
import {ReactComponent as WhiteBin} from './../assets/icons/whiteBin.svg';
import {ReactComponent as Edit} from './../assets/icons/edit.svg';
import {ReactComponent as Tick} from './../assets/icons/green_tick.svg';
import {ReactComponent as X} from './../assets/icons/x.svg';
import {ReactComponent as Search} from './../assets/icons/search.svg';
import {ReactComponent as Sort} from './../assets/icons/sort.svg';
import {ReactComponent as GrayLeft} from './../assets/icons/gray_left.svg';
import {ReactComponent as GrayRight} from './../assets/icons/gray_right.svg';
import {ReactComponent as MiniSort} from './../assets/icons/mini_sort.svg';
import {ReactComponent as GreenDot} from './../assets/icons/green_dot.svg';
import {ReactComponent as OrangeDot} from './../assets/icons/orange_dot.svg';
import {ReactComponent as RedDot} from './../assets/icons/red_dot.svg';
import {ReactComponent as Back} from './../assets/icons/back.svg';
import {ReactComponent as Plus} from './../assets/icons/plus.svg';
import {ReactComponent as Reach} from './../assets/icons/reach.svg';
import {ReactComponent as Upload} from './../assets/icons/upload.svg';
import {ReactComponent as Download} from './../assets/icons/download.svg';
import {ReactComponent as WhiteDownload} from './../assets/icons/whiteDownload.svg';
import {ReactComponent as CreatorBrief} from './../assets/icons/creator_brief.svg';
import {ReactComponent as CreatorList} from './../assets/icons/creator_list.svg';
import {ReactComponent as ContentPlan} from './../assets/icons/content_plan.svg';
import {ReactComponent as FinalAssets} from './../assets/icons/final_assets.svg';
import {ReactComponent as LiveMarket} from './../assets/icons/live_market.svg';
import {ReactComponent as Budget} from './../assets/icons/budget.svg';
import {ReactComponent as Date} from './../assets/icons/date.svg';
import {ReactComponent as Settings} from './../assets/icons/settings.svg';
import {ReactComponent as Video} from './../assets/icons/video.svg';
import {ReactComponent as Image} from './../assets/icons/image.svg';
import {ReactComponent as Dot} from './../assets/icons/dot.svg';
import {ReactComponent as Platform} from './../assets/icons/platform.svg';
import {ReactComponent as Camera} from './../assets/icons/camera.svg';
import {ReactComponent as BlackBell} from './../assets/icons/bell_black.svg';
import {ReactComponent as WhiteBell} from './../assets/icons/bell_white.svg';
import {ReactComponent as WhiteBellNotification} from './../assets/icons/bell_white_notification.svg';
import {ReactComponent as BlackBellNotification} from './../assets/icons/bell_black_notification.svg';
import {ReactComponent as BriefIcon} from './../assets/icons/brief_notification.svg';
import {ReactComponent as CampaignIcon} from './../assets/icons/campaign_notification.svg';
import {ReactComponent as CreatorListIcon} from './../assets/icons/creator_list_notification.svg';
import {ReactComponent as PerformanceIcon} from './../assets/icons/performance_notification.svg';
import {ReactComponent as ReloadIcon} from './../assets/icons/reload.svg';
import {ReactComponent as ArrowIcon} from './../assets/icons/arrow.svg';

export const Icons = {
	DARK_ROCKET: <DarkRocket />,
	LIGHT_ROCKET: <LightRocket />,
	DARK_PROFILE: <DarkProfile width={18} height={18} />,
	LIGHT_PROFILE: <LightProfile width={18} height={18} />,
	LOGOUT: <Logout />,
	FACEBOOK: <Facebook />,
	FACEBOOK_GRAY: <FacebookGray />,
	INSTAGRAM: <Instagram />,
	INSTAGRAM_GRAY: <InstagramGray />,
	TIKTOK: <TikTok />,
	TIKTOK_GRAY: <TikTokGray />,
	ARROW_ICON: <ArrowIcon />,
	X: <X />,
	TICK: <Tick />,
	EDIT: <Edit />,
	BIN: <Bin />,
	WHITE_BIN: <WhiteBin width={30} height={30} />,
	SEARCH: <Search />,
	SORT: <Sort />,
	LEFT: <GrayLeft />,
	RIGHT: <GrayRight />,
	GRAY_LEFT: <GrayLeft />,
	GRAY_RIGHT: <GrayRight />,
	MINI_SORT: <MiniSort />,
	GREEN_DOT: <GreenDot />,
	ORANGE_DOT: <OrangeDot />,
	RED_DOT: <RedDot />,
	BACK: <Back />,
	PLUS: <Plus />,
	REACH: <Reach width="20px" height="20px" />,
	UPLOAD: <Upload />,
	DOWNLOAD: <Download />,
	WHITE_DOWNLOAD: <WhiteDownload width={30} height={30} />,
	WARNING: <Warning style={{marginBottom: 5, marginRight: 5}} />,
	CREATOR_BRIEF: <CreatorBrief />,
	CREATOR_LIST: <CreatorList />,
	CONTENT_PLAN: <ContentPlan width="16px" height="20px" />,
	FINAL_ASSETS: <FinalAssets />,
	LIVE_MARKET: <LiveMarket />,
	BUDGET: <Budget />,
	DATE: <Date />,
	SETTINGS: <Settings />,
	VIDEO: <Video />,
	IMAGE: <Image />,
	DOT: <Dot />,
	PLATFORM: <Platform />,
	CAMERA: <Camera />,
	BLACK_BELL: <BlackBell width={18} height={18} />,
	WHITE_BELL: <WhiteBell width={18} height={18} />,
	WHITE_BELL_NOTIFICATION: <WhiteBellNotification width={23} height={23} />,
	BLACK_BELL_NOTIFICATION: <BlackBellNotification width={23} height={23} />,
	CAMPAIGN_ICON: <CampaignIcon />,
	BRIEF_ICON: <BriefIcon />,
	PERFORMANCE_ICON: <PerformanceIcon />,
	CREATOR_LIST_ICON: <CreatorListIcon />,
	RELOAD: <ReloadIcon />,
};
