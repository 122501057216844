// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PFCreatorListTableRow_checkbox__W67Zc {\n  box-sizing: border-box;\n  width: 16px;\n  height: 16px;\n  /* white */\n  background: #ffffff;\n  /* black8 */\n  border: 1px solid rgba(0, 0, 0, 0.08);\n  /* Inside auto layout */\n  flex: none;\n  order: 0;\n  flex-grow: 0;\n}\n\n:checked {\n  background: #284bcb;\n}\n\n.PFCreatorListTableRow_status__yju\\+n {\n  width: 300px;\n}\n\n.PFCreatorListTableRow_icons__XqpZa {\n  cursor: pointer;\n}\n\n.PFCreatorListTableRow_status__yju\\+n {\n  font-weight: bold;\n}\n\n.PFCreatorListTableRow_tr__7BqqU {\n  height: 50px;\n}\n\n.PFCreatorListTableRow_fb__lXOS9 {\n  float: \"left\";\n  padding-right: \"12px\";\n  padding-top: \"1px\";\n}\n\n.PFCreatorListTableRow_select__hLGdz {\n  width: 50%;\n}\n\n.PFCreatorListTableRow_edit__WrW\\+n {\n  margin-left: 10%;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/atoms/CreatorListTableRow/PFCreatorListTableRow.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EAEA,WAAA;EACA,YAAA;EAEA,UAAA;EAEA,mBAAA;EACA,WAAA;EAEA,qCAAA;EAEA,uBAAA;EAEA,UAAA;EACA,QAAA;EACA,YAAA;AALD;;AAOA;EACC,mBAAA;AAJD;;AAMA;EACC,YAAA;AAHD;;AAKA;EACC,eAAA;AAFD;;AAKA;EACC,iBAAA;AAFD;;AAKA;EACC,YAAA;AAFD;;AAKA;EACC,aAAA;EACA,qBAAA;EACA,kBAAA;AAFD;;AAIA;EACC,UAAA;AADD;;AAGA;EACC,gBAAA;AAAD","sourcesContent":[".checkbox {\n\tbox-sizing: border-box;\n\n\twidth: 16px;\n\theight: 16px;\n\n\t/* white */\n\n\tbackground: #ffffff;\n\t/* black8 */\n\n\tborder: 1px solid rgba(0, 0, 0, 0.08);\n\n\t/* Inside auto layout */\n\n\tflex: none;\n\torder: 0;\n\tflex-grow: 0;\n}\n:checked {\n\tbackground: #284bcb;\n}\n.status {\n\twidth: 300px;\n}\n.icons {\n\tcursor: pointer;\n}\n\n.status {\n\tfont-weight: bold;\n}\n\n.tr {\n\theight: 50px;\n}\n\n.fb {\n\tfloat: 'left';\n\tpadding-right: '12px';\n\tpadding-top: '1px';\n}\n.select {\n\twidth: 50%;\n}\n.edit {\n\tmargin-left: 10%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": "PFCreatorListTableRow_checkbox__W67Zc",
	"status": "PFCreatorListTableRow_status__yju+n",
	"icons": "PFCreatorListTableRow_icons__XqpZa",
	"tr": "PFCreatorListTableRow_tr__7BqqU",
	"fb": "PFCreatorListTableRow_fb__lXOS9",
	"select": "PFCreatorListTableRow_select__hLGdz",
	"edit": "PFCreatorListTableRow_edit__WrW+n"
};
export default ___CSS_LOADER_EXPORT___;
