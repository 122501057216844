import React from 'react';
import {Icons} from '../../../../constants/Icons';
import styles from './PFAdId.module.scss';

interface PFAdIdProps {
	text: string;
	onRemove: (text: string) => void;
}

export const PFAdId: React.FC<PFAdIdProps> = ({text, onRemove}) => {
	return (
		<div className={styles.container}>
			<div className={styles.text}>{text}</div>
			<span className={styles.x} onClick={() => onRemove(text)}>
				{Icons.X}
			</span>
		</div>
	);
};
