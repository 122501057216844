import React from 'react';
import {Icons} from '../../../../constants/Icons';
import styles from './PFStepCard.module.scss';

interface PFStepCardProps {
	text: string | null;
	type: string;
	onDelete: (retVal: string | null) => void;
	style?: {};
	editable?: boolean;
}
export const PFStepCard: React.FC<PFStepCardProps> = ({
	text,
	type,
	style,
	onDelete,
	editable = true,
}) => {
	const setIcon = (typeOfCard: string) => {
		if (typeOfCard === 'CreatorBrief') {
			return Icons.CREATOR_BRIEF;
		} else if (typeOfCard === 'CreatorList') {
			return Icons.CREATOR_LIST;
		} else if (typeOfCard === 'ContentPlan') {
			return Icons.CONTENT_PLAN;
		} else if (typeOfCard === 'FinalAssets') {
			return Icons.FINAL_ASSETS;
		} else if (typeOfCard === 'LiveMarket') {
			return Icons.LIVE_MARKET;
		} else if (typeOfCard === 'Budget') {
			return Icons.BUDGET;
		} else {
			return Icons.DATE;
		}
	};

	return (
		<div className={styles.split}>
			<div className={styles.card}>
				<div className={`${styles.icon} ${style}`}>{setIcon(type)}</div>
				<div className={`${styles.text}`}>{text}</div>
			</div>
			{type === 'LiveMarket' && editable ? (
				<div
					className={styles.bin}
					title="Delete live market"
					onClick={() => onDelete(text)}>
					{Icons.BIN}
				</div>
			) : null}
		</div>
	);
};

export default PFStepCard;
