import React from 'react';
import {Icons} from '../../../../constants/Icons';
import styles from './PFFileButton.module.scss';

interface PFFileButtonProps {
	text: string;
	onClick: () => void;
	style?: string;
	type?: 'submit' | 'button' | 'reset' | undefined;
	download?: boolean;
	disabled?: boolean;
}
export const PFFileButton: React.FC<PFFileButtonProps> = ({
	onClick,
	text,
	style = {},
	type = 'button',
	download = false,
	disabled = false,
}) => {
	return (
		<button
			disabled={disabled}
			className={`${styles.loginBtn} ${style} ${disabled && styles.disabled}`}
			onClick={onClick}
			type={type}>
			<span className={styles.icon}>
				{download ? Icons.DOWNLOAD : Icons.UPLOAD}
			</span>
			{text}
		</button>
	);
};

export default PFFileButton;
