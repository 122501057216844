import {toast} from 'react-toastify';
import {AdminPage} from '../../models/AdminPage';
import {AdminRequest} from '../../models/AdminRequest';
import {AdminsSearch} from '../../models/AdminsSearch';
import {BrandPage} from '../../models/BrandPage';
import {BrandsSearch} from '../../models/BrandsSearch';
import {Creator} from '../../models/Creator';
import {CreatorPage} from '../../models/CreatorPage';
import {CreatorsSearch} from '../../models/CreatorsSearch';
import {EditUser} from '../../models/EditUser';
import {EditUserResponse} from '../../models/EditUserResponse';
import {NewUser} from '../../models/NewUser';
import {SearchRequest} from '../../models/SearchRequest';
import {SelectBrand} from '../../models/SelectBrand';
import {TablePageModel} from '../../models/TablePageModel';
import {User} from '../../models/User';
import UserServices from '../../services/UserServices';
import {setLoading} from '../reducers/AuthReducer';
import {
	editBrandPage,
	editCreatorPage,
	getAllBrands,
	getAllCreators,
	removeAdmin,
	removeBrand,
	removeCreator,
	searchAdminPage,
	searchBrandPage,
	searchCreatorPage,
	setAdminPage,
	setBrandPage,
	setCreatorPage,
	activateBrand,
	activateAdmin,
	activateCreator,
	setDateOfDeletion,
	filterCreators,
	setRowsPerPage,
	filterBrands,
	filterAdmins,
	setFilterKey,
	setSortOrder,
} from '../reducers/UserReducer';

export const getAdminPage = (data: TablePageModel) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		UserServices.getAdminPage(data).then((res: AdminPage) => {
			dispatch(setAdminPage(res));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while reading admins from database');
		dispatch(setLoading(false));
	}
};

export const getCreatorPage = (data: TablePageModel) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		UserServices.getCreatorPage(data).then((res: CreatorPage) => {
			dispatch(setCreatorPage(res));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while reading creators from database');
		dispatch(setLoading(false));
	}
};

export const getCreators = () => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		UserServices.getAllCreators().then((res: Array<Creator>) => {
			dispatch(getAllCreators(res));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while reading creators from database');
		dispatch(setLoading(false));
	}
};

export const getBrandPage = (data: TablePageModel) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		UserServices.getBrandPage(data).then((res: BrandPage) => {
			dispatch(setBrandPage(res));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while reading brands from database');
		dispatch(setLoading(false));
	}
};

export const searchAdmins = (data: SearchRequest) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		UserServices.searchAdminPage(data).then((res: AdminsSearch) => {
			dispatch(searchAdminPage(res));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while reading admins from database');
		dispatch(setLoading(false));
	}
};

export const searchBrands = (data: SearchRequest) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		UserServices.searchBrandPage(data).then((res: BrandsSearch) => {
			dispatch(searchBrandPage(res));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while reading brands from database');
		dispatch(setLoading(false));
	}
};
export const searchCreators = (data: SearchRequest) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		UserServices.searchCreatorPage(data).then((res: CreatorsSearch) => {
			dispatch(searchCreatorPage(res));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while reading creators from database');
		dispatch(setLoading(false));
	}
};

export const createAdmin = (data: AdminRequest) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		UserServices.addAdmin(data).then((res: any) => {
			if (res.error) {
				toast.error('Admin with given email already exists');
			} else {
				toast.success('Admin added successfully');
			}
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while adding new admin');
		dispatch(setLoading(false));
	}
};

export const inviteUser = (data: NewUser) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		UserServices.inviteUser(data)
			.then(() => {
				dispatch(setLoading(false));
				toast.success('User successfully invited');
			})
			.catch(error => {
				toast.error('User with given email already exists');
			});
	} catch (e) {
		toast.error('Error while inviting user');
		dispatch(setLoading(false));
	}
};

export const editCreator = (data: EditUser) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		UserServices.editUser(data).then((res: EditUserResponse) => {
			dispatch(editCreatorPage(res));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while editing user');
		dispatch(setLoading(false));
	}
};

export const editBrand = (data: EditUser) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		UserServices.editUser(data).then((res: EditUserResponse) => {
			dispatch(editBrandPage(res));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while editing user');
		dispatch(setLoading(false));
	}
};

export const deleteAdmin = (data: User) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		UserServices.deleteUser(data).then(() => {
			dispatch(removeAdmin(data));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while deleting user');
		dispatch(setLoading(false));
	}
};

export const activateAdminAction = (data: User) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		UserServices.activateUser(data).then(() => {
			dispatch(activateAdmin(data));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while changing admin status');
		dispatch(setLoading(false));
	}
};

export const deleteBrand = (data: User) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		UserServices.deleteUser(data).then(() => {
			dispatch(removeBrand(data));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while deleting user');
		dispatch(setLoading(false));
	}
};

export const activateBrandAction = (data: User) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		UserServices.activateUser(data).then(() => {
			dispatch(activateBrand(data));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while changing brand status');
		dispatch(setLoading(false));
	}
};

export const deleteCreator = (data: User) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		UserServices.deleteUser(data).then(() => {
			dispatch(removeCreator(data));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while deleting user');
		dispatch(setLoading(false));
	}
};

export const activateCreatorAction = (data: User) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		UserServices.activateUser(data).then(() => {
			dispatch(activateCreator(data));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while changing creator status');
		dispatch(setLoading(false));
	}
};

export const getBrands = () => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		UserServices.getAllBrands().then((res: Array<SelectBrand>) => {
			dispatch(getAllBrands(res));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while reading brands from database');
		dispatch(setLoading(false));
	}
};

export const getDateOfDeletion = (token: string) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		UserServices.getDataDeletionDate(token).then((res: any) => {
			dispatch(setDateOfDeletion(res));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while reading brands from database');
		dispatch(setLoading(false));
	}
};

export const filterCreatorsAction =
	(filter: string, orderBy: string, pageNumber: number, pageSize: number) =>
	(dispatch: any) => {
		dispatch(setLoading(true));
		try {
			UserServices.getFilteredCreators(
				filter,
				orderBy,
				pageSize,
				pageNumber,
			).then((res: any) => {
				dispatch(filterCreators(res));
				dispatch(setLoading(false));
			});
		} catch (e) {
			toast.error('Error while sorting creators');
			dispatch(setLoading(false));
		}
	};

export const filterBrandsAction =
	(filter: string, orderBy: string, pageNumber: number, pageSize: number) =>
	(dispatch: any) => {
		dispatch(setLoading(true));
		try {
			UserServices.getFilteredBrands(
				filter,
				orderBy,
				pageSize,
				pageNumber,
			).then((res: any) => {
				dispatch(filterBrands(res));
				dispatch(setLoading(false));
			});
		} catch (e) {
			toast.error('Error while sorting brands');
			dispatch(setLoading(false));
		}
	};

export const filterAdminsAction =
	(filter: string, orderBy: string, pageNumber: number, pageSize: number) =>
	(dispatch: any) => {
		dispatch(setLoading(true));
		try {
			UserServices.getFilteredAdmins(
				filter,
				orderBy,
				pageSize,
				pageNumber,
			).then((res: any) => {
				dispatch(filterAdmins(res));
				dispatch(setLoading(false));
			});
		} catch (e) {
			toast.error('Error while sorting brands');
			dispatch(setLoading(false));
		}
	};

export const setRowsPerPageAction = (rowsNumber: number) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		dispatch(setRowsPerPage(rowsNumber));
		dispatch(setLoading(false));
	} catch (e) {
		toast.error('Error while setting rows per page number');
		dispatch(setLoading(false));
	}
};

export const setFilterKeyAction = (filterKey: string) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		dispatch(setFilterKey(filterKey));
		dispatch(setLoading(false));
	} catch (e) {
		toast.error('Error while setting filter key');
		dispatch(setLoading(false));
	}
};

export const setSortOrderAction = (sortOrder: string) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		dispatch(setSortOrder(sortOrder));
		dispatch(setLoading(false));
	} catch (e) {
		toast.error('Error while setting filter key');
		dispatch(setLoading(false));
	}
};
