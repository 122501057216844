import React, {useState} from 'react';
import Select from 'react-select';
import {Icons} from '../../../../constants/Icons';
import {CardType} from '../../../../models/CardType';
import styles from './PFCard.module.scss';

interface PFCardProps {
	type: CardType;
	name?: string;
	val: string;
	onChange: (value: string) => void;
	onDelete?: (name: string) => void;
	editable?: boolean;
}
export const PFCard: React.FC<PFCardProps> = ({
	type,
	onChange,
	val,
	name,
	onDelete,
	editable = true,
}) => {
	const [value, setValue] = useState(val);

	const icon = () => {
		if (type === CardType.Payment) {
			return Icons.BUDGET;
		} else if (type === CardType.Platform) {
			return Icons.PLATFORM;
		} else if (type === CardType.ShootTime) {
			return Icons.CAMERA;
		} else {
			return Icons.DOT;
		}
	};

	const options = [
		{value: 0, label: 'Instagram'},
		{value: 1, label: 'Facebook'},
		{value: 2, label: 'TikTok'},
	];

	const renderInput = () => {
		if (type !== CardType.UsageTime && type !== CardType.Platform) {
			return (
				<>
					<input
						onChange={e => {
							onChange(e.target.value);
							setValue(e.target.value);
						}}
						className={`${styles.input}`}
						placeholder={'Enter Value'}
						value={val}
						disabled={!editable}
					/>
				</>
			);
		} else if (type === CardType.UsageTime) {
			return <span className={styles.usageTimeVal}>{val}</span>;
		} else {
			return (
				<Select
					className={styles.select}
					options={options}
					value={options.filter(x => x.label === val)}
					onChange={e => onChange(e!.label)}
					isDisabled={!editable}
				/>
			);
		}
	};

	const handleDelete = () => {
		onDelete && name && onDelete(name);
	};
	return (
		<div className={styles.split}>
			<div className={styles.card}>
				<div className={styles.icon}>{icon()}</div>
				<div className={styles.text}>{name ? name : type}</div>
			</div>
			<div className={styles.row}>{renderInput()}</div>
			{type === CardType.UsageTime && editable && (
				<div
					className={styles.bin}
					title="Delete usage time"
					onClick={handleDelete}>
					{Icons.BIN}
				</div>
			)}
		</div>
	);
};
export default PFCard;
