import {toast} from 'react-toastify';
import {ChangedStatus} from '../../components/UI/organisms/CreatorList/PFCreatorList';
import {Ad} from '../../models/Ad';
import {FinalAsset} from '../../models/FinalAsset';
import {SelectedCreator} from '../../models/SelectedCreator';
import CreatorListService from '../../services/CreatorListService';
import {
	setAds,
	setCreatorList,
	setFinalAssets,
	setLoading,
} from '../reducers/CreatorListReducer';

export const getCreatorList = (id: string) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		CreatorListService.getCreatorList(id).then(
			(res: Array<SelectedCreator>) => {
				dispatch(setCreatorList(res));
				dispatch(setLoading(false));
			},
		);
	} catch (e) {
		toast.error('Error while reading creator list from database');
		dispatch(setLoading(false));
	}
};

export const editCreatorList =
	(id: string, list: Array<number>) => (dispatch: any) => {
		dispatch(setLoading(true));
		try {
			CreatorListService.editCreatorList(id, list).then(
				(res: Array<SelectedCreator>) => {
					dispatch(setCreatorList(res));
					dispatch(setLoading(false));
				},
			);
			toast.success('Creator list successfully updated!');
		} catch (e) {
			toast.error('Error while reading creator list from database');
			dispatch(setLoading(false));
		}
	};

export const changeStatuses =
	(id: string, list: Array<ChangedStatus>) => (dispatch: any) => {
		dispatch(setLoading(true));

		CreatorListService.changeStatuses(id, list)
			.then((res: Array<SelectedCreator>) => {
				dispatch(setCreatorList(res));
				dispatch(setLoading(false));
				toast.success('Creator statuses successfully updated!');
			})
			.catch(e => {
				if (e.response.status === 400) {
					toast.error(e.response.data.Message);
					dispatch(setLoading(false));
				} else {
					toast.error('Error while changing creator statuses.');
					dispatch(setLoading(false));
				}
			});
	};

export const filterCreatorList =
	(id: string, status: number) => (dispatch: any) => {
		dispatch(setLoading(true));
		try {
			CreatorListService.filterCreatorList(status, id).then(
				(res: Array<SelectedCreator>) => {
					dispatch(setCreatorList(res));
					dispatch(setLoading(false));
				},
			);
		} catch (e) {
			toast.error('Error while reading creator list from database');
			dispatch(setLoading(false));
		}
	};

export const getFinalAssetsCreatorList = (id: string) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		CreatorListService.getFinalAssetsCreatorList(id).then(
			(res: Array<SelectedCreator>) => {
				dispatch(setCreatorList(res));
				dispatch(setLoading(false));
			},
		);
	} catch (e) {
		toast.error('Error while reading creator list from database');
		dispatch(setLoading(false));
	}
};

export const getPerformanceCreatorList = (id: string) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		CreatorListService.getPerformanceCreatorList(id).then(
			(res: Array<SelectedCreator>) => {
				dispatch(setCreatorList(res));
				dispatch(setLoading(false));
			},
		);
	} catch (e) {
		toast.error('Error while reading creator list from database');
		dispatch(setLoading(false));
	}
};

export const addFinalAssets = (data: FormData) => (dispatch: any) => {
	dispatch(setLoading(true));
	CreatorListService.addFinalAssets(data)
		.then((res: Array<FinalAsset>) => {
			dispatch(
				setFinalAssets({
					assets: res,
					creatorId: data.get('CreatorId')?.toString() || '',
				}),
			);
			dispatch(setLoading(false));
			toast.success('Final asset added successfully!');
		})
		.catch(() => {
			toast.error(`Error while adding final asset. Max asset size is 30MB.`);
			dispatch(setLoading(false));
		});
};

export const deleteFinalAssets =
	(campaignId: string, creatorId: string, ids: number[]) => (dispatch: any) => {
		dispatch(setLoading(true));

		CreatorListService.deleteFinalAssets(campaignId, creatorId, ids)
			.then((res: Array<FinalAsset>) => {
				dispatch(
					setFinalAssets({
						assets: res,
						creatorId: creatorId,
					}),
				);
				dispatch(setLoading(false));
				toast.success('Final assets changed successfully!');
			})
			.catch(() => {
				toast.error('Error while removing final assets from database.');
				dispatch(setLoading(false));
			});
	};

export const deleteAds =
	(creatorId: string, metaId: string, adsToDelete: string[]) =>
	(dispatch: any) => {
		dispatch(setLoading(true));

		CreatorListService.deleteAds(creatorId, metaId, adsToDelete)
			.then(res => {
				dispatch(setLoading(false));
				dispatch(setAds({creatorId: creatorId, ads: res}));
				toast.success(
					`${adsToDelete.length > 1 ? 'Ads' : 'Ad'} successfully removed!`,
				);
			})
			.catch(() => {
				dispatch(setLoading(false));
				toast.error(
					`Something went wrong. Unable to remove ${
						adsToDelete.length > 1 ? 'ads' : 'ad'
					}.`,
				);
			});
	};

export const editAds =
	(creatorId: string, metaId: string, ads: Ad[]) => (dispatch: any) => {
		dispatch(setLoading(true));

		CreatorListService.editAds(creatorId, metaId, ads)
			.then(res => {
				dispatch(setLoading(false));
				dispatch(setAds({creatorId: creatorId, ads: res}));
				toast.success(
					`${ads.length > 1 ? 'Ads' : 'Ad'} successfully connected!`,
				);
			})
			.catch(e => {
				if (e.response.status == 409) {
					dispatch(setLoading(false));
					toast.error('Meta ad with given ID already exists!');
				} else {
					dispatch(setLoading(false));
					toast.error(
						`Something went wrong. Unable to connect ${
							ads.length > 1 ? 'ads' : 'ad'
						}.`,
					);
				}
			});
	};
