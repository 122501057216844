import React, {MouseEventHandler} from 'react';
import styles from './PFSortButton.module.scss';
import {Icons} from '../../../../constants/Icons';

interface PFSortButtonProps {
	sortOrder: string;
	columnKey: string;
	sortKey: string;
	onClick: MouseEventHandler<HTMLButtonElement>;
}
export const PFSortButton: React.FC<PFSortButtonProps> = ({
	onClick,
	sortOrder,
	sortKey,
	columnKey,
}) => {
	return (
		<button
			title="Sort"
			className={`${
				sortKey === columnKey && sortOrder === 'desc'
					? styles.sortBtnReverse
					: styles.sortBtn
			}`}
			onClick={onClick}>
			{Icons.MINI_SORT}
		</button>
	);
};

export default PFSortButton;
