import {toast} from 'react-toastify';
import {AuthModel} from '../../models/AuthModel';
import {ForgotPasswordModel} from '../../models/ForgotPasswordModel';
import {LoginUserModel} from '../../models/LoginUserModel';
import {ResetPasswordModel} from '../../models/ResetPasswordModel';
import AuthServices from '../../services/AuthServices';
import {setLoading, setLoggedIn} from '../reducers/AuthReducer';

export const login = (data: LoginUserModel) => (dispatch: any) => {
	dispatch(setLoading(true));
	AuthServices.login(data)
		.then((res: AuthModel) => {
			if (res.jwtToken) {
				if (data.rememberMe) {
					localStorage.setItem('token', res.jwtToken);
					sessionStorage.setItem('token', res.jwtToken);
				} else {
					sessionStorage.setItem('token', res.jwtToken);
				}
				dispatch(
					setLoggedIn({
						auth: res,
						rememberMe: data.rememberMe ? data.rememberMe : false,
					}),
				);
			}
		})
		.catch(error => {
			switch (error.response.status) {
				case 404: {
					toast.error('User with this email does not exist.');
					dispatch(setLoading(false));
					break;
				}
				case 400: {
					toast.error('Wrong password.');
					dispatch(setLoading(false));
					break;
				}
				default:
					toast.error("We couldn't log you in, try signing in later.");
					dispatch(setLoading(false));
					break;
			}
		});
};

export const forgotPassword =
	(data: ForgotPasswordModel) => (dispatch: any) => {
		dispatch(setLoading(true));
		AuthServices.forgotPassword(data).then(res => {
			if (res.error) {
				toast.error('Error while resetting password');
			} else {
				toast.success(res.message);
			}
			dispatch(setLoading(false));
		});
	};

export const resetPassword = (data: ResetPasswordModel) => (dispatch: any) => {
	dispatch(setLoading(true));
	AuthServices.resetPassword(data).then(res => {
		if (res.error) {
			toast.error('Error while resetting password');
		} else {
			toast.success(res.message);
		}
		dispatch(setLoading(false));
	});
};
