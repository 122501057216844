// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PFDateTypeMenu_select__cHfX4 {\n  border: 1px solid rgba(0, 0, 0, 0.01);\n  border-radius: 4px;\n  color: rgb(85, 85, 85);\n  font-family: \"Bulldog\";\n}\n\n.PFDateTypeMenu_bootstrap-select__O3pm4 {\n  width: 250px !important;\n  overflow: auto !important;\n  background-color: red !important;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/atoms/DateTypeMenu/PFDateTypeMenu.module.scss"],"names":[],"mappings":"AAAA;EACI,qCAAA;EACA,kBAAA;EACA,sBAAA;EACA,sBAAA;AACJ;;AAGA;EACI,uBAAA;EACA,yBAAA;EACA,gCAAA;AAAJ","sourcesContent":[".select{\n    border: 1px solid rgba(0, 0, 0, 0.01);\n    border-radius: 4px;\n    color: rgba(85, 85, 85, 1);\n    font-family: \"Bulldog\";\n    $form-select-color: red;\n} \n\n.bootstrap-select {\n    width: 250px !important;\n    overflow: auto !important;\n    background-color: red !important;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "PFDateTypeMenu_select__cHfX4",
	"bootstrap-select": "PFDateTypeMenu_bootstrap-select__O3pm4"
};
export default ___CSS_LOADER_EXPORT___;
