import React from 'react';
import {Colors} from '../../../../constants/Colors';
import {Icons} from '../../../../constants/Icons';
import {Images} from '../../../../constants/Images';
import styles from './PFCampaignCard.module.scss';

interface PFCampaignCardProps {
	onChange: (retVal: string) => void;
	onClick: (id: number) => void;
	id: number;
	name: string;
	date: string | undefined;
	status: number;
	imageUrl: string;
	brandName: string;
}

export const PFCampaignCard: React.FC<PFCampaignCardProps> = ({
	onChange,
	onClick,
	id,
	name,
	date,
	status,
	imageUrl,
	brandName,
}) => {
	const getStatus = () => {
		switch (status) {
			case 0:
				return 'Pending';
			case 1:
				return 'Approved';
			case 2:
				return 'Declined';
			case 3:
				return 'Active';
			case 4:
				return 'Ended';
			default:
				break;
		}
	};

	const handleClick = () => {
		onClick(id);
	};

	return (
		<div
			className={styles.card}
			style={{
				backgroundImage: imageUrl ? `url(${imageUrl})` : `url(${Images.Logo})`,
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',
			}}
			onClick={handleClick}>
			<div className={styles.text}>
				<div className={styles.name}>{`${brandName}: ${name}`}</div>
				<div className={styles.btmText}>
					<div className={styles.date}>Date Started: {date}</div>
					<div
						className={styles.status}
						title={getStatus()}
						style={
							status === 2 || status === 4
								? stylesObject.red
								: status === 0
								? stylesObject.orange
								: status === 3
								? stylesObject.green
								: stylesObject.lightGreen
						}>
						<span style={stylesObject.text}>{getStatus()}</span>
					</div>
				</div>
			</div>
		</div>
	);
};
const stylesObject = {
	red: {
		backgroundColor: 'rgba(234, 67, 53, 0.8)',
		width: 'auto',
		minWidth: 40,
		borderRadius: 4,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	orange: {
		backgroundColor: 'rgba(251, 139, 2, 0.8)',
		width: 'auto',
		minWidth: 40,
		borderRadius: 4,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	green: {
		backgroundColor: 'rgba(104, 204, 88, 0.8)',
		width: 'auto',
		minWidth: 40,
		borderRadius: 4,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	lightGreen: {
		backgroundColor: 'rgba(166, 224, 158, 1)',
		width: 'auto',
		minWidth: 40,
		borderRadius: 4,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	pending: {
		paddingRight: '15px',
	},
	text: {
		color: Colors.DARK_GREY,
	},
};

export default PFCampaignCard;
