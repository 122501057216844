import React from 'react';
import {Users} from '../../../../constants/Users';
import {Admin} from '../../../../models/Admin';
import {Brand} from '../../../../models/Brand';
import {Creator} from '../../../../models/Creator';
import AdminTable from '../../molecules/AdminTable/AdminTable';
import BrandTable from '../../molecules/BrandTable/BrandTable';
import CreatorTable from '../../molecules/CreatorTable/CreatorTable';

interface PFUsersContentTableProps {
  clicked: string;
  creators: Array<Creator>;
  admins: Array<Admin>;
  brands: Array<Brand>;
}
export const PFUsersContentTable: React.FC<PFUsersContentTableProps> = ({
  clicked,
  creators,
  admins,
  brands,
}) => {
  return (
    <div>
      {clicked === Users.CREATORS ? <CreatorTable users={creators} /> : null}
      {clicked === Users.BRANDS ? <BrandTable users={brands} /> : null}
      {clicked === Users.ADMINS ? <AdminTable users={admins} /> : null}
    </div>
  );
};

export default PFUsersContentTable;
