import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {CampaignModals} from '../../../../constants/CampaignModals';
import {CampaignTabs} from '../../../../constants/CampaignTabs';
import {CampaignBrief} from '../../../../models/CampaignBrief';
import {CreatorBriefResponse} from '../../../../models/CreatorBriefResponse';
import {SelectedCreator} from '../../../../models/SelectedCreator';
import {changeUnsavedChanges} from '../../../../store/actions/CampaignActions';
import {RootState} from '../../../../store/reducers/RootReducer';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import PFChangesModal from '../ChangesModal/PFChangesModal';
import styles from './PFSingleCampaignTabs.module.scss';

interface PFSingleCampaignTabsProps {
	onClick: (retVal: string) => void;
	currentTab: string;
}

const PFSingleCampaignTabs: React.FC<PFSingleCampaignTabsProps> = ({
	onClick,
	currentTab,
}) => {
	const [showModal, setShowModal] = useState<boolean>(false);
	const hasUnsavedChanges: boolean = useSelector(
		(state: RootState) => state.campaign.hasUnsavedChanges,
	);
	const [to, setTo] = useState('');
	const nav = useNavigate();
	const dispatch = useDispatch<any>();
	const [clicked, setClicked] = useState(currentTab);
	const id = useParams();
	const location = useLocation().pathname;
	const campaign: CampaignBrief = useSelector(
		(state: RootState) => state.campaign.currentCampaign,
	);
	const {creatorBrief}: CreatorBriefResponse = useSelector(
		(state: RootState) => state.creatorBrief.currentCreatorBrief,
	);
	const currentCreatorList: Array<SelectedCreator> = useSelector(
		(state: RootState) => state.creatorList.currentCreatorList,
	);

	const handleClick = (val: string) => {
		let newPath = `/${val.replace(' ', '-').toLowerCase()}/${id.id}`;
		if (location.toLowerCase() !== newPath.toLowerCase()) {
			if (!hasUnsavedChanges) {
				setClicked(val);
				nav(newPath);
			} else {
				setShowModal(true);
				setTo(newPath);
			}
		}
	};

	const handleLeave = () => {
		setShowModal(false);
		dispatch(changeUnsavedChanges(false));
		if (to !== '') {
			nav(to);
			setTo('');
		}
	};

	const handleCancel = () => {
		setShowModal(false);
	};

	const checkCreatorBriefAvailability = () => {
		if (campaign && campaign.campaignStatus && campaign.campaignStatus > 0) {
			return true;
		} else {
			return false;
		}
	};

	const checkCreatorsAvailability = () => {
		if (
			(campaign &&
				campaign.campaignStatus &&
				campaign.campaignStatus > 0 &&
				creatorBrief.campaignId !== -1 &&
				creatorBrief.summary !== '') ||
			campaign.campaignStatus === 3
		) {
			return true;
		} else {
			return false;
		}
	};

	const checkFinalAssetsAvailability = () => {
		let hasAcceptedCreators = 0;
		currentCreatorList.forEach((c: SelectedCreator) => {
			if (c.status === 5 || c.status === 7) {
				hasAcceptedCreators++;
			}
		});
		if (
			(campaign &&
				campaign.campaignStatus &&
				campaign.campaignStatus > 0 &&
				hasAcceptedCreators > 0) ||
			campaign.campaignStatus === 3
		) {
			return true;
		} else {
			return false;
		}
	};

	const checkPerformanceAvailability = () => {
		if (
			campaign &&
			campaign.campaignStatus &&
			(campaign.campaignStatus === 3 || campaign.campaignStatus === 4)
		) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<div className={styles.headerWrapper}>
			<div className={`${styles.header}`}>
				<ul className={styles.itemsWrapper}>
					<li className={`${styles.buttonWrapper}  ${styles.formItem}`}>
						<PFPrimaryButton
							onClick={() => handleClick(CampaignTabs.SOW)}
							text={CampaignTabs.SOW}
							type="submit"
							style={
								clicked === CampaignTabs.SOW
									? styles.clickedUserBtn
									: styles.userBtn
							}
						/>
					</li>
					<li className={`${styles.buttonWrapper}  ${styles.formItem}`}>
						<PFPrimaryButton
							onClick={() => handleClick(CampaignTabs.CREATOR_BRIEF)}
							text={CampaignTabs.CREATOR_BRIEF}
							type="submit"
							style={
								clicked === CampaignTabs.CREATOR_BRIEF
									? styles.clickedUserBtn
									: styles.userBtn
							}
							disabled={!checkCreatorBriefAvailability()}
						/>
					</li>
					<li className={`${styles.buttonWrapper}  ${styles.formItem}`}>
						<PFPrimaryButton
							onClick={() => handleClick(CampaignTabs.CREATOR_LIST)}
							text={CampaignTabs.CREATOR_LIST}
							type="submit"
							style={
								clicked === CampaignTabs.CREATOR_LIST
									? styles.clickedUserBtn
									: styles.userBtn
							}
							disabled={!checkCreatorsAvailability()}
						/>
					</li>
					<li className={`${styles.buttonWrapper}  ${styles.formItem}`}>
						<PFPrimaryButton
							onClick={() => handleClick(CampaignTabs.FINAL_ASSETS)}
							text={CampaignTabs.FINAL_ASSETS}
							type="submit"
							style={
								clicked === CampaignTabs.FINAL_ASSETS
									? styles.clickedUserBtn
									: styles.userBtn
							}
							disabled={!checkFinalAssetsAvailability()}
						/>
					</li>
					<li className={`${styles.buttonWrapper}  ${styles.formItem}`}>
						<PFPrimaryButton
							onClick={() => handleClick(CampaignTabs.PERFORMANCE)}
							text={CampaignTabs.PERFORMANCE}
							type="submit"
							style={
								clicked === CampaignTabs.PERFORMANCE
									? styles.clickedUserBtn
									: styles.userBtn
							}
							disabled={!checkPerformanceAvailability()}
						/>
					</li>
				</ul>
			</div>
			<PFChangesModal
				show={showModal}
				onHide={() => setShowModal(false)}
				label={CampaignModals.CHANGES}
				onLeave={handleLeave}
				onCancel={handleCancel}
			/>
		</div>
	);
};

export default PFSingleCampaignTabs;
