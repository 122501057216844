// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_alert__5wuYj {\n  background: #EA4335;\n  text-align: center;\n  margin-bottom: 0;\n  font-size: 16px;\n  font-family: \"Bulldog\";\n  padding-top: 3px;\n  padding-bottom: 3px;\n  position: fixed;\n  z-index: 10000;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/App.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,cAAA;EACA,WAAA;AACJ","sourcesContent":[".alert{\n    background: #EA4335;\n    text-align: center;\n    margin-bottom: 0;\n    font-size: 16px;\n    font-family: 'Bulldog';\n    padding-top: 3px;\n    padding-bottom: 3px;\n    position: fixed;\n    z-index: 10000;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "App_alert__5wuYj"
};
export default ___CSS_LOADER_EXPORT___;
