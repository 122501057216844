import React from 'react';
import {Icons} from '../../../../constants/Icons';
import styles from './PFBrandTableRow.module.scss';

interface PFBrandTableRowProps {
	onDelete: (val: number, fullName: string | undefined) => void;
	onClickAccept: (id: number) => void;
	onClickDecline: (id: number) => void;
	onActivate: (id: number) => void;
	fullName?: string;
	brandName?: string;
	email?: string;
	phoneNumber?: string;
	status?: string;
	id: number;
}
export const PFBrandTableRow: React.FC<PFBrandTableRowProps> = ({
	onDelete,
	onClickAccept,
	onClickDecline,
	onActivate,
	fullName,
	brandName,
	email,
	phoneNumber,
	status,
	id,
}) => {
	return (
		<tr className={styles.tr}>
			<td style={status === 'Deleted' ? stylesObject.deleted : {}}>
				{fullName}
			</td>
			<td style={status === 'Deleted' ? stylesObject.deleted : {}}>
				{brandName}
			</td>
			<td style={status === 'Deleted' ? stylesObject.deleted : {}}>{email}</td>
			<td style={status === 'Deleted' ? stylesObject.deleted : {}}>
				{phoneNumber}
			</td>
			<td>
				<span
					style={
						status === 'Declined' || status === 'Deleted'
							? stylesObject.red
							: status === 'Pending'
							? stylesObject.orange
							: stylesObject.green
					}>
					{status}
				</span>
			</td>
			<td>
				<span
					className={styles.icons}
					style={stylesObject.edit}
					onClick={() => onClickAccept(id)}
					title="Accept brands request">
					{status === 'Pending' && Icons.TICK}
				</span>
				{status === 'Pending' ? (
					<span
						className={styles.icons}
						onClick={() => onClickDecline(id)}
						title="Decline brands request">
						{Icons.X}
					</span>
				) : (
					<span
						className={styles.icons}
						onClick={() =>
							status === 'Deleted' ? onActivate(id) : onDelete(id, fullName)
						}
						title={status === 'Deleted' ? 'Activate brand' : 'Delete brand'}>
						{status === 'Deleted' ? Icons.RELOAD : Icons.BIN}
					</span>
				)}
			</td>
		</tr>
	);
};

const stylesObject = {
	red: {
		color: 'rgba(234, 67, 53, 1)',
	},
	orange: {
		color: 'rgba(251, 139, 2, 1)',
	},
	green: {
		color: 'rgba(104, 204, 88, 1)',
	},
	deleted: {
		color: 'rgba(234, 67, 53, 1)',
	},
	edit: {
		paddingRight: '13px',
	},
};

export default PFBrandTableRow;
