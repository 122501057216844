import {
	setToken,
	removeToken,
	setNotifications,
	setHasUnreadNotifications,
	addNewNotification,
} from '../reducers/NotificationReducer';
import AuthServices from '../../services/AuthServices';
import NotificationService from '../../services/NotificationService';
import {NotificationPage} from '../../models/NotificationPage';
import {toast} from 'react-toastify';
import {Notification} from '../../models/Notifications';

export const setNotificationToken =
	(token: string | null, isFound: boolean) => (dispatch: any) => {
		dispatch(setToken({token, isFound}));
	};

export const removeNotificationToken =
	(notificationToken: string) => (dispatch: any) => {
		AuthServices.logoutUser({token: notificationToken, isActive: false}).then(
			dispatch(removeToken()),
		);
	};

export const getNotifications =
	(pageNumber: number, pageSize: number) => (dispatch: any) => {
		try {
			NotificationService.getNotifications(pageNumber, pageSize).then(
				(res: NotificationPage) => {
					dispatch(setNotifications(res));
					dispatch(hasUnreadNotifications());
				},
			);
		} catch (e) {
			toast.error('Error while getting notifications');
		}
	};

export const hasUnreadNotifications = () => (dispatch: any) => {
	try {
		NotificationService.hasNotifications().then((res: boolean) => {
			dispatch(setHasUnreadNotifications(res));
		});
	} catch (e) {
		toast.error('Error while getting unread notifications');
	}
};

export const readNotification =
	(notificationId: number, pageNumber: number, pageSize: number) =>
	(dispatch: any) => {
		try {
			NotificationService.readNotification(
				notificationId,
				pageNumber,
				pageSize,
			).then((res: NotificationPage) => {
				dispatch(setNotifications(res));
				dispatch(hasUnreadNotifications());
			});
		} catch (e) {
			toast.error('Error while reading notifications');
		}
	};

export const addNotification =
	(notification: Notification) => (dispatch: any) => {
		dispatch(setHasUnreadNotifications(true));
		dispatch(addNewNotification(notification));
	};
