import 'bootstrap/dist/css/bootstrap.css';
import React, {useState} from 'react';
import {MultiSelect, Option} from 'react-multi-select-component';
import {Creator} from '../../../../models/Creator';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import PFModal from '../../organisms/Modal/PFModal';
import styles from './PFCreatorListModal.module.scss';

interface PFCreatorListModalProps {
	onClick: (list: Array<Option>) => void;
	onHide: () => void;
	show: boolean;
	label?: string;
	creators: Array<Creator>;
}
export const PFCreatorListModal: React.FC<PFCreatorListModalProps> = ({
	onClick,
	onHide,
	show = false,
	label,
	creators,
}) => {
	const [selected, setSelected] = useState([]);

	const getOptions = () => {
		let list: Array<Option> = [];
		creators.forEach(creator => {
			creator.Name
				? list.push({value: creator, label: creator.Name})
				: list.push({value: creator, label: ''});
		});
		return list;
	};

	const handleSubmit = () => {
		onClick(selected);
		setSelected([]);
	};

	return (
		<PFModal show={show} onHide={onHide} label={label}>
			<div>
				<MultiSelect
					options={getOptions()}
					value={selected}
					labelledBy={''}
					onChange={setSelected}
				/>
				<div>
					<PFPrimaryButton
						onClick={handleSubmit}
						text={selected.length <= 1 ? 'ADD CREATOR' : 'ADD CREATORS'}
						type="submit"
						style={styles.btn}></PFPrimaryButton>
				</div>
			</div>
		</PFModal>
	);
};

export default PFCreatorListModal;
