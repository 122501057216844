import React from 'react';
import {useSelector} from 'react-redux';
import {SelectedCreator} from '../../../../models/SelectedCreator';
import {RootState} from '../../../../store/reducers/RootReducer';
import PFCreatorListTable from '../../molecules/CreatorListTable/PFCreatorListTable';
import {PFPerformanceMetrics} from '../PerformanceMetrics/PFPerformanceMetrics';
import styles from './PFPerformance.module.scss';

interface PFPerformanceProps {
	campaignId: string;
}

export const PFPerformance: React.FC<PFPerformanceProps> = ({campaignId}) => {
	const currentCreatorList: Array<SelectedCreator> = useSelector(
		(state: RootState) => state.creatorList.currentCreatorList,
	);
	const currentCampaign = useSelector(
		(state: RootState) => state.campaign.currentCampaign,
	);

	const hasCampaignEnded =
		currentCampaign && currentCampaign.campaignStatus
			? currentCampaign.campaignStatus === 4
			: false;

	return (
		<div className={styles.page}>
			<div className={styles.highlight}>Campaign Performance</div>
			<div className={styles.contentWrapper}>
				<PFPerformanceMetrics performance={currentCampaign.targetGoals} />
			</div>
			<div className={styles.highlight}>Creator Performance</div>

			<div className={styles.contentWrapper}>
				<div className={styles.FaTWrapper}>
					<PFCreatorListTable
						isFinalAsset={false}
						isExpandable
						users={currentCreatorList}
						campaignId={campaignId}
						hasCampaignEnded={hasCampaignEnded}
					/>
				</div>
			</div>
		</div>
	);
};
