import {useDispatch, useSelector} from 'react-redux';
import PFNotificationCard from '../../UI/molecules/NotificationCard/NotificationCard';
import Sidebar from '../../UI/molecules/sidebar/Sidebar';
import styles from './NotificationsPage.module.scss';
import {
	getNotifications,
	hasUnreadNotifications,
	readNotification,
} from '../../../store/actions/NotificationActions';
import {RootState} from '../../../store/reducers/RootReducer';
import {useEffect, useState} from 'react';
import GlobalFooter from '../../UI/molecules/GlobalFooter/GlobalFooter';
import {Notification} from '../../../models/Notifications';
import NotificationService from '../../../services/NotificationService';
import GlobalHeader from '../../UI/molecules/globalHeader/GlobalHeader';

interface Props {}

function Notifications(props: Props) {
	const {} = props;
	const [currentFirstRow, setCurrentFirstRow] = useState(1);
	const [currentLastRow, setCurrentLastRow] = useState(5);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [currentPage, setCurrentPage] = useState(1);
	const dispatch = useDispatch<any>();

	const countMin = (val1: number, val2: number) => {
		if (val1 <= val2) return val1;
		return val2;
	};

	const notifications: Array<Notification> = useSelector(
		(state: RootState) => state.notification.notifications.notifications,
	);

	const totalData: number = useSelector(
		(state: RootState) => state.notification.notifications.totalData,
	);

	const GetNotifications = (pageNumber: number, pageSize: number) => {
		dispatch(getNotifications(pageNumber, pageSize));
	};

	const handleSelectChange = (val: string) => {
		const newVal: number = Number(val) > totalData ? totalData : Number(val);
		setCurrentLastRow(Number(newVal));
		setCurrentFirstRow(1);
		setRowsPerPage(Number(val));
		GetNotifications(1, Number(val));
	};

	const handleRightClick = () => {
		var newPage = currentPage + 1;
		var newLastRow = countMin(currentLastRow + rowsPerPage, totalData);
		var newFirstRow = currentLastRow + 1;
		setCurrentFirstRow(newFirstRow);
		setCurrentLastRow(newLastRow);
		setCurrentPage(newPage);
		GetNotifications(currentPage + 1, rowsPerPage);
	};

	const handleLeftClick = () => {
		var newPage = currentPage - 1;
		var newLastRow = currentFirstRow - 1;
		var newFirstRow =
			currentFirstRow - rowsPerPage <= 0 ? 1 : currentFirstRow - rowsPerPage;
		setCurrentFirstRow(newFirstRow);
		setCurrentLastRow(newLastRow);
		setCurrentPage(newPage);
		GetNotifications(currentPage - 1, rowsPerPage);
	};

	useEffect(() => {
		setCurrentLastRow(countMin(rowsPerPage, totalData));
	}, [totalData]);

	useEffect(() => {
		GetNotifications(currentPage, rowsPerPage);
	}, []);

	useEffect(() => {
		var ids: Array<number> = [];
		notifications.forEach(n => {
			if (n.isRead === false) ids.push(n.id);
		});
		NotificationService.readNotifications(ids).then(
			dispatch(hasUnreadNotifications()),
		);
	}, [notifications]);

	const handleWheelClick = (notificationId: number) => {
		dispatch(readNotification(notificationId, currentPage, rowsPerPage));
	};

	return (
		<div className={styles.page}>
			<div className={styles.sidebarWrapper}>
				<Sidebar activePage="notifications" />
			</div>
			<div className={styles.contentWrapper}>
				<div className={styles.headerWrapper}>
					<GlobalHeader label="Notifications" hasButton={false} />
				</div>
				<div className={styles.cardsWrapper}>
					{notifications.length > 0 ? (
						notifications.map(n => {
							return (
								<PFNotificationCard
									body={n.body}
									title={n.title}
									key={n.id}
									handleWheelClick={handleWheelClick}
									id={n.id}
									isRead={n.isRead}
									notificationType={n.notificationType}
									entityId={n.entityId}
									created={n.created}
								/>
							);
						})
					) : (
						<div className={styles.empty}>There are no notifications</div>
					)}
				</div>
				<div className={`${styles.footerWrapper}`}>
					<GlobalFooter
						firstRow={currentFirstRow}
						lastRow={currentLastRow}
						disabledLeft={currentFirstRow === 1 ? true : false}
						disabledRight={currentLastRow >= totalData ? true : false}
						totalData={totalData}
						handleRightClick={handleRightClick}
						handleLeftClick={handleLeftClick}
						rowsPerPage={rowsPerPage}
						handleSelectChange={handleSelectChange}
					/>
				</div>
			</div>
		</div>
	);
}

export default Notifications;
