import React, {useEffect, useState} from 'react';
import styles from './AdminTable.module.scss';
import PFAdminTableRow from '../../atoms/AdminTableRow/PFAdminTableRow';
import {Admin} from '../../../../models/Admin';
import PFSortButton from '../../atoms/SortButton/PFSortButton';
import {User} from '../../../../models/User';
import {useDispatch, useSelector} from 'react-redux';
import {
	activateAdminAction,
	deleteAdmin,
	filterAdminsAction,
	setFilterKeyAction,
	setSortOrderAction,
} from '../../../../store/actions/UserActions';
import {getUserStatus} from '../../../../helper/helper';
import DeleteUserModal from '../DeleteUserModal/DeleteUserModal';
import {CampaignModals} from '../../../../constants/CampaignModals';
import {RootState} from '../../../../store/reducers/RootReducer';

interface AdminTableProps {
	users: Array<Admin>;
}

type SortKeys = 'email' | 'fullName';
type SortOrder = 'asc' | 'desc';

const AdminTable: React.FC<AdminTableProps> = ({users}) => {
	const [sortKey, setSortKey] = useState<SortKeys>('email');
	const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
	const [fullName, setFullName] = useState<string | undefined>(undefined);
	const [id, setId] = useState(-1);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const dispatch = useDispatch<any>();

	const rowsPerPage: number = useSelector(
		(state: RootState) => state.user.rowsPerPage,
	);
	const currentPage: number = useSelector(
		(state: RootState) => state.user.currentPage,
	);

	const handleDelete = (id: number, fullName: string | undefined) => {
		setShowDeleteModal(true);
		setId(id);
		setFullName(fullName);
	};

	const handleCancel = () => {
		setShowDeleteModal(false);
	};

	const handleDeleteClick = () => {
		const data: User = {
			id: id,
			fullName: fullName,
		};
		dispatch(deleteAdmin(data));
		setShowDeleteModal(false);
	};

	const handleActivate = (value: number) => {
		const data: User = {
			id: value,
		};
		dispatch(activateAdminAction(data));
	};

	function changeSort(key: SortKeys) {
		dispatch(setFilterKeyAction(key));
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
		dispatch(setSortOrderAction(sortOrder.toUpperCase()));
		dispatch(
			filterAdminsAction(
				key,
				sortOrder.toUpperCase(),
				currentPage,
				rowsPerPage,
			),
		);
	}
	useEffect(() => {
		dispatch(setFilterKeyAction(''));
	}, []);
	return (
		<div>
			<table id={styles.users}>
				<thead>
					<tr>
						<th>
							Full Name{' '}
							<PFSortButton
								columnKey="Name"
								onClick={() => changeSort('fullName')}
								{...{
									sortOrder,
									sortKey,
								}}
							/>
						</th>
						<th>
							Email{' '}
							<PFSortButton
								columnKey="email"
								onClick={() => changeSort('email')}
								{...{
									sortOrder,
									sortKey,
								}}
							/>
						</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{users.map(user => {
						return (
							<PFAdminTableRow
								key={user.id}
								onDelete={handleDelete}
								onActivate={handleActivate}
								fullName={user.Name}
								email={user.email}
								id={user.id}
								status={getUserStatus(user.status)}
							/>
						);
					})}
				</tbody>
			</table>
			{users.length === 0 && (
				<div className={styles.empty}>There are no admins</div>
			)}
			<DeleteUserModal
				show={showDeleteModal}
				onHide={handleCancel}
				onDelete={handleDeleteClick}
				onCancel={handleCancel}
				fullName={fullName}
				label={CampaignModals.DELETE_USER}
			/>
		</div>
	);
};

export default AdminTable;
