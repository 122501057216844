import {Field, Formik} from 'formik';
import React from 'react';
import PFModal from '../../organisms/Modal/PFModal';
import {AdminModalValidationScheme} from '../../../../validation/AdminModalValidationScheme';
import styles from './PFAdminModal.module.scss';
import {PFFormikTextInput} from '../../atoms/FormikTextInput/PFFormikTextInput';
import 'bootstrap/dist/css/bootstrap.css';
import {NewAdmin} from '../../../../models/NewAdmin';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import {Users} from '../../../../constants/Users';

interface PFAdminModalProps {
	onClick: (email: string, fullName: string) => void;
	onHide: () => void;
	show: boolean;
	label?: string;
}
export const PFAdminModal: React.FC<PFAdminModalProps> = ({
	onClick,
	onHide,
	show = false,
	label,
}) => {
	return (
		<PFModal show={show} onHide={onHide} label={Users.ADMIN}>
			<div>
				<Formik
					initialValues={{email: '', name: ''}}
					validationSchema={AdminModalValidationScheme}
					onSubmit={(values: NewAdmin) => {
						onClick(values.name, values.email);
					}}>
					{formik => (
						<>
							<div className={styles.divFlex}>
								<Field
									label="Full Name"
									component={PFFormikTextInput}
									placeholder="Enter Full Name"
									name="name"
									type="text"
									value=""
									style={styles.formikInputLeft}
									styleForInput={styles.styleForInputLeft}
									styleForErrorLabel={styles.styleForErrorLabel}
								/>
								<Field
									label="Email Address"
									component={PFFormikTextInput}
									placeholder="Enter email"
									name="email"
									type="text"
									value=""
									style={styles.formikInputRight}
									styleForInput={styles.styleForInputRight}
									styleForErrorIcon={styles.styleForErrorIcon}
								/>
							</div>
							<div>
								<PFPrimaryButton
									onClick={() => formik.handleSubmit()}
									text={label === Users.ADMIN ? 'CREATE USER' : 'INVITE USER'}
									type="submit"
									style={styles.btn}></PFPrimaryButton>
							</div>
						</>
					)}
				</Formik>
			</div>
		</PFModal>
	);
};

export default PFAdminModal;
