import 'bootstrap/dist/css/bootstrap.css';
import React, {useState} from 'react';
import {CampaignModals} from '../../../../constants/CampaignModals';
import {Countries} from '../../../../constants/Countries';
import {Country} from '../../../../models/Country';
import PFLiveMarketSelectMenu from '../../atoms/LiveMarketSelectMenu/PFLiveMarketSelectMenu';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import PFModal from '../../organisms/Modal/PFModal';
import styles from './PFLiveMarketModal.module.scss';

interface PFLiveMarketModalProps {
	onClick: (country: Country) => void;
	onHide: () => void;
	show: boolean;
	label?: string;
	markets: Array<Country>;
	size?: 'sm' | 'lg';
}
export const PFLiveMarketModal: React.FC<PFLiveMarketModalProps> = ({
	onClick,
	onHide,
	show = false,
	label,
	size,
	markets,
}) => {
	const [market, setMarket] = useState<string | null>(null);
	const setCountry = (country: string | null) => {
		if (country !== null && country !== '') setMarket(country);
	};

	const getId = (country: string | null) => {
		const countryId = Countries.findIndex(c => c.label === country);
		return countryId;
	};
	const handleOnAddMarket = () => {
		if (market !== null && market !== '') {
			onClick({id: getId(market), name: market});
			setMarket(null);
		}
	};

	return (
		<PFModal
			size={size}
			show={show}
			onHide={onHide}
			label={CampaignModals.LIVE_MARKET}>
			<div>
				<div>Choose Market</div>
				<PFLiveMarketSelectMenu
					onChange={setCountry}
					chosenCountries={markets}
				/>
				<div>
					<PFPrimaryButton
						onClick={handleOnAddMarket}
						text={'ADD LIVE MARKET'}
						type="submit"
						disabled={market === null || market === ''}
						style={
							market === null || market === '' ? styles.disabled : styles.btn
						}
					/>
				</div>
			</div>
		</PFModal>
	);
};

export default PFLiveMarketModal;
