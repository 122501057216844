import axios from 'axios';
import {Campaign} from '../models/Campaign';
import {CampaignPage} from '../models/CampaignPage';
import {CreateCampaignRequest} from '../models/CreateCampaignRequest';
import {SearchRequest} from '../models/SearchRequest';
import {TablePageModel} from '../models/TablePageModel';
import '../interceptors/axiosInterceptor';

interface CampaignService {
	getCampaignPage(data: TablePageModel): Promise<CampaignPage>;
	getActiveCampaignPage(data: TablePageModel): Promise<CampaignPage>;
	getPendingCampaignPage(data: TablePageModel): Promise<CampaignPage>;
	getEndedCampaignPage(data: TablePageModel): Promise<CampaignPage>;
	createCampaign(data: CreateCampaignRequest): Promise<Campaign>;
	createFile(id: number, data: FormData): Promise<any>;
	createImages(id: number, data: FormData): Promise<any>;
	deleteCampaignImages(id: number, images: Array<string>): Promise<any>;
	getCampaignMetaId(campaignId: string): Promise<MetaCampaignId>;
	editCampaignMetaId(
		campaignId: string,
		metaCampaignId: string,
	): Promise<string>;
	changeCampaignStatus(campaignId: string, status: number): Promise<any>;
}

const ENDPOINTS = {
	CAMPAIGNS: `${process.env.REACT_APP_BASE_URL}/api/Campaign/all-campaigns`,
	CREATE_CAMPAIGN: `${process.env.REACT_APP_BASE_URL}/api/Campaign/campaign-brief`,
	GET_CAMPAIGN: `${process.env.REACT_APP_BASE_URL}/api/Campaign/campaign/`,
	DELETE_FILE: `${process.env.REACT_APP_BASE_URL}/api/Campaign/delete-file?`,
	DELETE_IMAGES: `${process.env.REACT_APP_BASE_URL}/api/Campaign/delete-images/`,
	CAMPAIGN_META_ID: `${process.env.REACT_APP_BASE_URL}/api/Campaign/meta-campaign-id`,
	CHANGE_STATUS: `${process.env.REACT_APP_BASE_URL}/api/Campaign/change-status/`,
};

interface MetaCampaignId {
	metaCampaignId: string;
}

class CampaignAxiosService implements CampaignService {
	async getCampaignPage(data: TablePageModel): Promise<CampaignPage> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');

		try {
			const response = await axios.get(
				ENDPOINTS.CAMPAIGNS +
					'?pageNumber=' +
					data.pageNumber +
					'&pageSize=' +
					data.pageSize,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async getActiveCampaignPage(data: TablePageModel): Promise<CampaignPage> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.CAMPAIGNS +
					'?pageNumber=' +
					data.pageNumber +
					'&pageSize=' +
					data.pageSize +
					'&status=3',
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async getApprovedCampaignPage(data: TablePageModel): Promise<CampaignPage> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.CAMPAIGNS +
					'?pageNumber=' +
					data.pageNumber +
					'&pageSize=' +
					data.pageSize +
					'&status=1',
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async getPendingCampaignPage(data: TablePageModel): Promise<CampaignPage> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.CAMPAIGNS +
					'?pageNumber=' +
					data.pageNumber +
					'&pageSize=' +
					data.pageSize +
					'&status=0',
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async getEndedCampaignPage(data: TablePageModel): Promise<CampaignPage> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.CAMPAIGNS +
					'?pageNumber=' +
					data.pageNumber +
					'&pageSize=' +
					data.pageSize +
					'&status=4',
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}
	async getDeclinedCampaignPage(data: TablePageModel): Promise<CampaignPage> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.CAMPAIGNS +
					'?pageNumber=' +
					data.pageNumber +
					'&pageSize=' +
					data.pageSize +
					'&status=2',
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}
	async searchCampaignPage(data: SearchRequest): Promise<CampaignPage> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.CAMPAIGNS +
					'?pageNumber=' +
					data.pageNumber +
					'&pageSize=' +
					data.pageSize +
					'&input=' +
					data.value,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async searchActiveCampaignPage(data: SearchRequest): Promise<CampaignPage> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.CAMPAIGNS +
					'?pageNumber=' +
					data.pageNumber +
					'&pageSize=' +
					data.pageSize +
					'&input=' +
					data.value +
					'&status=1',
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async searchPendingCampaignPage(data: SearchRequest): Promise<CampaignPage> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.CAMPAIGNS +
					'?pageNumber=' +
					data.pageNumber +
					'&pageSize=' +
					data.pageSize +
					'&input=' +
					data.value +
					'&status=0',
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async searchEndedCampaignPage(data: SearchRequest): Promise<CampaignPage> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.CAMPAIGNS +
					'?pageNumber=' +
					data.pageNumber +
					'&pageSize=' +
					data.pageSize +
					'&input=' +
					data.value +
					'&status=2',
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async createCampaign(data: CreateCampaignRequest): Promise<Campaign> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.post(
				ENDPOINTS.CREATE_CAMPAIGN,
				{
					name: data.name,
					clientId: data.clientId,
					campaignBrief: data.campaignBrief,
					targetGoals: data.targetGoals,
					campaignSteps: data.campaignSteps,
					budgets: data.budgets,
					dates: data.dates,
					liveMarkets: data.liveMarkets,
				},
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			return Promise.reject(error);
		}
	}

	async createFile(id: number, data: FormData): Promise<any> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			data.append('CampaignId', id.toString());
			const response = await axios.post(
				ENDPOINTS.CREATE_CAMPAIGN + '-file',
				data,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async createImages(id: number, data: FormData): Promise<any> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			data.append('CampaignId', id.toString());
			const response = await axios.post(
				ENDPOINTS.CREATE_CAMPAIGN + '-images',
				data,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async deleteCampaignImages(id: number, images: Array<string>): Promise<any> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.post(ENDPOINTS.DELETE_IMAGES + id, images, {
				headers: {
					Authorization: 'Bearer ' + Token,
				},
			});
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async getCampaignBrief(id: string): Promise<any> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(ENDPOINTS.GET_CAMPAIGN + id, {
				headers: {
					Authorization: 'Bearer ' + Token,
				},
			});

			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async deleteCampaignBrief(
		campaignID: number,
		kindOfFile: string,
	): Promise<any> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.delete(
				ENDPOINTS.DELETE_FILE +
					`campaignId=${campaignID}&fileKind=${kindOfFile}`,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async updateCampaignImage(campaignID: number, data: FormData): Promise<any> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			data.append('CampaignId', campaignID.toString());
			const response = await axios.put(
				ENDPOINTS.CREATE_CAMPAIGN + '-images',
				data,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			return Promise.reject(error);
		}
	}

	async updateCampaignBriefFile(
		campaignID: number,
		data: FormData,
	): Promise<any> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			data.append('CampaignId', campaignID.toString());
			const response = await axios.put(
				ENDPOINTS.CREATE_CAMPAIGN + '-file',
				data,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			return Promise.reject(error);
		}
	}

	async updateCampaignBrief(
		campaignID: number,
		data: CreateCampaignRequest,
	): Promise<any> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.put(
				ENDPOINTS.CREATE_CAMPAIGN + `/${campaignID}`,
				{
					name: data.name,
					clientId: data.clientId,
					campaignBrief: data.campaignBrief,
					targetGoals: data.targetGoals,
					campaignSteps: data.campaignSteps,
					budgets: data.budgets,
					dates: data.dates,
					liveMarkets: data.liveMarkets,
				},
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			return Promise.reject(error);
		}
	}

	async getCampaignMetaId(campaignId: string): Promise<MetaCampaignId> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');

		try {
			const response = await axios.get(
				ENDPOINTS.CAMPAIGN_META_ID + '/' + campaignId,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);

			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async editCampaignMetaId(
		campaignId: string,
		metaCampaignId: string,
	): Promise<string> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');

		try {
			const response = await axios.put(
				ENDPOINTS.CAMPAIGN_META_ID,
				{CampaignId: Number(campaignId), MetaCampaignId: metaCampaignId},
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);

			return response.data;
		} catch (error: any) {
			return Promise.reject(error);
		}
	}

	async changeCampaignStatus(campaignId: string, status: number): Promise<any> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');

		try {
			const response = await axios.put(
				ENDPOINTS.CHANGE_STATUS + campaignId,
				{status: status, comment: ''},
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			return Promise.reject(error);
		}
	}

	checkName = (name: string): boolean => {
		switch (name) {
			case 'Sales':
				return true;
			case 'App Downloads':
				return false;
			case 'Sign-Ups':
				return false;
			case 'Requests':
				return false;
			case 'Shares':
				return false;
			case 'Positive Comments':
				return false;
			case 'Likes':
				return false;
			default:
				return true;
		}
	};
}

export default new CampaignAxiosService();
