import React, {useState} from 'react';
import styles from './PFCampaignsTabs.module.scss';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';

interface PFCampaignsTabsProps {
	onClick: (retVal: string) => void;
}

const PFCampaignsTabs: React.FC<PFCampaignsTabsProps> = ({onClick}) => {
	const handleClick = (val: string) => {
		setClicked(val);
	};
	const [clicked, setClicked] = useState('All');
	function handleChangeTab(val: string) {
		handleClick(val);
		onClick(val);
	}
	return (
		<div className={styles.headerWrapper}>
			<div className={`${styles.header}`}>
				<ul className={styles.itemsWrapper}>
					<li className={`${styles.buttonWrapper}  ${styles.formItem}`}>
						<PFPrimaryButton
							onClick={() => handleChangeTab('All')}
							text="All"
							type="submit"
							style={clicked === 'All' ? styles.clickedUserBtn : styles.userBtn}
						/>
					</li>
					<li className={`${styles.buttonWrapper}  ${styles.formItem}`}>
						<PFPrimaryButton
							onClick={() => handleChangeTab('Active')}
							text="Active"
							type="submit"
							style={
								clicked === 'Active' ? styles.clickedUserBtn : styles.userBtn
							}
						/>
					</li>
					<li className={`${styles.buttonWrapper}  ${styles.formItem}`}>
						<PFPrimaryButton
							onClick={() => handleChangeTab('Approved')}
							text="Approved"
							type="submit"
							style={
								clicked === 'Approved' ? styles.clickedUserBtn : styles.userBtn
							}
						/>
					</li>
					<li className={`${styles.buttonWrapper}  ${styles.formItem}`}>
						<PFPrimaryButton
							onClick={() => handleChangeTab('Pending')}
							text="Pending"
							type="submit"
							style={
								clicked === 'Pending' ? styles.clickedUserBtn : styles.userBtn
							}
						/>
					</li>
					<li className={`${styles.buttonWrapper}  ${styles.formItem}`}>
						<PFPrimaryButton
							onClick={() => handleChangeTab('Declined')}
							text="Declined"
							type="submit"
							style={
								clicked === 'Declined' ? styles.clickedUserBtn : styles.userBtn
							}
						/>
					</li>
					<li className={`${styles.buttonWrapper}  ${styles.formItem}`}>
						<PFPrimaryButton
							onClick={() => handleChangeTab('Ended')}
							text="Ended"
							type="submit"
							style={
								clicked === 'Ended' ? styles.clickedUserBtn : styles.userBtn
							}
						/>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default PFCampaignsTabs;
