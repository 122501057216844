import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Budget} from '../../models/Budget';
import {Campaign} from '../../models/Campaign';
import {CampaignBrief} from '../../models/CampaignBrief';
import {CampaignImage} from '../../models/CampaignImage';
import {CampaignPage} from '../../models/CampaignPage';
import {DateLive} from '../../models/Date';
import {TargetGoal} from '../../models/TargetGoal';

interface CampaignState {
	totalPages: number;
	currentPage: number;
	totalData: number;
	empty: boolean;
	campaigns: Array<Campaign>;
	isLoading: boolean;
	isCampaignUploading: boolean;
	currentCampaign: CampaignBrief;
	currentCampaignMetaId: string;
	hasUnsavedChanges: boolean;
}

const initialState: CampaignState = {
	totalData: 0,
	currentPage: 0,
	totalPages: 0,
	empty: false,
	campaigns: [],
	isLoading: false,
	isCampaignUploading:false,
	currentCampaignMetaId: '',
	currentCampaign: {
		id: -1,
		name: '',
		clientId: -1,
		campaignBrief: '',
		campaignBriefUrl: '',
		targetGoals: new Array<TargetGoal>(),
		campaignSteps: new Array<number>(),
		campaignImages: new Array<CampaignImage>(),
		budgets: new Array<Budget>(),
		dates: new Array<DateLive>(),
		liveMarkets: new Array<string>(),
	},
	hasUnsavedChanges: false,
};

const campaignSlice = createSlice({
	initialState,
	name: 'campaignReducer',
	reducers: {
		setCampaignPage(state, {payload}: PayloadAction<CampaignPage>) {
			state.totalData = payload.totalData || -1;
			state.currentPage = payload.currentPage || -1;
			state.totalPages = payload.totalPages || -1;
			state.empty = payload.campaigns.length === 0;
			state.campaigns = payload.campaigns || [];
		},
		addCampaign(state, {payload}: PayloadAction<Campaign>) {
			state.campaigns = [payload, ...state.campaigns];
		},
		setLoading(state, {payload}: PayloadAction<boolean>) {			
			state.isLoading = payload;
		},
		setCurrentCampaign(state, {payload}: PayloadAction<CampaignBrief>) {
			state.currentCampaign = payload;
		},
		setCurrentCampaignBrief(state, {payload}: PayloadAction<string>) {
			state.currentCampaign.campaignBriefUrl = payload;
		},
		setCampaignMetaId(state, {payload}: PayloadAction<string>) {
			state.currentCampaignMetaId = payload;
		},
		setCampaignStatus(state, {payload}: PayloadAction<number>) {
			state.currentCampaign.campaignStatus = payload;
		},
		setUnsavedChanges(state, {payload}: PayloadAction<boolean>) {
			state.hasUnsavedChanges = payload;
		},
		setCampaignUploading(state, {payload}: PayloadAction<boolean>){
			state.isCampaignUploading = payload;
		}
	},
});

export const {
	setCampaignPage,
	addCampaign,
	setLoading,
	setCurrentCampaign,
	setCurrentCampaignBrief,
	setCampaignMetaId,
	setCampaignStatus,
	setUnsavedChanges,
	setCampaignUploading
} = campaignSlice.actions;

export default campaignSlice.reducer;
