import 'bootstrap/dist/css/bootstrap.css';
import {Field, Formik} from 'formik';
import React from 'react';
import {CampaignModals} from '../../../../constants/CampaignModals';
import {UsageTime} from '../../../../models/UsageTime';
import {usageTimeValidationScheme} from '../../../../validation/UsageTimeValidationScheme';
import {PFFormikTextInput} from '../../atoms/FormikTextInput/PFFormikTextInput';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import PFModal from '../../organisms/Modal/PFModal';
import styles from './PFUsageTimeModal.module.scss';

interface PFUsageTimeModalProps {
	onClick: (usageTime: UsageTime) => void;
	onHide: () => void;
	show: boolean;
	label?: string;
	size?: 'sm' | 'lg';
	usageTimeValues?: Array<string>;
}
export const PFUsageTimeModal: React.FC<PFUsageTimeModalProps> = ({
	onClick,
	onHide,
	show = false,
	label,
	size = 'sm',
	usageTimeValues,
}) => {
	const initialVals: UsageTime = {
		name: '',
		value: '',
		id: 0,
	};

	return (
		<PFModal
			size={size}
			show={show}
			onHide={onHide}
			label={CampaignModals.USAGE_TIME}>
			<div>
				<Formik
					initialValues={initialVals}
					validationSchema={usageTimeValidationScheme}
					onSubmit={(values: UsageTime) => {
						onClick({
							name: values.name,
							value: values.value,
							id: 0,
						});
					}}>
					{formik => (
						<>
							<div className={styles.col}>
								<div>
									<Field
										label="Name"
										component={PFFormikTextInput}
										placeholder="Enter Name"
										name="name"
										style={styles.brand}
										styleForInput={styles.inputName}
										value={''}
									/>
								</div>
								<div>
									<Field
										label="Value"
										component={PFFormikTextInput}
										placeholder="Enter Value"
										name="value"
										style={styles.brand}
										styleForInput={styles.inputName}
										value={''}
									/>
								</div>

								<div>
									<PFPrimaryButton
										onClick={() => {
											if (usageTimeValues) {
												if (usageTimeValues.includes(formik.values.name)) {
													formik.setFieldError(
														'name',
														'Usage Time with this name already exists.',
													);
												} else {
													formik.handleSubmit();
												}
											}
										}}
										text={'ADD USAGE TIME'}
										type="submit"
										style={styles.btn}></PFPrimaryButton>
								</div>
							</div>
						</>
					)}
				</Formik>
			</div>
		</PFModal>
	);
};

export default PFUsageTimeModal;
