import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Admin} from '../../models/Admin';
import {AdminPage} from '../../models/AdminPage';
import {AdminsSearch} from '../../models/AdminsSearch';
import {Brand} from '../../models/Brand';
import {BrandPage} from '../../models/BrandPage';
import {BrandsSearch} from '../../models/BrandsSearch';
import {Creator} from '../../models/Creator';
import {CreatorPage} from '../../models/CreatorPage';
import {CreatorsSearch} from '../../models/CreatorsSearch';
import {EditUserResponse} from '../../models/EditUserResponse';
import {SelectBrand} from '../../models/SelectBrand';
import {User} from '../../models/User';

interface UserState {
	totalPages: number;
	currentPage: number;
	totalData: number;
	empty: boolean;
	admins: Array<Admin>;
	creators: Array<Creator>;
	brands: Array<Brand>;
	allBrands: Array<SelectBrand>;
	isLoading: boolean;
	allCreators: Array<Creator>;
	dateOfDeletion: string;
	rowsPerPage: number;
	filterKey: string;
	sortOrder: string;
}

const initialState: UserState = {
	totalData: 0,
	currentPage: 0,
	totalPages: 0,
	empty: false,
	admins: [],
	creators: [],
	brands: [],
	allBrands: [],
	isLoading: false,
	allCreators: [],
	dateOfDeletion: '',
	rowsPerPage: 10,
	filterKey: '',
	sortOrder: 'ASC',
};

const userSlice = createSlice({
	initialState,
	name: 'userReducer',
	reducers: {
		setAdminPage(state, {payload}: PayloadAction<AdminPage>) {
			state.totalData = payload.totalData || -1;
			state.currentPage = payload.currentPage || -1;
			state.totalPages = payload.totalPages || -1;
			state.empty = payload.admins.length === 0;
			state.admins = payload.admins || [];
		},
		setCreatorPage(state, {payload}: PayloadAction<CreatorPage>) {
			state.totalData = payload.totalData || -1;
			state.currentPage = payload.currentPage || -1;
			state.totalPages = payload.totalPages || -1;
			state.empty = payload.creators.length === 0;
			state.creators = payload.creators || [];
		},
		setBrandPage(state, {payload}: PayloadAction<BrandPage>) {
			state.totalData = payload.totalData || -1;
			state.currentPage = payload.currentPage || -1;
			state.totalPages = payload.totalPages || -1;
			state.empty = payload.clients.length === 0;
			state.brands = payload.clients || [];
		},
		searchAdminPage(state, {payload}: PayloadAction<AdminsSearch>) {
			state.totalData = payload.totalData || -1;
			state.currentPage = payload.currentPage || -1;
			state.totalPages = payload.totalPages || -1;
			state.empty = payload.users.length === 0;
			state.admins = payload.users || [];
		},
		searchBrandPage(state, {payload}: PayloadAction<BrandsSearch>) {
			state.totalData = payload.totalData || -1;
			state.currentPage = payload.currentPage || -1;
			state.totalPages = payload.totalPages || -1;
			state.empty = payload.users.length === 0;
			state.brands = payload.users || [];
		},
		searchCreatorPage(state, {payload}: PayloadAction<CreatorsSearch>) {
			state.totalData = payload.totalData || -1;
			state.currentPage = payload.currentPage || -1;
			state.totalPages = payload.totalPages || -1;
			state.empty = payload.users.length === 0;
			state.creators = payload.users || [];
		},
		editCreatorPage(state, {payload}: PayloadAction<EditUserResponse>) {
			state.creators.find(creator => creator.id == payload.id)!.status =
				payload.status;
		},
		editBrandPage(state, {payload}: PayloadAction<EditUserResponse>) {
			state.brands.find(brand => brand.id == payload.id)!.status =
				payload.status;
		},
		removeAdmin(state, {payload}: PayloadAction<User>) {
			var foundIndex = state.admins.findIndex(admin => admin.id == payload.id);
			if (foundIndex !== -1) {
				state.admins.splice(foundIndex, 1);
			}
		},
		activateAdmin(state, {payload}: PayloadAction<User>) {
			var foundIndex = state.admins.findIndex(admin => admin.id == payload.id);
			if (foundIndex !== -1) {
				state.admins[foundIndex].status = 1;
			}
		},
		removeCreator(state, {payload}: PayloadAction<User>) {
			var foundIndex = state.creators.findIndex(
				creator => creator.id == payload.id,
			);
			if (foundIndex !== -1) {
				state.creators.splice(foundIndex, 1);
			}
		},
		activateCreator(state, {payload}: PayloadAction<User>) {
			var foundIndex = state.creators.findIndex(
				creator => creator.id == payload.id,
			);
			if (foundIndex !== -1) {
				state.creators[foundIndex].status = 1;
			}
		},
		removeBrand(state, {payload}: PayloadAction<User>) {
			var foundIndex = state.brands.findIndex(brand => brand.id == payload.id);
			if (foundIndex !== -1) {
				state.brands.splice(foundIndex, 1);
			}
		},
		activateBrand(state, {payload}: PayloadAction<User>) {
			var foundIndex = state.brands.findIndex(brand => brand.id == payload.id);
			if (foundIndex !== -1) {
				state.brands[foundIndex].status = 1;
			}
		},
		getAllBrands(state, {payload}: PayloadAction<Array<SelectBrand>>) {
			state.allBrands = payload || [];
		},
		setLoading(state, {payload}: PayloadAction<boolean>) {
			state.isLoading = payload;
		},
		getAllCreators(state, {payload}: PayloadAction<Array<Creator>>) {
			state.allCreators = payload;
		},
		setDateOfDeletion(state, {payload}: PayloadAction<string>) {
			state.dateOfDeletion = payload;
		},
		filterCreators(state, {payload}: PayloadAction<CreatorPage>) {
			state.creators = payload.creators || [];
			state.totalData = payload.totalData || -1;
			state.currentPage = payload.currentPage || -1;
			state.totalPages = payload.totalPages || -1;
			state.empty = payload.creators.length === 0;
		},
		filterBrands(state, {payload}: PayloadAction<BrandPage>) {
			state.brands = payload.clients || [];
			state.totalData = payload.totalData || -1;
			state.currentPage = payload.currentPage || -1;
			state.totalPages = payload.totalPages || -1;
			state.empty = payload.clients.length === 0;
		},
		filterAdmins(state, {payload}: PayloadAction<AdminPage>) {
			state.admins = payload.admins || [];
			state.totalData = payload.totalData || -1;
			state.currentPage = payload.currentPage || -1;
			state.totalPages = payload.totalPages || -1;
			state.empty = payload.admins.length === 0;
		},
		setRowsPerPage(state, {payload}: PayloadAction<number>) {
			state.rowsPerPage = payload;
		},
		setFilterKey(state, {payload}: PayloadAction<string>) {
			state.filterKey = payload;
		},
		setSortOrder(state, {payload}: PayloadAction<string>) {
			state.sortOrder = payload;
		},
	},
});

export const {
	setAdminPage,
	setCreatorPage,
	setBrandPage,
	setLoading,
	searchAdminPage,
	searchBrandPage,
	searchCreatorPage,
	editCreatorPage,
	editBrandPage,
	removeAdmin,
	removeCreator,
	removeBrand,
	getAllBrands,
	getAllCreators,
	activateAdmin,
	activateBrand,
	activateCreator,
	setDateOfDeletion,
	filterCreators,
	setRowsPerPage,
	filterBrands,
	filterAdmins,
	setFilterKey,
	setSortOrder,
} = userSlice.actions;

export default userSlice.reducer;
