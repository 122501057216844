import React, {useState} from 'react';
import styles from './Sidebar.module.scss';
import {Images} from '../../../../constants/Images';
import PFIconButton from '../../atoms/IconButton/PFIconButton';
import {useNavigate} from 'react-router-dom';
import {Icons} from '../../../../constants/Icons';
import {useDispatch, useSelector} from 'react-redux';
import {logOut} from '../../../../store/reducers/AuthReducer';
import {deleteNotificationToken} from '../../../../firebase';
import {RootState} from '../../../../store/reducers/RootReducer';
import PFChangesModal from '../ChangesModal/PFChangesModal';
import {CampaignModals} from '../../../../constants/CampaignModals';
import {changeUnsavedChanges} from '../../../../store/actions/CampaignActions';

interface SidebarProps {
	activePage: string;
}

const Sidebar: React.FC<SidebarProps> = ({activePage}) => {
	const nav = useNavigate();
	const dispatch = useDispatch<any>();
	const [showModal, setShowModal] = useState<boolean>(false);
	const hasUnsavedChanges: boolean = useSelector(
		(state: RootState) => state.campaign.hasUnsavedChanges,
	);
	const [to, setTo] = useState('');

	const handleNavigate = (name: string) => {
		if (!hasUnsavedChanges) {
			nav(`/${name}`);
		} else {
			setShowModal(true);
			setTo(name);
		}
	};

	const notificationToken: string | null = useSelector(
		(state: RootState) => state.notification.token,
	);

	const tokenIsFound: boolean = useSelector(
		(state: RootState) => state.notification.isFound,
	);

	const handleClickLogOut = async () => {
		await dispatch(logOut());
		if (notificationToken !== null && tokenIsFound) {
			await dispatch(deleteNotificationToken(notificationToken));
		}
		localStorage.removeItem('token');
		sessionStorage.removeItem('token');
		nav('/login');
	};

	const unreadNotification: boolean = useSelector(
		(state: RootState) => state.notification.hasUnreadNotifications,
	);

	const handleLeave = () => {
		setShowModal(false);
		dispatch(changeUnsavedChanges(false));
		if (to !== '') {
			nav(`/${to}`);
			setTo('');
		}
	};

	const handleCancel = () => {
		setShowModal(false);
	};
	return (
		<div className={styles.sidebarWrapper}>
			<div className={styles.sidebar}>
				<div className={styles.logoWrapper}>
					<a href="/campaigns" title="Homepage">
						<img
							src={Images.FourDots}
							alt="logo"
							className={`${styles.logo} ${styles.image}`}
						/>
					</a>
				</div>
				<ul className={styles.itemsWrapper}>
					<li className={styles.item}>
						<PFIconButton
							title="Campaigns"
							onClick={() => handleNavigate('campaigns')}
							clicked={activePage === 'campaigns' ? true : false}
							type="submit"
							name="campaigns"
							activeIcon={Icons.LIGHT_ROCKET}
							inactiveIcon={Icons.DARK_ROCKET}
							style={styles.button}
						/>
					</li>
					<li className={styles.item}>
						<PFIconButton
							title="Users"
							onClick={() => handleNavigate('users')}
							clicked={activePage === 'users' ? true : false}
							type="submit"
							name="users"
							activeIcon={Icons.LIGHT_PROFILE}
							inactiveIcon={Icons.DARK_PROFILE}
							style={styles.button}
						/>
					</li>
					<li className={styles.item}>
						<PFIconButton
							title="Notifications"
							onClick={() => handleNavigate('notifications')}
							clicked={activePage === 'notifications' ? true : false}
							type="submit"
							name="notifications"
							activeIcon={
								unreadNotification
									? Icons.WHITE_BELL_NOTIFICATION
									: Icons.WHITE_BELL
							}
							inactiveIcon={
								unreadNotification
									? Icons.BLACK_BELL_NOTIFICATION
									: Icons.BLACK_BELL
							}
							style={styles.button}
						/>
					</li>
				</ul>
				<div className={styles.logOutWrapper}>
					<PFIconButton
						title="Log out"
						onClick={handleClickLogOut}
						clicked={false}
						type="submit"
						name="logout"
						activeIcon={Icons.LOGOUT}
						inactiveIcon={Icons.LOGOUT}
					/>
				</div>
			</div>
			<PFChangesModal
				show={showModal}
				onHide={() => setShowModal(false)}
				label={CampaignModals.CHANGES}
				onLeave={handleLeave}
				onCancel={handleCancel}
			/>
		</div>
	);
};

export default Sidebar;
