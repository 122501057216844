// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WarningLayer_bg__\\+jbnc {\n  background-color: rgba(0, 0, 0, 0.7);\n  position: fixed;\n  top: 0px;\n  bottom: 0px;\n  left: 0px;\n  right: 0px;\n  display: flex;\n  height: 100vh;\n  width: 100%;\n  z-index: 3000;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.WarningLayer_box__PbnPL {\n  background-color: white;\n  padding-right: 3%;\n  padding-left: 3%;\n  padding-top: 3%;\n  padding-bottom: 3%;\n}\n\n.WarningLayer_text__UEw42 {\n  color: white;\n  align-self: center;\n  text-align: center;\n  font-family: \"Bulldog\";\n  font-size: 34px;\n  padding-left: 20%;\n  padding-right: 20%;\n  margin-top: 5%;\n}", "",{"version":3,"sources":["webpack://./src/components/pages/warningLayer/WarningLayer.module.scss"],"names":[],"mappings":"AAAA;EACC,oCAAA;EACA,eAAA;EACA,QAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;EACA,aAAA;EACA,aAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AACD;;AACA;EACC,uBAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;AAED;;AACA;EACC,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;AAED","sourcesContent":[".bg {\n\tbackground-color: rgba(0, 0, 0, 0.7);\n\tposition: fixed;\n\ttop: 0px;\n\tbottom: 0px;\n\tleft: 0px;\n\tright: 0px;\n\tdisplay: flex;\n\theight: 100vh;\n\twidth: 100%;\n\tz-index: 3000;\n\tjustify-content: center;\n\talign-items: center;\n\tflex-direction: column;\n}\n.box {\n\tbackground-color: white;\n\tpadding-right: 3%;\n\tpadding-left: 3%;\n\tpadding-top: 3%;\n\tpadding-bottom: 3%;\n}\n\n.text {\n\tcolor: white;\n\talign-self: center;\n\ttext-align: center;\n\tfont-family: 'Bulldog';\n\tfont-size: 34px;\n\tpadding-left: 20%;\n\tpadding-right: 20%;\n\tmargin-top: 5%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bg": "WarningLayer_bg__+jbnc",
	"box": "WarningLayer_box__PbnPL",
	"text": "WarningLayer_text__UEw42"
};
export default ___CSS_LOADER_EXPORT___;
