export const TargetGoals: Array<string> = [
    'Views',
    'VTR',
    'Clicks',
    'Traffic',
    'Sales',
  ];
  export const OtherMetrics: Array<string> = [
    'Positive Comments',
    'App Downloads',
    'Shares',
    'Sign-Ups',
    'Requests',
    'Likes',
  ];
  