export const TargetGoals = [
	{value: 1, label: 'Views'},
	{value: 2, label: 'VTR'},
	{value: 3, label: 'Clicks'},
	{value: 4, label: 'Traffic'},
	{value: 5, label: 'Positive Comments'},
	{value: 6, label: 'App Downloads'},
	{value: 7, label: 'Sales'},
	{value: 8, label: 'Shares'},
	{value: 9, label: 'Sign-Ups'},
	{value: 10, label: 'Requests'},
	{value: 11, label: 'Likes'},
];
