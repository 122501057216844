import React from 'react';
import styles from './PFBrandSelectMenu.module.scss';
import Select from 'react-select';
import {SelectBrand} from '../../../../models/SelectBrand';

interface PFBrandSelectMenuProps {
	onChange: (retVal: number) => void;
	list: Array<SelectBrand>;
	selected?: number;
	isDisabled?: boolean;
}

export const PFBrandSelectMenu: React.FC<PFBrandSelectMenuProps> = ({
	onChange,
	list,
	selected,
	isDisabled = false,
}) => {
	const Options = list.map((b: SelectBrand) => {
		return {value: b.id, label: b.Name};
	});
	const customStyles = {
		control: (provided: any) => ({
			...provided,
			border: '1px solid rgba(0, 0, 0, 0.08)',
			'&:hover': {
				cursor: 'pointer',
			},
		}),
		indicatorSeparator: (provided: any) => ({
			...provided,
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
		}),
		dropdownIndicator: (provided: any) => ({
			...provided,
			color: 'rgba(0, 0, 0, 0.6)',
		}),
	};
	return (
		<Select
			isDisabled={isDisabled}
			styles={customStyles}
			placeholder={<div className={styles.placeholder}>Select brand...</div>}
			className={styles.select}
			options={Options}
			value={Options.filter(x => x.value === selected)}
			onChange={e => onChange(e!.value)}
		/>
	);
};

export default PFBrandSelectMenu;
