import 'bootstrap/dist/css/bootstrap.css';
import {Field, Formik} from 'formik';
import moment from 'moment';
import React, {useState} from 'react';
import {CampaignModals} from '../../../../constants/CampaignModals';
import {LiveDate} from '../../../../models/LiveDate';
import {AddLiveDateValidationScheme} from '../../../../validation/AddLiveDateValidationScheme';
import PFDateTypeMenu from '../../atoms/DateTypeMenu/PFDateTypeMenu';
import {PFFormikTextInput} from '../../atoms/FormikTextInput/PFFormikTextInput';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import PFModal from '../../organisms/Modal/PFModal';
import styles from './PFAddDateModal.module.scss';

interface PFAddDateModalProps {
	onClick: (date: LiveDate) => void;
	onHide: () => void;
	show: boolean;
	label?: string;
}
export const PFAddDateModal: React.FC<PFAddDateModalProps> = ({
	onClick,
	onHide,
	show = false,
	label,
}) => {
	const [from, setFrom] = useState<string>('');
	const [fromDate, setFromDate] = useState<Date>(new Date());
	const [to, setTo] = useState<string>('');
	const [type, setType] = useState<number>(0);

	const setDateFrom = (e: any) => {
		setFromDate(new Date(e.target.value));
		setFrom(moment(e.target.value).format('DD MMM YYYY'));
	};

	const setDateTo = (e: any) => {
		setTo(moment(e.target.value).format('DD MMM YYYY'));
	};

	const getMinDate = () => {
		return fromDate.toISOString().split('T')[0];
	};

	const createInitalValues = () => {
		return {name: '', dateFrom: '', dateTo: '', id: 0, type: 0};
	};

	const setValues = (name: string, id: number) => {
		return {
			name: name,
			dateFrom: from,
			dateTo: to,
			id: id,
			type: type,
		};
	};

	return (
		<PFModal show={show} onHide={onHide} label={CampaignModals.LIVE_DATE}>
			<div>
				<Formik
					initialValues={createInitalValues()}
					validationSchema={AddLiveDateValidationScheme}
					onSubmit={(values: LiveDate) => {
						onClick(setValues(values.name, values.id));
					}}>
					{formik => (
						<>
							<div className={styles.divFlex}>
								<div className={styles.firstRow}>
									<div className={styles.leftFirst}>
										<Field
											label="Event Name"
											component={PFFormikTextInput}
											placeholder="Enter Event Name"
											name="name"
											type="text"
											value=""
											style={styles.bull2}
											styleForInput={styles.inp}
										/>
									</div>
									<div className={styles.rightFirst}>
										<div className={styles.bull}>Date Type</div>
										<div>
											<PFDateTypeMenu onChange={setType} />
										</div>
									</div>
								</div>
								<div className={styles.firstRow}>
									<div className={styles.leftSecond}>
										<div className={styles.bull}>From</div>
										<div>
											<input
												style={{paddingRight: 10}}
												type="date"
												onKeyDown={(e) => e.preventDefault()} 
												onChange={e => setDateFrom(e)}
											/>
										</div>
									</div>
									<div className={styles.rightSecond}>
										<div className={styles.bull}>To</div>
										<div>
											<input
												type="date"
												onKeyDown={(e) => e.preventDefault()} 
												style={{paddingRight: 10}}
												min={getMinDate()}
												onChange={e => setDateTo(e)}></input>
										</div>
									</div>
								</div>
								<PFPrimaryButton
									onClick={() => formik.handleSubmit()}
									text={'ADD CAMPAIGN DATE'}
									type="submit"
									style={styles.btn}></PFPrimaryButton>
							</div>
						</>
					)}
				</Formik>
			</div>
		</PFModal>
	);
};

export default PFAddDateModal;
