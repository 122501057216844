import React, {useEffect, useState} from 'react';

import {Icons} from '../../../../constants/Icons';
import CampaignServices from '../../../../services/CampaignServices';
import styles from './PFGoalCard.module.scss';

interface PFGoalCardProps {
	targetValue: string;
	onClick?: (name: string | null) => void;
	name: string;
	cost: number | null;
	currentValue?: number;
	onChange?: (value: number, name: string) => void;
	isPerformance?: boolean;
	editable?: boolean;
}
export const PFGoalCard: React.FC<PFGoalCardProps> = ({
	onClick,
	targetValue,
	name,
	cost,
	currentValue,
	onChange,
	editable = false,
	isPerformance = false,
}) => {
	const [value, setValue] = useState(0);

	useEffect(() => {
		setValue(currentValue ? currentValue : 0);
	}, [currentValue]);

	const handleChange = (value: number) => {
		setValue(value);
		onChange && onChange(value, name);
	};
	const textColorStyle = () => {
		if (isPerformance && currentValue) {
			if (currentValue > Number(targetValue)) {
				return styles.green;
			}
			return styles.red;
		}
		return '';
	};

	const convertToDecimalCommas = (num: number | undefined | string) => {
		if (typeof num === 'string') {
			num = parseFloat(num);
		}
		if (num === 0) {
			return '0';
		}
		if (Number.isInteger(num)) {
			return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		} else {
			return num
				?.toFixed(2)
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
		}
	};

	return (
		<div
			className={`${styles.cardWrapper} ${
				isPerformance && styles.performanceHeight
			}`}>
			<div className={`${styles.card}`}>
				<div className={styles.row}>
					<div className={styles.icon}>{Icons.REACH}</div>
					<div className={styles.name}>{name}</div>
					{editable && (
						<div
							className={styles.bin}
							title="Delete target goal"
							onClick={() => {
								onClick && onClick(name);
							}}>
							{Icons.X}
						</div>
					)}
				</div>
				<div className={`${styles.value} ${textColorStyle()}`}>
					{!CampaignServices.checkName(name) ? (
						<input
							type="number"
							className={styles.input}
							value={value}
							onChange={e => handleChange(Number(e.target.value))}></input>
					) : isPerformance && currentValue ? (
						currentValue
					) : (
						convertToDecimalCommas(targetValue)
					)}
					{isPerformance && (
						<div className={styles.targetBox}>
							<div className={styles.targetAndCost}>TARGET: {targetValue} </div>
							<div className={styles.targetAndCost}>COST: ${cost}</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default PFGoalCard;
