import axios from 'axios';
import {CreatorBrief} from '../models/CreatorBrief';
import {CreatorBriefResponse} from '../models/CreatorBriefResponse';
import '../interceptors/axiosInterceptor';

interface CreatorBriefService {
	getCreatorBrief(campaignId: string): Promise<CreatorBriefResponse>;
	updateCreatorBrief(
		campaignId: string,
		data: CreatorBrief,
	): Promise<CreatorBriefResponse>;
	updateCreatorBriefFile(campaignId: string, data: FormData): Promise<any>;
}

const ENDPOINTS = {
	CREATOR_BRIEF: `${process.env.REACT_APP_BASE_URL}/api/Campaign/creator-brief/`,
	EDIT_CREATOR_BRIEF: `${process.env.REACT_APP_BASE_URL}/api/Campaign/creator-brief/`,
	CREATOR_BRIEF_FILE: `${process.env.REACT_APP_BASE_URL}/api/Campaign/creator-brief-file/`,
};

class CreatorBriefAxiosService implements CreatorBriefService {
	async getCreatorBrief(id: string): Promise<CreatorBriefResponse> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(ENDPOINTS.CREATOR_BRIEF + id, {
				headers: {
					Authorization: 'Bearer ' + Token,
				},
			});
			return response.data;
		} catch (error: any) {
			if (error.response.data.StatusCode !== 404) {
				return Promise.reject(error);
			}
			return {
				creatorBrief: {
					shootTime: '',
					summary: '',
					assets: [],
					payment: '',
					platform: '',
					objective: '',
					usageTime: [],
					campaignId: -1,
					creativeFramework: '',
				},
				creatorBriefUrl: '',
			};
		}
	}
	async updateCreatorBrief(
		campaignId: string,
		data: CreatorBrief,
	): Promise<CreatorBriefResponse> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.put(
				ENDPOINTS.EDIT_CREATOR_BRIEF + campaignId,
				data,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}
	async updateCreatorBriefFile(
		campaignId: string,
		data: FormData,
	): Promise<any> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			data.append('CampaignId', campaignId);
			const response = await axios.put(
				ENDPOINTS.CREATOR_BRIEF_FILE + campaignId,
				data,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}
}

export default new CreatorBriefAxiosService();
