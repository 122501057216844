import {Link} from 'react-router-dom';
import {Icons} from '../../../../constants/Icons';
import styles from './NotificationCard.module.scss';
import {renderDate} from '../../../../helper/helper';

interface Props {
	title: string;
	body: string;
	id: number;
	handleWheelClick: (id: number) => void;
	isRead: boolean;
	notificationType: number;
	entityId: number;
	created: string;
}

const icons = {
	0: Icons.CAMPAIGN_ICON,
	1: Icons.BRIEF_ICON,
	2: Icons.PERFORMANCE_ICON,
	3: Icons.PERFORMANCE_ICON,
	4: Icons.DARK_PROFILE,
};

function PFNotificationCard(props: Props) {
	const {
		title,
		body,
		id,
		isRead,
		notificationType,
		entityId,
		created,
		handleWheelClick,
	} = props;

	const handleClick = (): string => {
		switch (notificationType) {
			case 0:
				return '/sow/' + entityId;
			case 1:
				return '/sow/' + entityId;
			case 2:
				return '/performance/' + entityId;
			case 3:
				return '/creator-list/' + entityId;
			case 4: {
				if (title.includes('New Client request')) {
					return '/users/client';
				}
				return '/users';
			}
			default:
				return '';
		}
	};

	const wheelClick = (event: any) => {
		if (event.button === 1) {
			handleWheelClick(id);
		}
	};

	return (
		<Link to={handleClick()} onMouseDown={wheelClick}>
			<div className={`${styles.card} ${!isRead && styles.yellowBg}`}>
				<div className={styles.title}>
					<span className={styles.icon}>
						{icons[notificationType as keyof typeof icons]}
					</span>
					<h4>{title}</h4>
				</div>
				<div className={styles.body}>
					<p>{body}</p>
				</div>
				<div className={styles.time}>{renderDate(created + 'Z')}</div>
			</div>
		</Link>
	);
}

export default PFNotificationCard;
