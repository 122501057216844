import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AuthModel} from '../../models/AuthModel';

interface AuthState {
	email: string;
	id: number;
	isApproved: boolean;
	isOnboarded: boolean;
	isLoading: boolean;
	isLoggedIn: boolean;
	rememberMe: boolean;
}

const initialState: AuthState = {
	email: '',
	id: 0,
	isApproved: false,
	isOnboarded: false,
	isLoading: false,
	isLoggedIn: false,
	rememberMe: false,
};

const authSlice = createSlice({
	initialState,
	name: 'authReducer',
	reducers: {
		setLoggedIn(
			state,
			{payload}: PayloadAction<{auth: AuthModel; rememberMe: boolean}>,
		) {
			state.email = payload.auth.email || '';
			state.id = payload.auth.id || -1;
			state.isApproved = payload.auth.isApproved || false;
			state.isOnboarded = payload.auth.isOnboarded || false;
			state.isLoading = false;
			state.isLoggedIn = true;
			state.rememberMe = payload.rememberMe;
		},
		setLoading(state, {payload}: PayloadAction<boolean>) {
			state.isLoading = payload;
		},
		logOut(state) {
			state.email = '';
			state.id = 0;
			state.isApproved = false;
			state.isOnboarded = false;
			state.isLoading = false;
			state.isLoggedIn = false;
			state.rememberMe = false;
		},
	},
});

export const {setLoggedIn, setLoading, logOut} = authSlice.actions;

export default authSlice.reducer;
