// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PFCurrencyMenu_option__ImSW4 {\n  font-family: \"Bulldog\";\n  font-weight: 400;\n  font-size: 15px;\n  background-color: rgb(255, 255, 255);\n}\n.PFCurrencyMenu_option__ImSW4:checked {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgb(226, 226, 226);\n}\n\n.PFCurrencyMenu_box__On5LG {\n  background-color: rgb(255, 255, 255);\n  border: 1px solid rgba(0, 0, 0, 0.08);\n  width: 70px;\n  height: 36px;\n  border-radius: 4px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-left: 5px;\n  font-family: \"Bulldog\";\n  font-weight: 400;\n  font-size: 15px;\n}\n\n.PFCurrencyMenu_select__zUzKT {\n  width: 72px;\n  margin-top: -1px;\n  margin-right: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/atoms/CurrencyMenu/PFCurrencyMenu.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,gBAAA;EACA,eAAA;EACA,oCAAA;AACD;AAAC;EACC,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,oCAAA;AAEF;;AAEA;EACC,oCAAA;EACA,qCAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,sBAAA;EACA,gBAAA;EACA,eAAA;AACD;;AAEA;EACC,WAAA;EACA,gBAAA;EACA,kBAAA;AACD","sourcesContent":[".option {\n\tfont-family: 'Bulldog';\n\tfont-weight: 400;\n\tfont-size: 15px;\n\tbackground-color: rgba(255, 255, 255, 1);\n\t&:checked {\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tbackground-color: rgb(226, 226, 226);\n\t}\n}\n\n.box {\n\tbackground-color: rgba(255, 255, 255, 1);\n\tborder: 1px solid rgba(0, 0, 0, 0.08);\n\twidth: 70px;\n\theight: 36px;\n\tborder-radius: 4px;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tpadding-left: 5px;\n\tfont-family: 'Bulldog';\n\tfont-weight: 400;\n\tfont-size: 15px;\n}\n\n.select {\n\twidth: 72px;\n\tmargin-top: -1px;\n\tmargin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"option": "PFCurrencyMenu_option__ImSW4",
	"box": "PFCurrencyMenu_box__On5LG",
	"select": "PFCurrencyMenu_select__zUzKT"
};
export default ___CSS_LOADER_EXPORT___;
