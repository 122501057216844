import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {CampaignTabs} from '../../../../constants/CampaignTabs';
import {Icons} from '../../../../constants/Icons';
import {getBrands} from '../../../../store/actions/UserActions';
import {RootState} from '../../../../store/reducers/RootReducer';
import PFSingleCampaignTabs from '../../molecules/SingleCampaignTabs/PFSingleCampaignTabs';
import styles from './PFCampaignHeader.module.scss';
import Select from 'react-select';
import {Option} from 'react-multi-select-component';
import {getCampaignStatus} from '../../../../helper/helper';
import {
	changeCampaignStatus,
	changeUnsavedChanges,
} from '../../../../store/actions/CampaignActions';
import PFChangesModal from '../../molecules/ChangesModal/PFChangesModal';
import {CampaignModals} from '../../../../constants/CampaignModals';

interface PFCampaignHeaderProps {
	onClick?: (retVal: string) => void;
	handleSearch?: (value: string) => void;
	label: string;
	currentTab?: string;
}

export const campaignStatuses = [
	{value: 0, label: 'Pending'},
	{value: 1, label: 'Approved'},
	{value: 2, label: 'Declined'},
	{value: 3, label: 'Active'},
	{value: 4, label: 'Ended'},
];

const PFCampaignHeader: React.FC<PFCampaignHeaderProps> = ({
	label,
	currentTab = CampaignTabs.SOW,
}) => {
	const nav = useNavigate();
	const [select, setSelect] = useState(false);
	const [showModal, setShowModal] = useState<boolean>(false);

	const currCampaign = useSelector(
		(state: RootState) => state.campaign.currentCampaign,
	);

	const {hasUnsavedChanges} = useSelector((state: RootState) => state.campaign);

	const [currStatus, setStatus] = useState(
		getCampaignStatus(currCampaign.campaignStatus),
	);

	const handleOnClick = () => {
		if (hasUnsavedChanges === true) {
			setShowModal(true);
		} else {
			nav('/campaigns');
		}
	};
	const dispatch = useDispatch<any>();

	useEffect(() => {
		dispatch(getBrands());
		setStatus(getCampaignStatus(currCampaign.campaignStatus));
	}, [currCampaign.campaignStatus, dispatch]);

	const clients = useSelector((state: RootState) => state.user.allBrands);
	const client = clients.find(c => c.id === currCampaign.clientId);

	const getLabel = useMemo(() => {
		if (label !== 'Create Campaign') {
			let name = client?.Name ? client.Name + ' : ' + currCampaign?.name : '';
			return name;
		}
		return 'Create Campaign';
	}, [client?.Name, currCampaign?.name, label]);

	const handleOnSelectChange = (e: any) => {
		setSelect(false);
		dispatch(changeCampaignStatus(currCampaign.id ?? -1, e.value));
		setStatus(getCampaignStatus(currCampaign.campaignStatus));
	};

	const getOptions = () => {
		let options: Array<Option> = [];
		campaignStatuses.forEach(c => {
			options.push(c);
		});
		return options;
	};

	const handleLeave = () => {
		setShowModal(false);
		dispatch(changeUnsavedChanges(false));
		nav('/campaigns');
	};

	const handleCancel = () => {
		setShowModal(false);
	};

	return (
		<>
			<div className={styles.div}>
				<div className={styles.headerWrapper}>
					<div className={`${styles.header}`}>
						<div className={`${styles.icon}`} onClick={handleOnClick}>
							{Icons.BACK}
						</div>
						<div className={`${styles.label}`}>{getLabel}</div>
						<div>
							{label !== 'Create Campaign' && (
								<PFSingleCampaignTabs
									onClick={() => {}}
									currentTab={currentTab}
								/>
							)}
						</div>
					</div>
					<div className={styles.status}>
						{!select ? (
							<div className={styles.statusText}>
								<div
									title={currStatus}
									style={
										currStatus === 'Declined' || currStatus === 'Ended'
											? stylesObject.red
											: currStatus === 'Pending'
											? stylesObject.orange
											: currStatus === 'Active'
											? stylesObject.green
											: stylesObject.lightGreen
									}>
									<span className={styles.statusTxt}>{currStatus}</span>
								</div>

								<span
									onClick={() => setSelect(true)}
									title={currStatus}
									className={styles.edit}>
									{Icons.EDIT}
								</span>
							</div>
						) : (
							<Select
								onBlur={() => setSelect(false)}
								onChange={handleOnSelectChange}
								options={getOptions()}
								className={styles.select}
								value={{value: currStatus, label: currStatus}}
							/>
						)}
					</div>
				</div>
			</div>
			<PFChangesModal
				show={showModal}
				onHide={() => setShowModal(false)}
				label={CampaignModals.CHANGES}
				onLeave={handleLeave}
				onCancel={handleCancel}
			/>
		</>
	);
};

const stylesObject = {
	red: {
		backgroundColor: 'rgba(234, 67, 53, 0.8)',
		width: 'auto',
		minWidth: 40,
		borderRadius: 4,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		paddingTop: 5,
		paddingBottom: 5,
	},
	orange: {
		backgroundColor: 'rgba(251, 139, 2, 0.8)',
		width: 'auto',
		minWidth: 40,
		borderRadius: 4,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		paddingTop: 5,
		paddingBottom: 5,
	},
	green: {
		backgroundColor: 'rgba(104, 204, 88, 0.8)',
		width: 'auto',
		minWidth: 40,
		borderRadius: 4,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		paddingTop: 5,
		paddingBottom: 5,
	},
	lightGreen: {
		backgroundColor: 'rgba(166, 224, 158, 1)',
		width: 'auto',
		minWidth: 40,
		borderRadius: 4,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		paddingTop: 5,
		paddingBottom: 5,
	},
	pending: {
		paddingRight: '15px',
	},
};

export default PFCampaignHeader;
