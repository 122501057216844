import React from 'react';
import styles from './CreateCampaignPage.module.scss';
import 'bootstrap/dist/css/bootstrap.css';
import Sidebar from '../../UI/molecules/sidebar/Sidebar';
import PFCampaignHeader from '../../UI/organisms/CampaignHeader/PFCampaignHeader';
import PFSoW from '../../UI/organisms/SoW/PFSoW';

const countMin = (val1: number, val2: number) => {
	if (val1 <= val2) return val1;
	return val2;
};

const CreateCampaignPage: React.FC = () => {
	return (
		<div className={styles.page}>
			<div className={styles.sidebarWrapper}>
				<Sidebar activePage="campaigns" />
			</div>
			<div className={styles.contentWrapper}>
				<div className={styles.headerWrapper}>
					<PFCampaignHeader
						onClick={() => {}}
						handleSearch={() => {}}
						label="Create Campaign"
					/>
				</div>
				<div className={styles.FaTWrapper}>
					<PFSoW text={'create'} />
				</div>
			</div>
		</div>
	);
};

const styleCards = {
	display: 'flex',
	flexWrap: 'wrap' as 'wrap',
};

export default CreateCampaignPage;
