import React from 'react';
import {Icons} from '../../../../constants/Icons';
import styles from './PFDateCard.module.scss';

interface PFDateCardProps {
	text: string;
	startDate: string;
	endDate: string;
	onClick: () => void;
	style?: {};
	editable?: boolean;
}
export const PFDateCard: React.FC<PFDateCardProps> = ({
	onClick,
	text,
	startDate,
	endDate,
	style,
	editable = true,
}) => {
	return (
		<div className={styles.split}>
			<div className={styles.card}>
				<div className={`${styles.icon} ${style}`}>{Icons.DATE}</div>
				<div className={`${styles.text}`}>{text}</div>
			</div>
			<div className={styles.row}>
				<div className={`${styles.dates}`}>
					{startDate} {' - '} {endDate}
				</div>
				{editable && (
					<div
						className={`${styles.settings} ${style}`}
						title="Edit live date"
						onClick={onClick}>
						{Icons.SETTINGS}
					</div>
				)}
			</div>
		</div>
	);
};

export default PFDateCard;
