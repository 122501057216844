import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {CampaignTabs} from '../../../constants/CampaignTabs';
import {CampaignBrief} from '../../../models/CampaignBrief';
import {
	getCampaign,
	getCampaignMetaId,
} from '../../../store/actions/CampaignActions';
import {getPerformanceCreatorList} from '../../../store/actions/CreatorListActions';
import {RootState} from '../../../store/reducers/RootReducer';
import Sidebar from '../../UI/molecules/sidebar/Sidebar';
import PFCampaignHeader from '../../UI/organisms/CampaignHeader/PFCampaignHeader';
import {PFPerformance} from '../../UI/organisms/Performance/PFPerformance';
import styles from './PerformancePage.module.scss';

const PerformancePage = () => {
	const dispatch = useDispatch<any>();
	const id = useParams();

	useEffect(() => {
		if (id.id) {
			dispatch(getPerformanceCreatorList(id.id));
			dispatch(getCampaign(id.id));
			dispatch(getCampaignMetaId(id.id));
		}
	}, [dispatch, id.id]);

	const campaign: CampaignBrief = useSelector(
		(state: RootState) => state.campaign.currentCampaign,
	);

	return (
		<>
			{campaign !== undefined && id.id && (
				<div className={styles.page}>
					<div className={styles.sidebarWrapper}>
						<Sidebar activePage="campaigns" />
					</div>
					<div className={styles.contentWrapper}>
						<div className={styles.headerWrapper}>
							<PFCampaignHeader
								onClick={() => {}}
								handleSearch={() => {}}
								label="Performance"
								currentTab={CampaignTabs.PERFORMANCE}
							/>
						</div>
						<PFPerformance campaignId={id.id} />
					</div>
				</div>
			)}
		</>
	);
};

export default PerformancePage;
