import {combineReducers} from '@reduxjs/toolkit';
import authSlice from './AuthReducer';
import userSlice from './UserReducer';
import campaignSlice from './CampaignReducer';
import creatorBriefSlice from './CreatorBriefReducer';
import creatorListSlice from './CreatorListReducer';
import contentPlanSlice from './ContentPlanReducer';
import notificationSlice from './NotificationReducer';

const rootReducer = combineReducers({
	auth: authSlice,
	user: userSlice,
	campaign: campaignSlice,
	creatorBrief: creatorBriefSlice,
	creatorList: creatorListSlice,
	contentPlan: contentPlanSlice,
	notification: notificationSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
