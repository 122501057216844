import 'bootstrap/dist/css/bootstrap.css';
import {Field, FieldArray, Formik} from 'formik';
import React, {useState} from 'react';
import Select from 'react-select';
import {AssetTypes} from '../../../../constants/AssetTypes';
import {CampaignModals} from '../../../../constants/CampaignModals';
import {CreatorBriefAsset} from '../../../../models/CreatorBriefAsset';
import {creatorBriefAssetValidationScheme} from '../../../../validation/CreatorBriefAssetModalValidationScheme';
import {PFFormikTextInput} from '../../atoms/FormikTextInput/PFFormikTextInput';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import PFModal from '../../organisms/Modal/PFModal';
import styles from './PFCreatorBriefAssetModal.module.scss';

interface PFCreatorBriefAssetModalProps {
	onClick: (asset: CreatorBriefAsset) => void;
	onHide: () => void;
	show: boolean;
	label?: string;
	size?: 'sm' | 'lg';
}
export const PFCreatorBriefAssetModal: React.FC<
	PFCreatorBriefAssetModalProps
> = ({onClick, onHide, show = false, label, size = 'sm'}) => {
	const [type, setType] = useState(1);

	const initialVals: CreatorBriefAsset = {
		type: type,
		length: [],
		format: '',
		quantity: 0,
	};

	return (
		<PFModal
			size={size}
			show={show}
			onHide={onHide}
			label={CampaignModals.CREATOR_BRIEF_ASSET}>
			<div>
				<Formik
					initialValues={initialVals}
					validationSchema={creatorBriefAssetValidationScheme}
					onSubmit={(values: CreatorBriefAsset) => {
						onClick({
							type: type,
							length: values.length,
							format: values.format,
							quantity: values.quantity,
						});
					}}>
					{formik => (
						<>
							<div className={styles.col}>
								<div className={styles.brand}>Choose Asset Type</div>
								<Select
									className={styles.select}
									options={AssetTypes}
									onChange={e => setType(e!.value)}
								/>
								<div>
									<Field
										label="Quantity"
										component={PFFormikTextInput}
										placeholder="Enter Value"
										name="quantity"
										number
										style={styles.brand}
										styleForInput={styles.inputName}
										value={''}
									/>
								</div>
								<div>
									<Field
										label="Format"
										component={PFFormikTextInput}
										placeholder="Enter format"
										name="format"
										style={styles.brand}
										styleForInput={styles.inputName}
										value={''}
									/>
								</div>
								{formik.values.quantity > 0 && type === 0 && (
									<FieldArray
										name={'length'}
										render={() =>
											Array(formik.values.quantity)
												.fill(true)
												.map((_, i) => (
													<Field
														key={i}
														label={`Video ${i + 1} length (')`}
														component={PFFormikTextInput}
														placeholder="Enter video length in minutes"
														name={`length[${i}]`}
														number
														style={styles.brand}
														styleForInput={styles.inputName}
														value={''}
													/>
												))
										}
									/>
								)}
								<div>
									<PFPrimaryButton
										onClick={() => formik.handleSubmit()}
										text={'ADD ASSET'}
										type="submit"
										style={styles.btn}></PFPrimaryButton>
								</div>
							</div>
						</>
					)}
				</Formik>
			</div>
		</PFModal>
	);
};

export default PFCreatorBriefAssetModal;
