export const Countries = [
	{value: 0, label: 'Albania'},
	{value: 1, label: 'Argentina'},
	{value: 2, label: 'Australia'},
	{value: 3, label: 'Austria'},
	{value: 4, label: 'Bangladesh'},
	{value: 5, label: 'Belgium'},
	{value: 6, label: 'Bosnia'},
	{value: 7, label: 'Brazil'},
	{value: 8, label: 'Bulgaria'},
	{value: 9, label: 'Canada'},
	{value: 10, label: 'Chile'},
	{value: 11, label: 'China'},
	{value: 12, label: 'Colombia'},
	{value: 13, label: 'Comoros'},
	{value: 14, label: 'Costa Rica'},
	{value: 15, label: 'Croatia'},
	{value: 16, label: 'Cuba'},
	{value: 17, label: 'Cyprus'},
	{value: 18, label: 'Czech Republic'},
	{value: 19, label: 'Denmark'},
	{value: 20, label: 'Egypt'},
	{value: 21, label: 'Finland'},
	{value: 22, label: 'France'},
	{value: 23, label: 'Georgia'},
	{value: 24, label: 'Germany'},
	{value: 25, label: 'Gibraltar'},
	{value: 26, label: 'Greece'},
	{value: 27, label: 'Hong Kong'},
	{value: 28, label: 'Hungary'},
	{value: 29, label: 'Iceland'},
	{value: 30, label: 'India'},
	{value: 31, label: 'Indonesia'},
	{value: 32, label: 'Iran'},
	{value: 33, label: 'Iraq'},
	{value: 34, label: 'Ireland'},
	{value: 35, label: 'Israel'},
	{value: 36, label: 'Italy'},
	{value: 37, label: 'Ivory Coast'},
	{value: 38, label: 'Jamaica'},
	{value: 39, label: 'Japan'},
	{value: 40, label: 'Kazakhstan'},
	{value: 41, label: 'Kenya'},
	{value: 42, label: 'Kosovo'},
	{value: 43, label: 'Kuwait'},
	{value: 44, label: 'Lebanon'},
	{value: 45, label: 'Liechtenstein'},
	{value: 46, label: 'Lithuania'},
	{value: 47, label: 'Luxembourg'},
	{value: 48, label: 'Macedonia'},
	{value: 49, label: 'Madagascar'},
	{value: 50, label: 'Malaysia'},
	{value: 51, label: 'Mali'},
	{value: 52, label: 'Malta'},
	{value: 53, label: 'Mexico'},
	{value: 54, label: 'Moldova'},
	{value: 55, label: 'Monaco'},
	{value: 56, label: 'Montenegro'},
	{value: 57, label: 'Morocco'},
	{value: 58, label: 'Netherlands'},
	{value: 59, label: 'New Zealand'},
	{value: 60, label: 'Nigeria'},
	{value: 61, label: 'Norway'},
	{value: 62, label: 'Pakistan'},
	{value: 63, label: 'Palestine'},
	{value: 64, label: 'Peru'},
	{value: 65, label: 'Philippines'},
	{value: 66, label: 'Poland'},
	{value: 67, label: 'Portugal'},
	{value: 68, label: 'Qatar'},
	{value: 69, label: 'Romania'},
	{value: 70, label: 'Russia'},
	{value: 71, label: 'Saudi Arabia'},
	{value: 72, label: 'Serbia'},
	{value: 73, label: 'Singapore'},
	{value: 74, label: 'Slovakia'},
	{value: 75, label: 'Slovenia'},
	{value: 76, label: 'South Africa'},
	{value: 77, label: 'South Korea'},
	{value: 78, label: 'Spain'},
	{value: 79, label: 'Sri Lanka'},
	{value: 80, label: 'Sweden'},
	{value: 81, label: 'Switzerland'},
	{value: 82, label: 'Taiwan'},
	{value: 83, label: 'Thailand'},
	{value: 84, label: 'Trinidad'},
	{value: 85, label: 'Tunisia'},
	{value: 86, label: 'Turkey'},
	{value: 87, label: 'Ukraine'},
	{value: 88, label: 'UAE'},
	{value: 89, label: 'United Kingdom'},
	{value: 90, label: 'United States'},
	{value: 91, label: 'Vietnam'},
	{value: 92, label: 'Zimbabwe'},
];
