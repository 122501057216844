import axios from 'axios';
import {ChangedStatus} from '../components/UI/organisms/CreatorList/PFCreatorList';
import {Ad} from '../models/Ad';
import {FinalAsset} from '../models/FinalAsset';
import {SelectedCreator} from '../models/SelectedCreator';
import '../interceptors/axiosInterceptor';

interface CreatorListService {
	getCreatorList(campaignId: string): Promise<Array<SelectedCreator>>;
	editCreatorList(campaignId: string, list: Array<number>): Promise<any>;
	changeStatuses(
		campaignId: string,
		list: Array<ChangedStatus>,
	): Promise<Array<SelectedCreator>>;
	filterCreatorList(
		status: number,
		campaignId: string,
	): Promise<SelectedCreator[]>;
	addFinalAssets(data: FormData): Promise<Array<FinalAsset>>;
	deleteFinalAssets(
		campaignId: string,
		creatorId: string,
		ids: Array<number>,
	): Promise<Array<FinalAsset>>;
	editAds(
		creatorId: string,
		metaId: string,
		ads: Array<Ad>,
	): Promise<Array<string>>;
	deleteAds(
		creatorId: string,
		metaId: string,
		ads: Array<string>,
	): Promise<Array<string>>;
	checkMetaAdId(metaAdId: string): Promise<Ad>;
	getFinalAssetsCreatorList(
		campaignId: string,
	): Promise<Array<SelectedCreator>>;
	getPerformanceCreatorList(
		campaignId: string,
	): Promise<Array<SelectedCreator>>;
}

const ENDPOINTS = {
	CREATOR_LIST: `${process.env.REACT_APP_BASE_URL}/api/Campaign/creator-list/`,
	FINAL_ASSETS: `${process.env.REACT_APP_BASE_URL}/api/Assets/final-assets`,
	CHANGE_STATUSES: `${process.env.REACT_APP_BASE_URL}/api/Campaign/creator-list-status/`,
	FILTER_CREATOR_LIST: `${process.env.REACT_APP_BASE_URL}/api/Campaign/filter-creator-list?`,
	META_ADS_ID: `${process.env.REACT_APP_BASE_URL}/api/Campaign/meta-ad-id`,
	CHECK_META_ADS_ID: `${process.env.REACT_APP_BASE_URL}/api/Campaign/check-meta-ad-id/`,
	PERFORMANCE: `${process.env.REACT_APP_BASE_URL}/api/SelectedCreator/creators-performance/`,
};

class CreatorListAxiosService implements CreatorListService {
	async getCreatorList(campaignId: string): Promise<Array<SelectedCreator>> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(ENDPOINTS.CREATOR_LIST + campaignId, {
				headers: {
					Authorization: 'Bearer ' + Token,
				},
			});
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async editCreatorList(campaignId: string, list: number[]): Promise<any> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.put(
				ENDPOINTS.CREATOR_LIST + campaignId,
				{Creators: list},
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async changeStatuses(
		campaignId: string,
		list: ChangedStatus[],
	): Promise<SelectedCreator[]> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.put(
				ENDPOINTS.CHANGE_STATUSES + campaignId,
				{creators: list},
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			return Promise.reject(error);
		}
	}

	async filterCreatorList(
		status: number,
		campaignId: string,
	): Promise<SelectedCreator[]> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.FILTER_CREATOR_LIST +
					`status=${status}&campaignId=${campaignId}`,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async addFinalAssets(data: FormData): Promise<FinalAsset[]> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.put(ENDPOINTS.FINAL_ASSETS, data, {
				headers: {
					Authorization: 'Bearer ' + Token,
				},
			});
			return response.data;
		} catch (error: any) {
			return Promise.reject(error);
		}
	}
	async deleteFinalAssets(
		campaignId: string,
		creatorId: string,
		ids: number[],
	): Promise<FinalAsset[]> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');

		try {
			const response = await axios.put(
				ENDPOINTS.FINAL_ASSETS + '-delete',
				{
					Assets: ids,
					CampaignId: Number(campaignId),
					CreatorId: Number(creatorId),
				},
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			return Promise.reject(error);
		}
	}

	async editAds(
		creatorId: string,
		metaId: string,
		ads: Ad[],
	): Promise<string[]> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');

		try {
			const response = await axios.post(
				ENDPOINTS.META_ADS_ID,
				{SelectedCreatorId: creatorId, MetaCampaignId: metaId, MetaAds: ads},
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			return Promise.reject(error);
		}
	}

	async deleteAds(
		creatorId: string,
		metaId: string,
		ads: string[],
	): Promise<string[]> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.put(
				ENDPOINTS.META_ADS_ID + '-delete',
				{SelectedCreatorId: creatorId, MetaCampaignId: metaId, MetaAdIds: ads},
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);

			return response.data;
		} catch (error: any) {
			return Promise.reject(error);
		}
	}

	async checkMetaAdId(metaAdId: string): Promise<Ad> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(ENDPOINTS.CHECK_META_ADS_ID + metaAdId, {
				headers: {
					Authorization: 'Bearer ' + Token,
				},
			});
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	}

	async getFinalAssetsCreatorList(
		campaignId: string,
	): Promise<SelectedCreator[]> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.FINAL_ASSETS + `?campaignId=${campaignId}`,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async getPerformanceCreatorList(
		campaignId: string,
	): Promise<SelectedCreator[]> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.PERFORMANCE + `${campaignId}/7`,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}
}

export default new CreatorListAxiosService();
