import {toast} from 'react-toastify';
import ContentPlanService from '../../services/ContentPlanService';
import {setContentPlan, setLoading} from '../reducers/ContentPlanReducer';

export const getContentPlan = (id: string) => (dispatch: any) => {
	
	try {
		ContentPlanService.getContentPlan(id).then(res => {
			dispatch(setContentPlan(res));
		});
	} catch (error) {
		toast.error('Error while reading content plan from database');
	}
};
export const editContentPlan = (data: FormData) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		ContentPlanService.editContentPlan(data).then(res => {
			dispatch(setLoading(false));
			dispatch(setContentPlan(res));
			toast.success('Content plan successfully changed!');
		});
	} catch (error) {
		toast.error('Error while editing content plan.');
		dispatch(setLoading(false));
	}
};

export const createContentPlan = (data: FormData) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		ContentPlanService.createContentPlan(data).then(res => {
			dispatch(setLoading(false));
			dispatch(setContentPlan(res));
			toast.success('Content plan successfully added!');
		});
	} catch (error) {
		toast.error('Error while uploading content plan.');
		dispatch(setLoading(false));
	}
};
