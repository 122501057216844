// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/bulldog.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/bulldog_bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/bulldog_medium.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n\tfont-family: 'bulldog';\n\tsrc: local('bulldog'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('opentype');\n}\n\n@font-face {\n\tfont-family: 'bulldog_bold';\n\tfont-weight: bold;\n\tsrc: local('bulldog_bold'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('opentype');\n}\n@font-face {\n\tfont-family: 'bulldog_medium';\n\tsrc: local('bulldog_medium'),\n\t\turl(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('opentype');\n}\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,iFAAkE;AACnE;;AAEA;CACC,2BAA2B;CAC3B,iBAAiB;CACjB,sFAA4E;AAC7E;AACA;CACC,6BAA6B;CAC7B;4DACmD;AACpD","sourcesContent":["@font-face {\n\tfont-family: 'bulldog';\n\tsrc: local('bulldog'), url(./fonts/bulldog.ttf) format('opentype');\n}\n\n@font-face {\n\tfont-family: 'bulldog_bold';\n\tfont-weight: bold;\n\tsrc: local('bulldog_bold'), url(./fonts/bulldog_bold.ttf) format('opentype');\n}\n@font-face {\n\tfont-family: 'bulldog_medium';\n\tsrc: local('bulldog_medium'),\n\t\turl(./fonts/bulldog_medium.otf) format('opentype');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
