import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Images} from '../../../constants/Images';
import {getDateOfDeletion} from '../../../store/actions/UserActions';
import {RootState} from '../../../store/reducers/RootReducer';
import styles from './DataDeletionPage.module.scss';
interface Props {}

function DataDeletion(props: Props) {
	const {} = props;
	const params = useParams();
	const dispatch = useDispatch<any>();
	const token = params.token !== undefined ? params.token.split('=')[1] : '';

	const date: string | null = useSelector(
		(state: RootState) => state.user.dateOfDeletion,
	);

	useEffect(() => {
		dispatch(getDateOfDeletion(token));
	}, []);

	return (
		<>
			<div className={styles.body}>
				<div className={styles.form}>
					<div className={styles.logoWrapper1}>
						<img
							src={Images.FourDots}
							alt="logo"
							className={styles.fourDotsLogo}
						/>
					</div>
					<div className={styles.logoWrapper2}>
						<img
							src={Images.Performative}
							alt="logo-2"
							className={styles.logo}
						/>
					</div>
					<p className={styles.moto}>Social works with creators.</p>
					<div className={styles.itemsWrapper}>
						<h3>
							Your account will be deleted on:
							<p>
								<b>{date.split('T')[0]}</b>
							</p>{' '}
						</h3>
					</div>
					<div className={styles.bottomLogoWrapper}>
						<img
							src={Images.Warmstorm}
							alt="warmstorm-logo"
							className={styles.bottomLogo}
						/>
					</div>
				</div>
				<div className={styles.background}></div>
			</div>
		</>
	);
}

export default DataDeletion;
