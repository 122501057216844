import React, {useState} from 'react';
import {Icons} from '../../../../constants/Icons';
import {FinalAsset} from '../../../../models/FinalAsset';
import styles from './PFFinalAsset.module.scss';

interface PFFinalAssetProps {
	asset: FinalAsset;
	onDelete: () => void;
	editable?: boolean;
}

export const PFFinalAsset: React.FC<PFFinalAssetProps> = ({
	asset,
	onDelete,
	editable = true,
}) => {
	const [bottomControlsShown, setBottomControlsShown] = useState(true);
	const handleOnDelete = () => {
		onDelete();
	};

	const handleOnDownload = async () => {
		if (asset.url) {
			const originalImage = asset.url;
			const image = await fetch(originalImage);

			const nameSplit = originalImage.split('/');
			const duplicateName = nameSplit.pop();

			const imageBlog = await image.blob();
			const imageURL = URL.createObjectURL(imageBlog);
			const link = document.createElement('a');
			link.href = imageURL;
			link.download = '' + duplicateName + '';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};
	const handleOnAssetClick = () => {
		window.open(asset.url, '_blank');
	};
	const handleOnVideoAssetClick = () => {
		setBottomControlsShown(!bottomControlsShown);
	};

	return (
		<div>
			{asset.type === 'Photo' ? (
				<div
					className={styles.card}
					style={{
						backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 1),rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.1), transparent),url(${asset.url})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
					}}>
					<div className={styles.row}>
						<span
							onClick={handleOnDownload}
							className={styles.download}
							title="Download asset">
							{Icons.WHITE_DOWNLOAD}
						</span>
						{editable && (
							<span
								onClick={handleOnDelete}
								className={styles.bin}
								title="Delete asset">
								{Icons.WHITE_BIN}
							</span>
						)}
					</div>
				</div>
			) : (
				<div
					onClick={
						asset.type === 'Photo'
							? handleOnAssetClick
							: handleOnVideoAssetClick
					}
					className={bottomControlsShown ? styles.wrapper : styles.card}>
					<video controls className={styles.video} height={270} width={240}>
						<source src={asset.url}></source>
					</video>
					{bottomControlsShown && (
						<div className={styles.rowVideo}>
							<span onClick={handleOnDownload} className={styles.download}>
								{Icons.WHITE_DOWNLOAD}
							</span>
							<span onClick={handleOnDelete} className={styles.bin}>
								{Icons.WHITE_BIN}
							</span>
						</div>
					)}
				</div>
			)}
			<div className={styles.description}>{asset?.description}</div>
		</div>
	);
};
