import React, {useState} from 'react';
import styles from './PFTabs.module.scss';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import {Users} from '../../../../constants/Users';

interface PFTabsProps {
	onClick: (retVal: string) => void;
	selected?: string;
}

const PFTabs: React.FC<PFTabsProps> = ({onClick, selected}) => {
	const handleClick = (val: string) => {
		setClicked(val);
	};
	const [clicked, setClicked] = useState(selected ? selected : Users.CREATORS);
	function handleChangeTab(val: string) {
		handleClick(val);
		onClick(val);
	}
	return (
		<div className={styles.headerWrapper}>
			<div className={`${styles.header}`}>
				<ul className={styles.itemsWrapper}>
					<li className={`${styles.buttonWrapper}  ${styles.formItem}`}>
						<PFPrimaryButton
							onClick={() => handleChangeTab(Users.CREATORS)}
							text={Users.CREATORS}
							type="submit"
							style={
								clicked === Users.CREATORS
									? styles.clickedUserBtn
									: styles.userBtn
							}
						/>
					</li>
					<li className={`${styles.buttonWrapper}  ${styles.formItem}`}>
						<PFPrimaryButton
							onClick={() => handleChangeTab(Users.BRANDS)}
							text={Users.BRANDS}
							type="submit"
							style={
								clicked === Users.BRANDS
									? styles.clickedUserBtn
									: styles.userBtn
							}
						/>
					</li>
					<li className={`${styles.buttonWrapper}  ${styles.formItem}`}>
						<PFPrimaryButton
							onClick={() => handleChangeTab(Users.ADMINS)}
							text={Users.ADMINS}
							type="submit"
							style={
								clicked === Users.ADMINS
									? styles.clickedUserBtn
									: styles.userBtn
							}
						/>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default PFTabs;
