import React, {useState} from 'react';
import {creatorTabs} from '../../../../constants/CreatorTabs';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import styles from './PFCreatorTabs.module.scss';

interface PFCreatorTabsProps {
	onClick: (retVal: number) => void;
}

export const mapStatus = (status: string) => {
	switch (status) {
		case 'All':
			return -1;
		case 'Suggested':
			return 0;
		case 'Not Interested':
			return 1;
		case 'Interested':
			return 2;
		case 'Pending':
			return 3;
		case 'Declined':
			return 4;
		case 'Accepted':
			return 5;
		case 'Rejected':
			return 6;
		case 'Live':
			return 7;
		default:
			return -1;
	}
};

const PFCreatorTabs: React.FC<PFCreatorTabsProps> = ({onClick}) => {
	const handleClick = (val: string) => {
		setClicked(val);
	};
	const [clicked, setClicked] = useState('All');

	function handleChangeTab(val: string) {
		handleClick(val);

		onClick(mapStatus(val));
	}

	return (
		<div className={styles.headerWrapper}>
			<div className={`${styles.header}`}>
				<ul className={styles.itemsWrapper}>
					{creatorTabs.map((t, index) => (
						<li
							className={`${styles.buttonWrapper}  ${styles.formItem}`}
							key={index}>
							<PFPrimaryButton
								onClick={() => handleChangeTab(t)}
								text={t}
								type="submit"
								style={clicked === t ? styles.clickedUserBtn : styles.userBtn}
							/>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};
export default PFCreatorTabs;
