import 'bootstrap/dist/css/bootstrap.css';
import {Field, Formik} from 'formik';
import React, {useState} from 'react';
import {CampaignModals} from '../../../../constants/CampaignModals';
import {Goal} from '../../../../models/Goal';
import CampaignServices from '../../../../services/CampaignServices';
import {TargetGoalsModalValidationScheme} from '../../../../validation/TargetGoalsModalValidationScheme';
import {PFFormikTextInput} from '../../atoms/FormikTextInput/PFFormikTextInput';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import PFTargetGoalSelectMenu from '../../atoms/TargetGoalSelectMenu/PFTargetGoalSelectMenu';
import PFModal from '../../organisms/Modal/PFModal';
import styles from './PFTargetGoalModal.module.scss';

interface PFTargetGoalModalProps {
	onClick: (goal: Goal) => void;
	onHide: () => void;
	show: boolean;
	label?: string;
	goals: Array<Goal>;
	size?: 'sm' | 'lg';
}
export const PFTargetGoalModal: React.FC<PFTargetGoalModalProps> = ({
	onClick,
	onHide,
	show = false,
	label,
	goals,
	size,
}) => {
	const [name, setName] = useState('');
	const createInitialValues = () => {
		return {id: 0, name: '', targetValue: 0, currentValue: 0, cost: 0};
	};

	const setValues = (
		id: number,
		targetValue: number,
		cost: number | null,
		currentValue: number,
	) => {
		return {
			id: id,
			name: name,
			targetValue: targetValue,
			cost: cost,
			currentValue: currentValue,
		};
	};

	return (
		<PFModal
			size={size}
			show={show}
			onHide={onHide}
			label={CampaignModals.TARGET_GOAL}>
			<div>
				<Formik
					initialValues={createInitialValues()}
					validationSchema={
						!CampaignServices.checkName(name)
							? undefined
							: TargetGoalsModalValidationScheme
					}
					onSubmit={(values: Goal) => {
						onClick(
							setValues(
								values.id,
								values.targetValue,
								values.cost,
								values.currentValue,
							),
						);
					}}>
					{formik => (
						<>
							<div className={styles.col}>
								<div>
									Choose Target Goal
									<PFTargetGoalSelectMenu list={goals} onChange={setName} />
								</div>
								{CampaignServices.checkName(name) && (
									<div className={styles.row}>
										<div className={styles.input}>
											<Field
												label="Define Target Goal"
												component={PFFormikTextInput}
												placeholder="Enter Value"
												name="targetValue"
												type="number"
												number
												value=""
												styleForInput={styles.inputName}
											/>
										</div>
									</div>
								)}
								<div>
									<PFPrimaryButton
										onClick={() => formik.handleSubmit()}
										text={'ADD TARGET GOAL'}
										type="submit"
										style={
											!formik.isValid || name === ''
												? styles.disabled
												: styles.btn
										}
										disabled={!formik.isValid || name === ''}
									/>
								</div>
							</div>
						</>
					)}
				</Formik>
			</div>
		</PFModal>
	);
};

export default PFTargetGoalModal;
