import axios from 'axios';
import '../interceptors/axiosInterceptor';

interface ContentPlanService {
	getContentPlan(id: string): Promise<string>;
	editContentPlan(data: FormData): Promise<string>;
	createContentPlan(data: FormData): Promise<string>;
}
const ENDPOINTS = {
	CONTENT_PLAN: `${process.env.REACT_APP_BASE_URL}/api/Campaign/content-plan/`,
};

class ContentPlanAxiosService implements ContentPlanService {
	async getContentPlan(id: string): Promise<string> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(ENDPOINTS.CONTENT_PLAN + id, {
				headers: {
					Authorization: 'Bearer ' + Token,
				},
			});
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}
	async editContentPlan(data: FormData): Promise<string> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.put(ENDPOINTS.CONTENT_PLAN, data, {
				headers: {
					Authorization: 'Bearer ' + Token,
				},
			});
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async createContentPlan(data: FormData): Promise<string> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.post(ENDPOINTS.CONTENT_PLAN, data, {
				headers: {
					Authorization: 'Bearer ' + Token,
				},
			});
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}
}
export default new ContentPlanAxiosService();
