import React from 'react';
import styles from './PFIconButton.module.scss';

interface PFPrimaryButtonProps {
	onClick: () => void;
	style?: string;
	type?: 'submit' | 'button' | 'reset' | undefined;
	clicked: boolean;
	name: string;
	activeIcon: any;
	inactiveIcon: any;
	disabled?: boolean;
	title?: string;
}
export const PFIconButton: React.FC<PFPrimaryButtonProps> = ({
	onClick,
	style = {},
	type = 'button',
	clicked = false,
	name = '',
	activeIcon,
	inactiveIcon,
	disabled = false,
	title,
}) => {
	return (
		<button
			title={title}
			className={
				clicked
					? `${styles.selectedIconBtn} ${style}`
					: `${styles.iconBtn}  ${style}`
			}
			disabled={disabled}
			onClick={onClick}
			type={type}>
			<div className={styles.icon}>{clicked ? activeIcon : inactiveIcon}</div>
		</button>
	);
};

export default PFIconButton;
