import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {CampaignTabs} from '../../../constants/CampaignTabs';
import {CampaignBrief} from '../../../models/CampaignBrief';
import {getCampaign} from '../../../store/actions/CampaignActions';
import {getCreatorBrief} from '../../../store/actions/CreatorBriefActions';
import {RootState} from '../../../store/reducers/RootReducer';
import Sidebar from '../../UI/molecules/sidebar/Sidebar';
import PFCampaignHeader from '../../UI/organisms/CampaignHeader/PFCampaignHeader';
import PFCreatorBrief from '../../UI/organisms/CreatorBrief/PFCreatorBrief';
import styles from './CreatorBriefPage.module.scss';

interface Props {}

const CreatorBriefPage = (props: Props) => {
	const dispatch = useDispatch<any>();
	const id = useParams();

	useEffect(() => {
		if (id.id !== undefined) {
			dispatch(getCreatorBrief(id.id));
			dispatch(getCampaign(id.id));
		}
	}, [dispatch, id.id]);

	const campaign: CampaignBrief = useSelector(
		(state: RootState) => state.campaign.currentCampaign,
	);

	return (
		<>
			{campaign !== undefined && (
				<div className={styles.page}>
					<div className={styles.sidebarWrapper}>
						<Sidebar activePage="campaigns" />
					</div>
					<div className={styles.contentWrapper}>
						<div className={styles.headerWrapper}>
							<PFCampaignHeader
								onClick={() => {}}
								handleSearch={() => {}}
								label="Creator Brief"
								currentTab={CampaignTabs.CREATOR_BRIEF}
							/>
						</div>
						<PFCreatorBrief campaignId={id.id ? id.id?.toString() : ''} />
					</div>
				</div>
			)}
		</>
	);
};

export default CreatorBriefPage;
