import React, {useEffect, useState} from 'react';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import PFModal from '../../organisms/Modal/PFModal';
import styles from './DeleteUserModal.module.scss';
interface DeleteUserModalProps {
	show: boolean;
	onHide: () => void;
	onDelete?: () => void;
	onCancel?: () => void;
	label?: string;
	fullName?: string | undefined;
}

function DeleteUserModal(props: DeleteUserModalProps) {
	const {show, onHide, onCancel, onDelete, label, fullName} = props;
	const [showModal, setShowModal] = useState<boolean>(false);

	const handleCancel = () => {
		if (onCancel !== undefined) onCancel();
	};

	const handleLeave = () => {
		if (onDelete !== undefined) onDelete();
	};

	useEffect(() => {
		setShowModal(show);
	}, [show]);

	return (
		<div>
			<PFModal
				show={showModal}
				onHide={onHide}
				label={label}
				dialogClassName={styles.body}>
				<div className={styles.body}>
					<p>Are you sure you want to delete {fullName}?</p>
					<PFPrimaryButton
						onClick={handleCancel}
						style={styles.btn}
						text="Cancel"
						type="submit"
					/>
					<PFPrimaryButton
						onClick={handleLeave}
						style={`${styles.btn} ${styles.red}`}
						text="Delete user"
						type="submit"
					/>
				</div>
			</PFModal>
		</div>
	);
}

export default DeleteUserModal;
