import {Field, Formik} from 'formik';
import React, {useEffect} from 'react';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Images} from '../../../constants/Images';
import {LoginUserModel} from '../../../models/LoginUserModel';
import {login} from '../../../store/actions/AuthActions';
import {loginValidationScheme} from '../../../validation/LoginValidationScheme';
import {PFFormikTextInput} from '../../UI/atoms/FormikTextInput/PFFormikTextInput';
import PFPrimaryButton from '../../UI/atoms/PrimaryButton/PFPrimaryButton';
import styles from './LoginPage.module.scss';
import {Link, useNavigate} from 'react-router-dom';
import {RootState} from '../../../store/reducers/RootReducer';

const LoginPage: React.FC = () => {
	const dispatch = useDispatch<any>();
	const [rememberMe, setRememberMe] = useState(false);
	const nav = useNavigate();

	const handleChangeCheckbox = () => {
		setRememberMe(!rememberMe);
	};

	const notificationToken: string | null = useSelector(
		(state: RootState) => state.notification.token,
	);

	const getInitialValues = () => {
		return {email: '', password: ''} as LoginUserModel;
	};
	const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

	useEffect(() => {
		if (isLoggedIn) {
			nav('/campaigns');
		}
	}, [isLoggedIn, nav]);

	return (
		<div className={styles.body}>
			<div className={styles.form}>
				<div>
					<div className={styles.logoWrapper1}>
						<img
							src={Images.FourDots}
							alt="logo"
							className={styles.fourDotsLogo}
						/>
					</div>
					<div className={styles.logoWrapper2}>
						<img
							src={Images.Performative}
							alt="logo-2"
							className={styles.logo}
						/>
					</div>
					<p className={styles.moto}>Social works with creators.</p>
					<div className={styles.itemsWrapper}>
						<Formik
							initialValues={getInitialValues()}
							validationSchema={loginValidationScheme}
							onSubmit={(values: LoginUserModel) => {
								const data: LoginUserModel = {
									email: values.email,
									password: values.password,
									type: 0,
									rememberMe: rememberMe,
									notificationToken: notificationToken,
								};
								dispatch(login(data));
							}}>
							{formik => (
								<div>
									<Field
										label="Email Address"
										component={PFFormikTextInput}
										placeholder="Enter your email"
										name="email"
										type="text"
										styleForInput={styles.inputBox}
										styleForErrorLabel={styles.error}
										value=""
									/>
									<Field
										secureTextEntry
										label="Password"
										component={PFFormikTextInput}
										placeholder="Enter your password"
										name="password"
										type="text"
										styleForErrorLabel={styles.error}
										styleForInput={styles.inputBox}
										value=""
									/>
									<li
										className={`${styles.checkboxwrapper}  ${styles.formItem}`}>
										<div>
											<input
												type="checkbox"
												id="rememberMe"
												name="rememberMe"
												onChange={handleChangeCheckbox}
												className={`${styles.checkbox} ${styles.input}`}></input>
											<label
												htmlFor=""
												className={`${styles.checkboxLabel} ${styles.label}`}>
												Remember me
											</label>
										</div>
										<Link
											to="/forgot-password"
											className={styles.forgotPassword}>
											Forgot Password?
										</Link>
									</li>
									<li className={`${styles.buttonWrapper}  ${styles.formItem}`}>
										<PFPrimaryButton
											onClick={() => formik.handleSubmit()}
											text="LOGIN"
											type="submit"
										/>
									</li>
								</div>
							)}
						</Formik>
					</div>
				</div>
				<div>
					<img
						src={Images.Warmstorm}
						alt="warmstorm-logo"
						className={styles.bottomLogo}
					/>
				</div>
			</div>
			<div className={styles.background}></div>
		</div>
	);
};

export default LoginPage;
