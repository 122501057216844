import React from 'react';
import styles from './PFCampaignSelectMenu.module.scss';

interface PFCampaignSelectMenuProps {
	onChange: (retVal: string) => void;
	defaultValue: number;
}

export const PFCampaignSelectMenu: React.FC<PFCampaignSelectMenuProps> = ({
	onChange,
	defaultValue,
}) => {
	return (
		<select
			className={styles.box}
			onChange={e => onChange(e.target.value)}
			defaultValue={defaultValue}>
			<option value="5" className={styles.option}>
				5
			</option>
			<option value="10" className={styles.option}>
				10
			</option>
			<option value="20" className={styles.option}>
				20
			</option>
		</select>
	);
};

export default PFCampaignSelectMenu;
