import React from 'react';
import styles from './PFCurrencyMenu.module.scss';
import Select from 'react-select';
import {Currency, IntToCurrency} from '../../../../constants/Currency';
import {Budget} from '../../../../models/Budget';

interface PFCurrencyMenuProps {
	onChange: (retVal: number) => void;
	defaultValue: number;
	budget?: Budget;
	editable?: boolean;
}

export const PFCurrencyMenu: React.FC<PFCurrencyMenuProps> = ({
	onChange,
	defaultValue,
	budget,
	editable = true,
}) => {
	const customStyles = {
		control: (provided: any) => ({
			...provided,
			border: '1px solid rgba(0, 0, 0, 0.08)',
			'&:hover': {
				cursor: 'pointer',
			},
		}),
		indicatorSeparator: (provided: any) => ({
			...provided,
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
		}),
		dropdownIndicator: (provided: any) => ({
			...provided,
			color: 'rgba(0, 0, 0, 0.6)',
		}),
	};
	return (
		<Select
			styles={customStyles}
			className={styles.select}
			options={Currency}
			value={
				budget?.currency !== undefined
					? {label: IntToCurrency(budget?.currency), value: budget.currency}
					: Currency[0]
			}
			defaultValue={Currency[0]}
			onChange={e => onChange(e!.value)}
			isDisabled={!editable}
		/>
	);
};

export default PFCurrencyMenu;
