import {useEffect, useState} from 'react';
import {Link, Route, Routes, useLocation} from 'react-router-dom';
import {ToastContainer, ToastOptions} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import CampaignsPage from './components/pages/campaigns/CampaignsPage';
import CreateCampaignPage from './components/pages/createCampaign/CreateCampaignPage';
import CreatorBriefPage from './components/pages/creatorBrief/CreatorBriefPage';
import CreatorListPage from './components/pages/creatorList/CreatorListPage';
import EditCampaignPage from './components/pages/editCampaign/EditCampaignPage';
import FinalAssetsPage from './components/pages/finalAssets/FInalAssetsPage';
import LoginPage from './components/pages/login/LoginPage';
import PerformancePage from './components/pages/performance/PerformancePage';
import UsersPage from './components/pages/users/UsersPage';
import {WarningLayer} from './components/pages/warningLayer/WarningLayer';
import JwtUtilServices from './services/JwtUtilServices';
import {fetchToken, onMessageListener} from './firebase';
import {toast} from 'react-toastify';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {RootState} from './store/reducers/RootReducer';
import NotificationsPage from './components/pages/notifications/NotificationsPage';
import {
	addNotification,
	hasUnreadNotifications,
} from './store/actions/NotificationActions';
import {Notification} from './models/Notifications';
import {NotificationModel} from './models/NotificationModel';
import styles from './App.module.scss';
import {isMobile} from 'react-device-detect';
import ProtectedRoute, {
	ProtectedRouteProps,
} from './components/routes/ProtectedRoute';
import ResetPassword from './components/pages/ResetPassword/ResetPassword';
import ForgotPassword from './components/pages/ForgotPassword/ForgotPassword';
import DataDeletionPage from './components/pages/dataDeletion/DataDeletionPage';
import DataDeletion from './components/pages/dataDeletion/DataDeletionPage';
import 'react-toastify/dist/ReactToastify.css';
import TikTok from './components/pages/tiktok/TikTok';

function App() {
	const [show, setShow] = useState(false);
	const [notification, setNotification] = useState({title: '', body: ''});
	const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
	const dispatch = useDispatch<any>();

	const notificationToken: string | null = useSelector(
		(state: RootState) => state.notification.token,
		shallowEqual,
	);
	dispatch(fetchToken());
	const {pathname} = useLocation();
	const CustomToastWithLink = (url: string, text: string) => {
		return pathname.includes(url) || url.includes(pathname) ? (
			<Link reloadDocument to={`${url}`}>
				<div>{text}</div>
			</Link>
		) : (
			<Link to={`${url}`}>
				<div>{text}</div>
			</Link>
		);
	};
	const toastPreferences: ToastOptions = {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'dark',
	};

	onMessageListener()
		.then((payload: NotificationModel) => {
			if (payload.notification != null) {
				var notification: Notification = {
					id: Number(payload.data.Id),
					title: payload.notification.title,
					body: payload.notification.body,
					isRead:
						payload.data.isRead.toLocaleLowerCase() === 'true' ? true : false,
					isSent:
						payload.data.isSent.toLocaleLowerCase() === 'true' ? true : false,
					userId: 0,
					created: new Date(payload.data.Created).toDateString(),
					entityId: Number(payload.data.entityId),
					notificationType: Number(payload.data.notificationType),
				};
				setNotification({
					title: payload.notification.title,
					body: payload.notification.body,
				});
				dispatch(addNotification(notification));
				setShow(true);
				if (notification.title.includes('New Client request')) {
					toast.info(
						CustomToastWithLink(
							payload.data.Url + '/client',
							payload.notification.body,
						),
					);
				} else {
					toast.info(
						CustomToastWithLink(payload.data.Url, payload.notification.body),
					);
				}
			}
		})
		.catch(err => console.log('failed: ', err));

	useEffect(() => {
		dispatch(hasUnreadNotifications());
	}, []);

	const [screenSize, getDimension] = useState({
		dynamicWidth: window.innerWidth,
		dynamicHeight: window.innerHeight,
	});
	const setDimension = () => {
		getDimension({
			dynamicWidth: window.innerWidth,
			dynamicHeight: window.innerHeight,
		});
	};

	useEffect(() => {
		if (isMobile) {
			window.addEventListener('resize', setDimension);

			return () => {
				window.removeEventListener('resize', setDimension);
			};
		}
	}, [screenSize]);

	const defaultProps: Omit<ProtectedRouteProps, 'outlet'> = {
		isAuth:
			localStorage.getItem('token') !== null ||
			sessionStorage.getItem('token') !== null,
		authPath: '/login',
	};

	return (
		<>
			{notificationToken === null && (
				<p className={styles.alert}>Please enable notifications in browser</p>
			)}

			{screenSize.dynamicWidth < screenSize.dynamicHeight && (
				<Routes>
					<Route path="/" element={<WarningLayer />} />
					<Route path="/tiktok/:code" element={<WarningLayer />} />

					<Route path="login" element={<WarningLayer />} />
					<Route path="users/:type" element={<WarningLayer />} />
					<Route path="users" element={<WarningLayer />} />
					<Route path="campaigns" element={<WarningLayer />} />
					<Route path="sow/:id" element={<WarningLayer />} />
					<Route path="create-campaign" element={<WarningLayer />} />
					<Route path="creator-brief/:id" element={<WarningLayer />} />
					<Route path="creator-list/:id" element={<WarningLayer />} />
					<Route path="content-plan/:id" element={<WarningLayer />} />
					<Route path="final-assets/:id" element={<WarningLayer />} />
					<Route path="performance/:id" element={<WarningLayer />} />
					<Route path="reset-password/:token" element={<WarningLayer />} />
					<Route path="forgot-password" element={<WarningLayer />} />
					<Route path="data-deletion/:token" element={<WarningLayer />} />
				</Routes>
			)}
			<Routes>
				{JwtUtilServices.parseJwt() ? (
					<Route
						path="/"
						element={
							<ProtectedRoute {...defaultProps} outlet={<CampaignsPage />} />
						}
					/>
				) : (
					<Route path="/" element={<LoginPage />} />
				)}
				<Route path="login" element={<LoginPage />} />
				<Route path="/tiktok/:code" element={<TikTok />} />

				<Route path="reset-password/:token" element={<ResetPassword />} />
				<Route path="forgot-password" element={<ForgotPassword />} />
				<Route path="data-deletion/:token" element={<DataDeletion />} />
				<Route
					path="users/:type"
					element={<ProtectedRoute {...defaultProps} outlet={<UsersPage />} />}
				/>
				<Route
					path="users"
					element={<ProtectedRoute {...defaultProps} outlet={<UsersPage />} />}
				/>
				<Route
					path="campaigns"
					element={
						<ProtectedRoute {...defaultProps} outlet={<CampaignsPage />} />
					}
				/>
				<Route
					path="sow/:id"
					element={
						<ProtectedRoute {...defaultProps} outlet={<EditCampaignPage />} />
					}
				/>
				<Route
					path="create-campaign"
					element={
						<ProtectedRoute {...defaultProps} outlet={<CreateCampaignPage />} />
					}
				/>
				<Route
					path="creator-brief/:id"
					element={
						<ProtectedRoute {...defaultProps} outlet={<CreatorBriefPage />} />
					}
				/>
				<Route
					path="creator-list/:id"
					element={
						<ProtectedRoute {...defaultProps} outlet={<CreatorListPage />} />
					}
				/>
				<Route
					path="final-assets/:id"
					element={
						<ProtectedRoute {...defaultProps} outlet={<FinalAssetsPage />} />
					}
				/>
				<Route
					path="notifications"
					element={
						<ProtectedRoute {...defaultProps} outlet={<NotificationsPage />} />
					}
				/>
				<Route
					path="performance/:id"
					element={
						<ProtectedRoute {...defaultProps} outlet={<PerformancePage />} />
					}
				/>
			</Routes>
			<ToastContainer autoClose={5000} />
		</>
	);
}

export default App;
