export const CampaignModals = {
	LIVE_MARKET: 'market',
	TARGET_GOAL: 'goal',
	IMAGE: 'image',
	LIVE_DATE: 'date',
	EDIT_DATE: 'edit_date',
	CREATOR_BRIEF_ASSET: 'creator_brief_asset',
	USAGE_TIME: 'usage_time',
	ADD_CREATOR: 'add_creator',
	ADD_ASSET: 'Add Final Asset',
	CHANGES: 'unsaved_changes',
	DELETE_USER: 'delete_user',
};
