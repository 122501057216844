import styles from './PFModal.module.scss';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {Users} from '../../../../constants/Users';
import {CampaignModals} from '../../../../constants/CampaignModals';

interface PFModalProps {
	children?: React.ReactNode;
	onHide: () => void;
	show?: boolean;
	label?: string;
	size?: 'sm' | 'lg';
	dialogClassName?: string;
}

function PFModal(props: PFModalProps) {
	const getModalTitle = () => {
		if (props.label === Users.ADMIN) return 'Add Admin';
		else if (props.label === CampaignModals.LIVE_MARKET)
			return 'Add Live Market';
		else if (props.label === CampaignModals.LIVE_DATE)
			return 'Add Campaign Date';
		else if (props.label === CampaignModals.EDIT_DATE)
			return 'Modify Campaign Date';
		else if (props.label === CampaignModals.TARGET_GOAL)
			return 'Add Target Goal';
		else if (props.label === CampaignModals.CREATOR_BRIEF_ASSET)
			return 'Add Asset';
		else if (props.label === CampaignModals.ADD_ASSET) return 'Add Final Asset';
		else if (props.label === CampaignModals.ADD_CREATOR) return 'Add Creator';
		else if (props.label === CampaignModals.USAGE_TIME) return 'Add Usage Time';
		else if (props.label === CampaignModals.CHANGES)
			return 'You have unsaved changes';
		else if (props.label === CampaignModals.DELETE_USER) return 'Delete user';
		return 'Invite User';
	};
	return (
		<Modal
			{...props}
			size={props.size ? props.size : 'lg'}
			aria-labelledby="contained-modal-title-vcenter"
			dialogClassName={props.dialogClassName}
			centered>
			<Modal.Header className={`${styles.border} ${styles.font}`} closeButton>
				<Modal.Title
					className={`${styles.border} ${styles.font}`}
					id="contained-modal-title-vcenter">
					{getModalTitle()}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className={`${styles.border} ${styles.font}`}>
				{props.children}
			</Modal.Body>
		</Modal>
	);
}

export default PFModal;
