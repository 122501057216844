import {Field, Formik} from 'formik';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Dots} from 'react-activity';
import {useDispatch, useSelector} from 'react-redux';
import {Icons} from '../../../../constants/Icons';
import {CampaignBrief} from '../../../../models/CampaignBrief';
import {CardType} from '../../../../models/CardType';
import {CreatorBrief} from '../../../../models/CreatorBrief';
import {CreatorBriefAsset} from '../../../../models/CreatorBriefAsset';
import {CreatorBriefRequest} from '../../../../models/CreatorBriefRequest';
import {CreatorBriefResponse} from '../../../../models/CreatorBriefResponse';
import {UsageTime} from '../../../../models/UsageTime';
import {changeUnsavedChanges} from '../../../../store/actions/CampaignActions';
import {editCreatorBrief} from '../../../../store/actions/CreatorBriefActions';
import {RootState} from '../../../../store/reducers/RootReducer';
import {creatorBriefValidationScheme} from '../../../../validation/CreatorBriefValidationScheme';
import PFCreatorBriefAssetCard from '../../atoms/CreatorBriefAssetCard/PFCreatorBriefAssetCard';
import PFFileButton from '../../atoms/FileButton/PFFileButton';
import {PFFormikTextInput} from '../../atoms/FormikTextInput/PFFormikTextInput';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import PFCard from '../../molecules/Card/PFCard';
import PFCreatorBriefAssetModal from '../../molecules/CreatorBriefAssetModal/PFCreatorBriefAssetModal';
import PFUsageTimeModal from '../../molecules/UsageTimeModal/PFUsageTimeModal';
import styles from './PFCreatorBrief.module.scss';

interface PFCreatorBriefProps {
	campaignId: string;
}

const PFCreatorBrief: React.FC<PFCreatorBriefProps> = ({campaignId}) => {
	const {creatorBrief, creatorBriefUrl}: CreatorBriefResponse = useSelector(
		(state: RootState) => state.creatorBrief.currentCreatorBrief,
	);

	const [showAssetModal, setShowAssetModal] = useState(false);
	const [showUsageTimeModal, setShowUsageTimeModal] = useState(false);
	const dispatch = useDispatch<any>();

	const [platform, setPlatform] = useState(
		creatorBrief && creatorBrief.platform ? creatorBrief.platform : '',
	);
	const [shootTime, setShootTime] = useState(
		creatorBrief && creatorBrief.shootTime ? creatorBrief.shootTime : '',
	);
	const [assets, setAssets] = useState<Array<CreatorBriefAsset>>(
		creatorBrief && creatorBrief.assets ? creatorBrief.assets : [],
	);
	const [usageTime, setUsageTime] = useState(
		creatorBrief && creatorBrief.usageTime ? creatorBrief.usageTime : [],
	);
	const [summary, setSummary] = useState(
		creatorBrief && creatorBrief.summary ? creatorBrief.summary : '',
	);
	const [payment, setPayment] = useState(
		creatorBrief && creatorBrief.payment ? creatorBrief.payment : '',
	);
	const [objective, setObjective] = useState(
		creatorBrief && creatorBrief.objective ? creatorBrief.objective : '',
	);
	const [showFillRequiredFieldsPopup, setShowFillRequiredFieldsPopup] =
		useState(false);
	const [framework, setFramework] = useState(
		creatorBrief && creatorBrief.creativeFramework
			? creatorBrief.creativeFramework
			: '',
	);
	const fileUploadRef = useRef<HTMLInputElement>(null);
	const [file, setFile] = useState<any>();
	const [, setFileName] = useState<string | undefined>();
	const isLoading: boolean = useSelector(
		(state: RootState) => state.creatorBrief.isLoading,
	);

	const campaign: CampaignBrief = useSelector(
		(state: RootState) => state.campaign.currentCampaign,
	);
	const hasCampaignEnded =
		campaign && campaign.campaignStatus ? campaign.campaignStatus === 4 : false;

	useEffect(() => {
		dispatch(changeUnsavedChanges(true));
	}, [dispatch, payment, platform, shootTime]);

	useEffect(() => {
		if (showFillRequiredFieldsPopup) {
			alert('Please fill in all required fields.');
			setShowFillRequiredFieldsPopup(false);
		}
	}, [showFillRequiredFieldsPopup]);

	const handleAddAsset = (newAsset: CreatorBriefAsset) => {
		setAssets(prevState => [...prevState, newAsset]);
		setShowAssetModal(false);
		dispatch(changeUnsavedChanges(true));
	};
	const handleAddUsageTime = (newUsageTime: UsageTime) => {
		setUsageTime(prevState => [...prevState, newUsageTime]);
		setShowUsageTimeModal(false);
		dispatch(changeUnsavedChanges(true));
	};

	const getInitVals = () => {
		return {
			summary: creatorBrief && creatorBrief.summary ? creatorBrief.summary : '',
			payment: creatorBrief && creatorBrief.payment ? creatorBrief.payment : '',
			platform:
				creatorBrief && creatorBrief.platform ? creatorBrief.platform : '',
			objective:
				creatorBrief && creatorBrief.objective ? creatorBrief.objective : '',
			shootTime:
				creatorBrief && creatorBrief.shootTime ? creatorBrief.shootTime : '',
			usageTime:
				creatorBrief && creatorBrief.usageTime ? creatorBrief.usageTime : [],
			creativeFramework:
				creatorBrief && creatorBrief.creativeFramework
					? creatorBrief.creativeFramework
					: '',
			assets: creatorBrief && creatorBrief.assets ? creatorBrief.assets : [],
			campaignId: Number(campaignId),
		};
	};

	const setBriefFile = () => {
		return fileUploadRef.current?.click();
	};
	const handleFileUpload = (e: any) => {
		setFile(e.target.files[0]);
		setFileName(e.target.files[0].name);
		dispatch(changeUnsavedChanges(true));
	};
	const handleDownload = () => {
		window.open(file.url);
	};

	const handleDelete = () => {
		setFile(undefined);
		dispatch(changeUnsavedChanges(true));
	};
	const handleDeleteUsageTime = (name: string) => {
		const newList = usageTime.filter(u => u.name !== name);
		setUsageTime(newList);
		dispatch(changeUnsavedChanges(true));
	};
	const handleOnAssetDelete = (id: number) => {
		const newList = assets.filter(a => a.id !== id);
		setAssets(newList);
		dispatch(changeUnsavedChanges(true));
	};
	const checkIsValid = useMemo(() => {
		if (
			payment === '' ||
			platform === '' ||
			shootTime === '' ||
			usageTime.length === 0 ||
			assets.length === 0 ||
			file === undefined ||
			hasCampaignEnded
		) {
			return false;
		} else {
			return true;
		}
	}, [
		assets.length,
		file,
		payment,
		platform,
		shootTime,
		usageTime.length,
		hasCampaignEnded,
	]);

	const getFileFormData = () => {
		const formDataFile = new FormData();
		if (file.lastModified) {
			formDataFile.append('creatorBriefFile', file);
		}
		return formDataFile;
	};

	useEffect(() => {
		dispatch(changeUnsavedChanges(false));
		setPayment(creatorBrief?.payment);
		setSummary(creatorBrief?.summary);
		setPlatform(creatorBrief?.platform);
		setAssets(creatorBrief?.assets);
		setShootTime(creatorBrief?.shootTime);
		setObjective(creatorBrief?.objective);
		setUsageTime(creatorBrief?.usageTime);
		setFramework(creatorBrief?.creativeFramework);

		const parts = creatorBriefUrl.split('Creator-briefs/');
		if (parts.length > 0 && parts[1] !== undefined) {
			let fileName = decodeURIComponent(parts[1].split('?')[0]);
			const file = {name: fileName, url: creatorBriefUrl};
			setFile(file);
		}
	}, [creatorBrief, creatorBriefUrl]);

	if (creatorBrief.campaignId === -1) {
		return (
			<div className={styles.dots}>
				<Dots size={40} color={'#3560FF'} />
			</div>
		);
	}

	return (
		<div>
			<Formik
				enableReinitialize={true}
				initialValues={getInitVals()}
				validateOnBlur
				validateOnMount
				validateOnChange
				validationSchema={creatorBriefValidationScheme}
				onSubmit={(values: CreatorBrief) => {
					const brief: CreatorBrief = {
						summary: values.summary,
						payment: payment,
						platform: platform,
						objective: values.objective,
						shootTime: shootTime,
						usageTime: usageTime,
						creativeFramework: values.creativeFramework,
						assets: assets,
						campaignId: Number(campaignId),
					};
					const briefReq: CreatorBriefRequest = {
						creatorBrief: brief,
						creatorBriefFile: getFileFormData(),
					};

					dispatch(editCreatorBrief(campaignId, briefReq));
					dispatch(changeUnsavedChanges(false));
				}}>
				{formik => (
					<div className={styles.content}>
						{!!formik.dirty && dispatch(changeUnsavedChanges(true))}
						<div className={styles.head}>
							<div className={styles.left}>
								<div className={styles.highlight}>Creator Brief</div>
								<div className={`${styles.separatorTop} ${styles.tabWrapper}`}>
									<div className={styles.brand}>Summary *</div>
									<div className={styles.textbox}>
										<Field
											component={PFFormikTextInput}
											editable={!hasCampaignEnded}
											placeholder="Enter Brief Summary"
											name="summary"
											type="text"
											value={summary}
											style={styles.bold}
											styleForInput={styles.inputBox}
											textArea
											onChange={(e: string) => setSummary(e)}
											styleForErrorLabel={styles.styleForErrorLabel}
										/>
									</div>

									<div className={styles.brand}>Creator Brief File *</div>
									<div className={styles.row}>
										<div className={styles.file}>
											{file ? (
												<div
													title={`Open ${file?.name}`}
													className={`${styles.fileName} ${styles.edit}`}>
													<div className={styles.fileNameWrapper}>
														<span className={styles.fileNameText}>
															{file?.name}
														</span>
														<span
															className={styles.download}
															title="Download file"
															onClick={handleDownload}>
															{Icons.DOWNLOAD}
														</span>
														{!hasCampaignEnded && (
															<span
																className={styles.x}
																title="Delete file"
																onClick={handleDelete}>
																{Icons.X}
															</span>
														)}
													</div>
												</div>
											) : (
												<div className={styles.fileName}>
													{file?.name
														? file.name
														: 'No Creator Brief uploaded.'}
												</div>
											)}
											<div className={styles.btn}>
												<PFFileButton
													onClick={setBriefFile}
													text="Upload Brief File"
													disabled={hasCampaignEnded}
												/>
											</div>
											<input
												id={'file'}
												ref={fileUploadRef}
												type={'file'}
												style={{display: 'none'}}
												accept=".doc,.txt,.pdf"
												onChange={handleFileUpload}
											/>
										</div>
									</div>

									<div className={styles.supp}>Supported: pdf, txt, doc</div>
									<div className={styles.brand}>Objective #1 *</div>
									<div className={styles.textbox}>
										<Field
											component={PFFormikTextInput}
											editable={!hasCampaignEnded}
											placeholder="Enter Objective #1"
											name="objective"
											type="text"
											value={objective}
											style={styles.inputBox}
											onChange={(e: string) => setObjective(e)}
											styleForInput={styles.textArea}
											textArea
											styleForErrorLabel={styles.styleForErrorLabel}
										/>
									</div>
									<div className={styles.brand}>Creative Framework *</div>
									<div className={styles.textbox}>
										<Field
											component={PFFormikTextInput}
											editable={!hasCampaignEnded}
											placeholder="Enter Creative Framework"
											name="creativeFramework"
											type="text"
											value={framework}
											style={styles.bold}
											onChange={(e: string) => setFramework(e)}
											styleForErrorLabel={styles.styleForErrorLabel}
											styleForInput={styles.textArea}
											textArea
										/>
									</div>
								</div>
							</div>
							<div className={styles.right}>
								<div className={styles.highlight}>Campaign Details *</div>
								<div className={styles.cardWrapper}>
									<PFCard
										type={CardType.Payment}
										val={payment}
										onChange={setPayment}
										editable={!hasCampaignEnded}
									/>
									<PFCard
										type={CardType.Platform}
										val={platform}
										onChange={setPlatform}
										editable={!hasCampaignEnded}
									/>
									<PFCard
										type={CardType.ShootTime}
										val={shootTime}
										onChange={setShootTime}
										editable={!hasCampaignEnded}
									/>
								</div>
								<div className={styles.highlight}>
									Usage Time *
									{!hasCampaignEnded && (
										<div
											className={styles.icons}
											onClick={() => setShowUsageTimeModal(true)}>
											{Icons.PLUS}
										</div>
									)}
								</div>
								<div className={styles.cardWrapper}>
									{usageTime.map((ut, index) => (
										<PFCard
											key={index}
											type={CardType.UsageTime}
											name={ut.name}
											val={ut.value}
											onChange={() => {}}
											onDelete={handleDeleteUsageTime}
											editable={!hasCampaignEnded}
										/>
									))}
								</div>
								<div className={styles.highlight}>
									Assets *
									{!hasCampaignEnded && (
										<div
											className={styles.icons}
											onClick={() => setShowAssetModal(true)}>
											{Icons.PLUS}
										</div>
									)}
								</div>
								<div style={styleCards} className={styles.cards}>
									{assets.map((a, index) => (
										<PFCreatorBriefAssetCard
											onDelete={handleOnAssetDelete}
											asset={a}
											key={index}
											editable={!hasCampaignEnded}
										/>
									))}
								</div>

								<div className={styles.emptyRow} />
							</div>
						</div>
						<div className={styles.bttmRow}>
							<div className={styles.Btn}>
								<PFPrimaryButton
									onClick={() => {
										window.location.reload();
									}}
									text={'DISCARD'}
									style={styles.discardBtn}
								/>
							</div>
							<div className={styles.Btn}>
								<PFPrimaryButton
									onClick={() => {
										if (!(checkIsValid && formik.isValid)) {
											setShowFillRequiredFieldsPopup(true);
										} else {
											formik.handleSubmit();
										}
									}}
									isLoading={isLoading}
									text={'SAVE CREATOR BRIEF'}
									style={
										checkIsValid && formik.isValid
											? styles.saveBtn
											: styles.disabled
									}
								/>
							</div>
						</div>
					</div>
				)}
			</Formik>
			<PFCreatorBriefAssetModal
				onClick={handleAddAsset}
				onHide={() => setShowAssetModal(false)}
				show={showAssetModal}
			/>
			<PFUsageTimeModal
				onClick={handleAddUsageTime}
				onHide={() => setShowUsageTimeModal(false)}
				show={showUsageTimeModal}
				usageTimeValues={usageTime.map(u => u.name)}
			/>
		</div>
	);
};
const styleCards = {
	display: 'flex',
	flexWrap: 'wrap' as 'wrap',
};
export default PFCreatorBrief;
