import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {v4 as uuidv4} from 'uuid';
import {Icons} from '../../../../constants/Icons';
import {Ad} from '../../../../models/Ad';
import {FinalAsset} from '../../../../models/FinalAsset';
import {SelectedCreator} from '../../../../models/SelectedCreator';
import CreatorListService from '../../../../services/CreatorListService';
import {changeUnsavedChanges} from '../../../../store/actions/CampaignActions';
import {
	addFinalAssets,
	deleteAds,
	deleteFinalAssets,
	editAds,
} from '../../../../store/actions/CreatorListActions';
import {RootState} from '../../../../store/reducers/RootReducer';
import {PFAdId} from '../../atoms/AdId/PFAdId';
import {PFFinalAsset} from '../../atoms/FinalAsset/PFFinalAsset';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import {PFTextInput} from '../../atoms/TextInput/PFTextInput';
import PFFinalAssetModal from '../FinalAssetModal/PFFinalAssetModal';
import styles from './PFFinalAssetExpandedBox.module.scss';

interface PFFinalAssetExpandedBoxProps {
	creatorName: string;
	creatorId: string;
	campaignId: string;
	metaCampaignId: string;
	editable?: boolean;
}

export const PFFinalAssetExpandedBox: React.FC<
	PFFinalAssetExpandedBoxProps
> = ({creatorId, creatorName, campaignId, metaCampaignId, editable = true}) => {
	const dispatch = useDispatch<any>();

	const creatorList: Array<SelectedCreator> = useSelector(
		(state: RootState) => state.creatorList.currentCreatorList,
	);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [finalAssets, setFinalAssets] = useState(
		creatorList.find(c => c.id.toString() === creatorId)?.assets,
	);
	const [adIds, setAdIds] = useState<string[]>([]);

	useEffect(() => {
		creatorList.forEach(c => {
			if (c.id.toString() === creatorId) {
				if (c.ads) {
					setAdIds(c.ads);
				}
				return;
			}
		});
	}, [creatorId, creatorList]);

	const [showModal, setShowModal] = useState(false);
	const [showAssets, setShowAssets] = useState<Array<FinalAsset>>(
		finalAssets ? finalAssets : [],
	);
	const [showNewAssets, setShowNewAssets] = useState<Array<FinalAsset>>([]);
	const [newAssets, setNewAssets] = useState<Array<FormData>>([]);
	const [newAds, setNewAds] = useState<Ad[]>([]);
	const [adsToBeDeleted, setAdsToBeDeleted] = useState<string[]>([]);

	const handleOnAddAsset = (
		data: FormData,
		desc: string,
		file: any,
		type: string,
	) => {
		setNewAssets(prev => [...prev, data]);
		const url = URL.createObjectURL(file);
		let asset: FinalAsset = {
			description: desc,
			id: uuidv4(),
			url: url,
			type: type,
		};
		if (data.has('description') && data.has('Asset')) {
			setShowNewAssets(prev => [...prev, asset]);
			setShowModal(false);
		}
	};

	const handleOnDelete = (img: FinalAsset, index: number) => {
		const showImagesIds = showAssets.map(a => a.id);
		if (!showImagesIds.includes(img.id)) {
			setNewAssets(prev => prev.filter((_, i) => i !== index));
		}
		setShowNewAssets(prev => prev.filter(a => a.id !== img.id));
		setShowAssets(prev => prev.filter(a => a.id !== img.id));
		dispatch(changeUnsavedChanges(true));
	};

	const handleOnSubmit = () => {
		if (newAssets.length > 0) {
			newAssets.forEach(asset => {
				asset.append('CampaignId', campaignId);
				asset.append('CreatorId', creatorId);
				dispatch(addFinalAssets(asset));
			});
			setNewAssets([]);
		}
		const toDelete = finalAssets?.filter(a => !showAssets.includes(a));

		if (toDelete) {
			const idsToDelete: any[] = toDelete?.map(c => c.id);
			if (idsToDelete.length > 0) {
				dispatch(deleteFinalAssets(campaignId, creatorId, idsToDelete));
			}
		}
		dispatch(changeUnsavedChanges(false));
	};

	const handleOnDiscard = () => {
		setNewAssets([]);
		setShowNewAssets([]);
		if (finalAssets) {
			if (showAssets.length < finalAssets?.length) {
				setShowAssets(finalAssets);
			}
		}
	};
	const handleOnAddAdId = (value: string) => {
		value = value.trim();
		if (value !== '') {
			const oldAdIndex = adIds.findIndex(a => a === value);
			const newAdIndex = newAds.findIndex(a => a.metaAdId === value);

			if (oldAdIndex === -1 && newAdIndex === -1) {
				CreatorListService.checkMetaAdId(value)
					.then(res => setNewAds(prev => [...prev, res]))
					.catch(() => {
						toast.error(`Ad with ID '${value}' does not exists!`);
					});
			} else {
				toast.error(`Ad with ID '${value}' already connected!`);
			}
		}
		dispatch(changeUnsavedChanges(true));
	};

	const handleOnAdRemove = (value: string) => {
		const newList = adIds.filter(a => a !== value);
		setAdIds(newList);
		const newAdsList = newAds.filter(a => a.metaAdId !== value);
		setNewAds(newAdsList);
		setAdsToBeDeleted(prev => [...prev, value]);
		dispatch(changeUnsavedChanges(true));
	};

	const handleOnAdsSubmit = () => {
		if (newAds.length > 0) {
			dispatch(editAds(creatorId, metaCampaignId, newAds));
			setNewAds([]);
		}

		if (adsToBeDeleted.length > 0) {
			dispatch(deleteAds(creatorId, metaCampaignId, adsToBeDeleted));
			setAdsToBeDeleted([]);
		}
		dispatch(changeUnsavedChanges(true));
	};

	const handleOnAdDiscard = () => {
		setNewAds([]);
		setAdIds(prev => [...prev, ...adsToBeDeleted]);
		setAdsToBeDeleted([]);
	};

	return (
		<>
			{newAssets.length > 0 && dispatch(changeUnsavedChanges(true))}
			<div className={styles.container}>
				<div className={styles.highlight}>
					{creatorName}'s Final Assets{' '}
					{editable && (
						<span
							onClick={() => setShowModal(true)}
							className={styles.icon}
							title="Add new asset">
							{Icons.PLUS}
						</span>
					)}
				</div>
				<div className={styles.contentWrapper}>
					<div style={styleCards} className={styles.cards}>
						{showAssets.length > 0 || showNewAssets.length > 0 ? (
							showAssets?.concat(showNewAssets).map((img, index) => (
								<div key={index} className={styles.img}>
									<PFFinalAsset
										asset={img}
										editable={editable}
										onDelete={() => handleOnDelete(img, index)}
									/>
								</div>
							))
						) : (
							<div>There is no final assets.</div>
						)}
					</div>
				</div>
				<div className={styles.bttmRow}>
					<div className={styles.Btn}>
						<PFPrimaryButton
							onClick={handleOnDiscard}
							text={'DISCARD'}
							style={styles.discardBtn}
						/>
					</div>
					<div className={styles.Btn}>
						<PFPrimaryButton
							disabled={
								!(
									newAssets.length > 0 ||
									(finalAssets && finalAssets?.length !== showAssets.length)
								)
							}
							onClick={handleOnSubmit}
							text={'SAVE CHANGES'}
							style={
								newAssets.length > 0 ||
								(finalAssets && finalAssets?.length !== showAssets.length)
									? styles.saveBtn
									: styles.disabled
							}
						/>
					</div>
				</div>
				<div className={styles.highlight2}>Connect Ads</div>
				<div className={styles.adContentWrapper}>
					<div className={styles.ads} style={styleCards}>
						{adIds.map((ad, index) => (
							<PFAdId key={index} text={ad} onRemove={handleOnAdRemove} />
						))}
						{newAds.map((ad, index) => (
							<PFAdId
								key={index}
								text={ad.metaAdId}
								onRemove={handleOnAdRemove}
							/>
						))}
					</div>
					<PFTextInput
						tooltip="Press Enter to add ID"
						label={'Add Ad ID'}
						value={''}
						style={styles.input}
						styleForInput={styles.inputName}
						submitOnEnter={true}
						placeholder={'Enter Ad ID'}
						onChange={handleOnAddAdId}
						editable={editable}
					/>
				</div>
				<div className={styles.bttmRow}>
					<div className={styles.Btn}>
						<PFPrimaryButton
							onClick={handleOnAdDiscard}
							text={'DISCARD'}
							style={styles.discardBtn}
						/>
					</div>
					<div className={styles.Btn}>
						<PFPrimaryButton
							onClick={handleOnAdsSubmit}
							text={'SAVE CHANGES'}
							style={styles.saveBtn}
							disabled={!editable}
						/>
					</div>
				</div>
			</div>
			<PFFinalAssetModal
				size="sm"
				onClick={handleOnAddAsset}
				onHide={() => setShowModal(false)}
				show={showModal}
			/>
		</>
	);
};
const styleCards = {
	display: 'flex',
	flexWrap: 'wrap' as 'wrap',
};
