import axios from 'axios';
import {toast} from 'react-toastify';
import AuthServices from '../services/AuthServices';
import {logOut} from '../store/reducers/AuthReducer';
import store from '../store/store';

let refreshTokenPromise: any;


axios.interceptors.request.use(
    config => {
        config.withCredentials = true;
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(
    response => response,
    error => {
        const originalConfig = error.config;
        let loggedIn: boolean =
            localStorage.getItem('token') !== null ||
            sessionStorage.getItem('token') !== null;
        if (error !== undefined && error.response !== undefined && loggedIn) {
            if (
                error.response.status === 401 &&
                !originalConfig._retry &&
                originalConfig.url !==
                    process.env.REACT_APP_BASE_URL + '/api/User/refresh-token'
            ) {
                originalConfig._retry = true;
                originalConfig.withCredentials = true;
                if (!refreshTokenPromise) {
                    refreshTokenPromise = AuthServices.refreshToken()
                      .then(res => {
                        refreshTokenPromise = null;
                        return res;
                      })
                      .catch(err => {
                        refreshTokenPromise = null;                        
                        store.dispatch(logOut());
                        if (err.response.status === 400) {
                            toast.error('Invalid token.');
                        } else if (err.response.status === 404) {
                            toast.error('Session expired! Please login again.');
                        } else {
                            toast.error('Session expired! Please login again.');
                        }
                        return Promise.reject(err);
                      });
                  }

                return refreshTokenPromise.then((r: any) => {
                    localStorage.setItem('token', r.jwtToken);
                    sessionStorage.setItem('token', r.jwtToken);
                    originalConfig.withCredentials = true;
                    originalConfig.headers.Authorization = `Bearer ${r.jwtToken}`;
                    refreshTokenPromise = null;
                    return axios(originalConfig);
                    });
            }
            if (
                originalConfig.url ===
                    process.env.REACT_APP_BASE_URL + '/api/User/refresh-token' &&
                !originalConfig._retry
            ) {
                sessionStorage.removeItem('token');
                localStorage.removeItem('token');
                store.dispatch(logOut());
                return Promise.reject(error);
            }
            return Promise.reject(error);
        }
        return Promise.reject(error);
    },
);

