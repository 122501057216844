// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PFModal_btn__EF3UK {\n  width: 300px;\n  margin-left: 250px;\n  margin-bottom: 30px;\n  margin-top: 30px;\n}\n\n.PFModal_border__G7U52 {\n  border: 0px solid white;\n}\n\n.PFModal_font__hJUOr {\n  font-family: \"Bulldog\";\n}", "",{"version":3,"sources":["webpack://./src/components/UI/organisms/Modal/PFModal.module.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;AACD;;AAEA;EACC,uBAAA;AACD;;AAEA;EACC,sBAAA;AACD","sourcesContent":[".btn {\n\twidth: 300px;\n\tmargin-left: 250px;\n\tmargin-bottom: 30px;\n\tmargin-top: 30px;\n}\n\n.border {\n\tborder: 0px solid white;\n}\n\n.font {\n\tfont-family: 'Bulldog';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "PFModal_btn__EF3UK",
	"border": "PFModal_border__G7U52",
	"font": "PFModal_font__hJUOr"
};
export default ___CSS_LOADER_EXPORT___;
