import {Field, Formik} from 'formik';
import React from 'react';
import {useDispatch} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {Images} from '../../../constants/Images';
import {ResetPasswordModel} from '../../../models/ResetPasswordModel';
import {resetPassword} from '../../../store/actions/AuthActions';
import {ResetPasswordValidationScheme} from '../../../validation/ResetPasswordValidationScheme';
import {PFFormikTextInput} from '../../UI/atoms/FormikTextInput/PFFormikTextInput';
import PFPrimaryButton from '../../UI/atoms/PrimaryButton/PFPrimaryButton';
import styles from './ResetPassword.module.scss';
interface Props {}

function ResetPassword(props: Props) {
	const {} = props;
	const getInitialValues = () => {
		return {password: '', confirmPassword: '', token: ''} as ResetPasswordModel;
	};
	const params = useParams();
	const dispatch = useDispatch<any>();
	const token = params.token !== undefined ? params.token.split('=')[1] : '';
	return (
		<div className={styles.body}>
			<div className={styles.form}>
				<div className={styles.logoWrapper1}>
					<img src={Images.FourDots} alt="logo" />
				</div>
				<div className={styles.logoWrapper2}>
					<img src={Images.Performative} alt="logo-2" className={styles.logo} />
				</div>
				<p className={styles.moto}>Social works with creators.</p>
				<div className={styles.itemsWrapper}>
					<Formik
						initialValues={getInitialValues()}
						validationSchema={ResetPasswordValidationScheme}
						onSubmit={(values: any) => {
							const data: ResetPasswordModel = {
								password: values.password,
								confirmPassword: values.confirmPassword,
								token: token,
							};
							dispatch(resetPassword(data));
						}}>
						{formik => (
							<div>
								<Field
									secureTextEntry
									label="New Password"
									component={PFFormikTextInput}
									placeholder="Enter new password"
									name="password"
									type="text"
									styleForInput={styles.inputBox}
									value=""
								/>
								<Field
									secureTextEntry
									label="Confirm new Password"
									component={PFFormikTextInput}
									placeholder="Confirm new password"
									name="confirmPassword"
									type="text"
									styleForInput={styles.inputBox}
									value=""
								/>
								<li>
									<Link to="/login" className={styles.back}>
										&lt; Back to login
									</Link>
								</li>
								<li>
									<Link to="/forgot-password" className={styles.reset}>
										Resend token?
									</Link>
								</li>
								<li className={`${styles.buttonWrapper}  ${styles.formItem}`}>
									<PFPrimaryButton
										onClick={() => formik.handleSubmit()}
										text="SAVE NEW PASSWORD"
										type="submit"
									/>
								</li>
							</div>
						)}
					</Formik>
				</div>
				<div className={styles.bottomLogoWrapper}>
					<img
						src={Images.Warmstorm}
						alt="warmstorm-logo"
						className={styles.bottomLogo}
					/>
				</div>
			</div>
			<div className={styles.background}></div>
		</div>
	);
}

export default ResetPassword;
