import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CampaignBrief} from '../../../../models/CampaignBrief';
import {SelectedCreator} from '../../../../models/SelectedCreator';
import {
	changeUnsavedChanges,
	editCampaignMetaId,
	getCampaignMetaId,
} from '../../../../store/actions/CampaignActions';
import {getFinalAssetsCreatorList} from '../../../../store/actions/CreatorListActions';
import {RootState} from '../../../../store/reducers/RootReducer';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import {PFTextInput} from '../../atoms/TextInput/PFTextInput';
import PFCreatorListTable from '../../molecules/CreatorListTable/PFCreatorListTable';
import styles from './PFFinalAssets.module.scss';

interface PFFinalAssetsProps {
	campaignId: string;
}

const PFFinalAssets: React.FC<PFFinalAssetsProps> = ({campaignId}) => {
	const dispatch = useDispatch<any>();
	const currentCreatorList: Array<SelectedCreator> = useSelector(
		(state: RootState) => state.creatorList.currentCreatorList,
	);
	const metaId = useSelector(
		(state: RootState) => state.campaign.currentCampaignMetaId,
	);

	const campaign: CampaignBrief = useSelector(
		(state: RootState) => state.campaign.currentCampaign,
	);
	const hasCampaignEnded =
		campaign && campaign.campaignStatus ? campaign.campaignStatus === 4 : false;

	const [realCampaignId, setRealCampaignId] = useState('');

	const handleSaveCampaignId = () => {
		dispatch(editCampaignMetaId(campaignId.trim(), realCampaignId.trim()));
		dispatch(changeUnsavedChanges(false));
	};

	const handleCampaignIdChange = (e: string) => {
		dispatch(changeUnsavedChanges(true));
		setRealCampaignId(e);
	};

	useEffect(() => {
		dispatch(getFinalAssetsCreatorList(campaignId));
		dispatch(getCampaignMetaId(campaignId));
	}, [campaignId, dispatch]);

	return (
		<div className={styles.page}>
			<div className={styles.highlight}>Final Assets</div>
			<div className={styles.contentWrapper}>
				<div className={styles.row}>
					<PFTextInput
						label={'Campaign ID'}
						editable={!hasCampaignEnded}
						value={metaId}
						style={styles.input}
						submitOnEnter={false}
						styleForInput={styles.inputName}
						onChange={e => handleCampaignIdChange(e)}
					/>
					<div className={styles.Btn}>
						<PFPrimaryButton
							onClick={handleSaveCampaignId}
							text={'SAVE CAMPAIGN ID'}
							style={styles.saveBtn}
							disabled={hasCampaignEnded}
						/>
					</div>
				</div>

				<div className={styles.FaTWrapper}>
					<PFCreatorListTable
						users={currentCreatorList}
						isFinalAsset
						isExpandable
						campaignId={campaignId}
						hasCampaignEnded={hasCampaignEnded}
					/>
				</div>
			</div>
		</div>
	);
};

export default PFFinalAssets;
