import React, {useEffect, useState} from 'react';
import {OtherMetrics} from '../../../../constants/KPS';
import {TargetGoal} from '../../../../models/TargetGoal';
import PFGoalCard from '../../molecules/GoalCard/PFGoalCard';
import styles from './PFPerformanceMetrics.module.scss';

interface PFPerformanceMetricsProps {
	performance: Array<TargetGoal>;
}

export const PFPerformanceMetrics: React.FC<PFPerformanceMetricsProps> = ({
	performance,
}) => {
	const [targetGoals, setTargetGoals] = useState<Array<TargetGoal>>([]);
	const [otherMetrics, setOtherMetrics] = useState<Array<TargetGoal>>([]);

	useEffect(() => {
		let tg: Array<TargetGoal> = [];
		let om: Array<TargetGoal> = [];
		performance.forEach(t => {
			if (OtherMetrics.includes(t.name)) {
				om.push(t);
			} else {
				tg.push(t);
			}
		});
		setTargetGoals(tg);
		setOtherMetrics(om);
	}, [performance]);

	return (
		<div className={styles.campaignPerformance}>
			{performance && performance.length > 0 ? (
				<>
					<div className={styles.smallHighlight}>Target Goals</div>
					<div style={styleCards}>
						{targetGoals.length > 0 &&
							targetGoals.map((t, index) => {
								return (
									<div className={styles.oneCard} key={index}>
										<PFGoalCard
											targetValue={String(t.targetValue)}
											name={t.name}
											cost={t.cost}
											isPerformance
											currentValue={t.currentValue}
										/>
									</div>
								);
							})}
					</div>

					{otherMetrics.length > 0 && (
						<div className={styles.smallHighlight}>Other Metrics</div>
					)}
					<div style={styleCards}>
						{otherMetrics.map((t, index) => (
							<div className={styles.oneCard} key={index}>
								<PFGoalCard
									targetValue={String(t.currentValue)}
									name={t.name}
									cost={11}
									currentValue={t.currentValue}
								/>
							</div>
						))}
					</div>
				</>
			) : (
				<div className={styles.noTargetGoals}>There is no Target goals.</div>
			)}
		</div>
	);
};

const styleCards = {
	display: 'flex',
	flexWrap: 'wrap' as 'wrap',
};
