// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PFStepCard_card__u3K9C {\n  width: 100%;\n  height: 46px;\n  margin-top: 10px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.PFStepCard_icon__3qi3m {\n  padding-bottom: 10px;\n  padding-left: 10px;\n  padding-right: 20px;\n}\n\n.PFStepCard_text__t6Gvv {\n  font-family: \"Bulldog\";\n  font-size: 14px;\n  font-weight: 400;\n  padding-bottom: 8px;\n}\n\n.PFStepCard_split__dP9Pd {\n  display: flex;\n  flex-direction: row;\n  background-color: rgb(255, 255, 255);\n  border: 1px solid rgba(0, 0, 0, 0.08);\n  border-radius: 4px;\n}\n\n.PFStepCard_bin__OnIAq {\n  padding-top: 13px;\n  padding-right: 20px;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/atoms/StepCard/PFStepCard.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EAEA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AAAJ;;AAIA;EACI,oBAAA;EACA,kBAAA;EACA,mBAAA;AADJ;;AAIA;EACI,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AADJ;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,oCAAA;EACA,qCAAA;EACA,kBAAA;AADJ;;AAIA;EACI,iBAAA;EACA,mBAAA;EACA,eAAA;AADJ","sourcesContent":[".card{\n    width: 100%;\n    height: 46px;\n   \n    margin-top: 10px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n\n}\n\n.icon{\n    padding-bottom: 10px;\n    padding-left: 10px;\n    padding-right: 20px;\n}\n\n.text{\n    font-family: \"Bulldog\";\n    font-size: 14px;\n    font-weight: 400;\n    padding-bottom: 8px;\n}\n\n.split{\n    display: flex;\n    flex-direction: row;\n    background-color: rgba(255, 255, 255, 1);\n    border: 1px solid rgba(0, 0, 0, 0.08);\n    border-radius: 4px;\n}\n\n.bin{\n    padding-top: 13px;\n    padding-right: 20px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "PFStepCard_card__u3K9C",
	"icon": "PFStepCard_icon__3qi3m",
	"text": "PFStepCard_text__t6Gvv",
	"split": "PFStepCard_split__dP9Pd",
	"bin": "PFStepCard_bin__OnIAq"
};
export default ___CSS_LOADER_EXPORT___;
