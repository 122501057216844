import React, {useEffect, useState} from 'react';
import styles from './UsersPage.module.scss';
import 'bootstrap/dist/css/bootstrap.css';
import {useDispatch, useSelector} from 'react-redux';
import {Admin} from '../../../models/Admin';
import {Brand} from '../../../models/Brand';
import {Creator} from '../../../models/Creator';
import {
	createAdmin,
	inviteUser,
	getAdminPage,
	searchAdmins,
	searchBrands,
	searchCreators,
	setRowsPerPageAction,
	filterCreatorsAction,
	filterAdminsAction,
	filterBrandsAction,
} from '../../../store/actions/UserActions';
import {RootState} from '../../../store/reducers/RootReducer';
import PFAdminModal from '../../UI/molecules/AdminModal/PFAdminModal';
import GlobalFooter from '../../UI/molecules/GlobalFooter/GlobalFooter';
import GlobalHeader from '../../UI/molecules/globalHeader/GlobalHeader';
import Sidebar from '../../UI/molecules/sidebar/Sidebar';
import PFUserModal from '../../UI/molecules/UserModal/PFUserModal';
import PFSearchHeader from '../../UI/organisms/SearchHeader/PFSearchHeader';
import PFUsersContentTable from '../../UI/organisms/UsersContentTable/PFUsersContentTable';
import {NewUser} from '../../../models/NewUser';
import {AdminRequest} from '../../../models/AdminRequest';
import {Users} from '../../../constants/Users';
import {useParams} from 'react-router-dom';

const countMin = (val1: number, val2: number) => {
	if (val1 <= val2) return val1;
	return val2;
};

const UsersPage: React.FC = () => {
	const {type} = useParams();
	const dispatch = useDispatch<any>();
	const [clicked, setClicked] = useState(
		type === 'client' ? 'Brands' : 'Creators',
	);
	const [modalShowAdmin, setModalShowAdmin] = useState(false);
	const [modalShowBrand, setModalShowBrand] = useState(false);
	const [modalShowCreator, setModalShowCreator] = useState(false);
	const [currentFirstRow, setCurrentFirstRow] = useState(1);
	const [currentLastRow, setCurrentLastRow] = useState(10);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [isSearched, setIsSearched] = useState(false);
	const [searchValue, setSearchValue] = useState('');

	const creators: Array<Creator> = useSelector(
		(state: RootState) => state.user.creators,
	);
	const admins: Array<Admin> = useSelector(
		(state: RootState) => state.user.admins,
	);
	const brands: Array<Brand> = useSelector(
		(state: RootState) => state.user.brands,
	);
	const totalData: number = useSelector(
		(state: RootState) => state.user.totalData,
	);
	const filterKey: string = useSelector(
		(state: RootState) => state.user.filterKey,
	);
	const sortOrder: string = useSelector(
		(state: RootState) => state.user.sortOrder,
	);
	useEffect(() => {
		if (type === 'client') {
			handleClick(Users.BRANDS);
		} else {
			handleClick(Users.CREATORS);
		}
	}, []);

	useEffect(() => {
		setCurrentLastRow(countMin(rowsPerPage, totalData));
	}, [totalData]);

	const handleClick = (val: string) => {
		setTable(val, 1);
		setClicked(val);
		setCurrentPage(1);
		setCurrentFirstRow(1);
		setCurrentLastRow(countMin(rowsPerPage, totalData));
	};

	const addAdmin = (fullName: string, email: string) => {
		const data: AdminRequest = {
			email: email,
			fullName: fullName,
		};
		setModalShowAdmin(false);
		dispatch(createAdmin(data));
	};

	const addBrand = (email: string) => {
		const data: NewUser = {
			email: email,
		};
		setModalShowBrand(false);
		dispatch(inviteUser(data));
	};

	const addCreator = (email: string) => {
		const data: NewUser = {
			email: email,
		};
		setModalShowCreator(false);
		dispatch(inviteUser(data));
	};
	const handleSelectChange = (val: string) => {
		const newVal: number = Number(val) > totalData ? totalData : Number(val);
		setCurrentLastRow(Number(newVal));
		setCurrentFirstRow(1);
		setRowsPerPage(Number(val));
		dispatch(setRowsPerPageAction(Number(val)));
	};

	const handleRightClick = () => {
		var newPage = currentPage + 1;
		var newLastRow = countMin(currentLastRow + rowsPerPage, totalData);
		var newFirstRow = currentLastRow + 1;
		setCurrentFirstRow(newFirstRow);
		setCurrentLastRow(newLastRow);
		setCurrentPage(newPage);
		if (!isSearched) {
			setTable(clicked, newPage);
		} else {
			searchTable(clicked, searchValue, newPage);
		}
	};

	const handleLeftClick = () => {
		var newPage = currentPage - 1;
		var newLastRow = currentFirstRow - 1;
		var newFirstRow =
			currentFirstRow - rowsPerPage <= 0 ? 1 : currentFirstRow - rowsPerPage;
		setCurrentFirstRow(newFirstRow);
		setCurrentLastRow(newLastRow);
		setCurrentPage(newPage);
		if (!isSearched) {
			setTable(clicked, newPage);
		} else {
			searchTable(clicked, searchValue, newPage);
		}
	};

	const handleSearch = (value: string, page?: number) => {
		if (value !== '') {
			setIsSearched(true);
			setSearchValue(value);
			searchTable(clicked, value, 1);
			setCurrentPage(1);
			setCurrentFirstRow(1);
			setCurrentLastRow(countMin(rowsPerPage, totalData));
		} else {
			handleClick(clicked);
		}
	};

	const searchTable = (clicked: string, value: string, page?: number) => {
		if (clicked === Users.ADMINS) {
			dispatch(
				searchAdmins({
					value: value,
					role: 0,
					pageNumber: page ?? currentPage,
					pageSize: rowsPerPage,
				}),
			);
		} else if (clicked === Users.BRANDS) {
			dispatch(
				searchBrands({
					value: value,
					role: 2,
					pageNumber: page ?? currentPage,
					pageSize: rowsPerPage,
				}),
			);
		} else if (clicked === Users.CREATORS) {
			setTimeout(() => {
				dispatch(
					searchCreators({
						value: value,
						role: 3,
						pageNumber: page ?? currentPage,
						pageSize: rowsPerPage,
					}),
				);
			}, 500);
		}
	};

	const setTable = (val: string, page?: number) => {
		if (val === Users.ADMINS) {
			if (filterKey === '') {
				dispatch(
					getAdminPage({
						pageNumber: page ?? currentPage,
						pageSize: rowsPerPage,
					}),
				);
			} else {
				dispatch(
					filterAdminsAction(
						filterKey,
						sortOrder.toUpperCase(),
						page ?? currentPage,
						rowsPerPage,
					),
				);
			}
		} else if (val === Users.BRANDS) {
			if (filterKey === '') {
				dispatch(
					filterBrandsAction('status', 'ASC', page ?? currentPage, rowsPerPage),
				);
			} else {
				dispatch(
					filterBrandsAction(
						filterKey,
						sortOrder.toUpperCase(),
						page ?? currentPage,
						rowsPerPage,
					),
				);
			}
		} else if (val === Users.CREATORS) {
			if (filterKey === '') {
				dispatch(
					filterCreatorsAction(
						'status',
						'ASC',
						page ?? currentPage,
						rowsPerPage,
					),
				);
			} else {
				dispatch(
					filterCreatorsAction(
						filterKey,
						sortOrder.toUpperCase(),
						page ?? currentPage,
						rowsPerPage,
					),
				);
			}
		}
	};

	useEffect(() => {
		handleClick(clicked);
	}, [rowsPerPage]);

	const setModalShow = () => {
		if (clicked === Users.ADMINS) {
			setModalShowAdmin(true);
		} else if (clicked === Users.BRANDS) {
			setModalShowBrand(true);
		} else if (clicked === Users.CREATORS) {
			setModalShowCreator(true);
		}
	};

	return (
		<div className={styles.page}>
			<div className={styles.sidebarWrapper}>
				<Sidebar activePage="users" />
			</div>
			<div className={styles.contentWrapper}>
				<div className={styles.headerWrapper}>
					<GlobalHeader label="Users" onClick={setModalShow} />
				</div>
				<div className={styles.searchWrapper}>
					<PFSearchHeader
						onClick={handleClick}
						handleSearch={handleSearch}
						label="Users"
						selectedTab={clicked}
					/>
				</div>
				<div className={styles.FaTWrapper}>
					<div className={styles.tableWrapper}>
						{
							<PFUsersContentTable
								clicked={clicked}
								creators={creators}
								admins={admins}
								brands={brands}
							/>
						}
					</div>
					<div className={styles.footerWrapper}>
						<GlobalFooter
							firstRow={currentFirstRow}
							lastRow={currentLastRow}
							disabledLeft={currentFirstRow === 1 ? true : false}
							disabledRight={currentLastRow >= totalData ? true : false}
							totalData={totalData}
							handleRightClick={handleRightClick}
							handleLeftClick={handleLeftClick}
							rowsPerPage={rowsPerPage}
							handleSelectChange={handleSelectChange}
						/>
					</div>
				</div>
			</div>
			<PFAdminModal
				show={modalShowAdmin}
				onClick={addAdmin}
				onHide={() => setModalShowAdmin(false)}
				label={Users.ADMIN}
			/>
			<PFUserModal
				show={modalShowBrand}
				onClick={addBrand}
				onHide={() => setModalShowBrand(false)}
				label={Users.BRAND}
			/>
			<PFUserModal
				show={modalShowCreator}
				onClick={addCreator}
				onHide={() => setModalShowCreator(false)}
				label={Users.CREATOR}
			/>
		</div>
	);
};

export default UsersPage;
