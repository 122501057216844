import {useEffect, useState} from 'react';
import {Option} from 'react-multi-select-component';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {CampaignModals} from '../../../../constants/CampaignModals';
import {Icons} from '../../../../constants/Icons';
import {Creator} from '../../../../models/Creator';
import {SelectedCreator} from '../../../../models/SelectedCreator';
import {
	changeStatuses,
	editCreatorList,
	filterCreatorList,
	getCreatorList,
} from '../../../../store/actions/CreatorListActions';
import {RootState} from '../../../../store/reducers/RootReducer';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import PFCreatorListModal from '../../molecules/CreatorListModal/PFCreatorListModal';
import PFCreatorListTable from '../../molecules/CreatorListTable/PFCreatorListTable';
import PFCreatorTabs, {
	mapStatus,
} from '../../molecules/CreatorTabs/PFCreatorTabs';
import styles from './PFCreatorList.module.scss';
import {changeUnsavedChanges} from '../../../../store/actions/CampaignActions';
import {CampaignBrief} from '../../../../models/CampaignBrief';
import {Dots} from 'react-activity';

export interface ChangedStatus {
	id: number;
	status: number;
}

const PFCreatorList = () => {
	const dispatch = useDispatch<any>();
	const id = useParams();
	const creators: Array<Creator> = useSelector(
		(state: RootState) => state.user.allCreators,
	);
	const currentCreatorList: Array<SelectedCreator> = useSelector(
		(state: RootState) => state.creatorList.currentCreatorList,
	);
	const isLoading: boolean = useSelector(
		(state: RootState) => state.creatorList.isLoading,
	);

	const [availableCreators, setAvailableCreators] = useState<Array<Creator>>(
		[],
	);
	const [changedStatuses, setChangedStatuses] = useState<Array<ChangedStatus>>(
		[],
	);
	const [showModal, setShowModal] = useState(false);
	const [newCreators, setNewCreators] = useState<Array<SelectedCreator>>([]);
	const [tableKey, setTableKey] = useState<number>();
	const campaign: CampaignBrief = useSelector(
		(state: RootState) => state.campaign.currentCampaign,
	);

	const hasCampaignEnded =
		campaign && campaign.campaignStatus ? campaign.campaignStatus === 4 : false;

	useEffect(() => {
		if (id.id !== undefined) {
			dispatch(getCreatorList(id.id));
		}
	}, [creators, dispatch, id.id]);

	useEffect(() => {
		if (currentCreatorList.length === 0) setAvailableCreators(creators);
		else {
			setAvailableCreators(
				creators.filter(
					x1 => !currentCreatorList.some(x2 => x2.email === x1.email),
				),
			);
		}
	}, [creators, currentCreatorList]);

	const handleOnStatusChange = (id: number, value: string) => {
		setChangedStatuses(prev => [...prev, {id: id, status: mapStatus(value)}]);
		dispatch(changeUnsavedChanges(true));
	};

	const handleOnCreatorsAdd = (cr: Array<Option>) => {
		let list: Array<SelectedCreator> = [];
		cr.forEach(c => {
			let creator = {
				id: c.value.id,
				Name: c.value?.Name,
				email: c.value.email,
			};
			list.push({
				id: c.value.id,
				fullName: c.value?.Name,
				email: c.value.email,
				phoneNumber: c.value.phoneNumber,
				tikTokHandle: c.value.tikTokHandle,
				instagramHandle: c.value.instagramHandle,
				status: 0,
			});
			setAvailableCreators(x => x.filter(cr => cr.email !== creator.email));
		});
		setNewCreators(prev => [...prev, ...list]);

		setShowModal(false);
	};

	const handleOnCreatorRemove = (email: string) => {
		setNewCreators(newCreators.filter(c => c.email !== email));
		const newAvailable = creators.filter(c => c.email === email);
		setAvailableCreators(prev => [...prev, ...newAvailable]);
	};

	const handleOnSendCreatorsForApproval = () => {
		let ids: Array<number> = [];
		newCreators.forEach(creator => {
			ids.push(creator.id);
		});

		if (id.id) {
			dispatch(editCreatorList(id.id, ids));
			setNewCreators([]);
		}
	};

	const handleOnApplyStatusChanges = () => {
		if (id.id) {
			dispatch(changeStatuses(id.id, changedStatuses));
			setChangedStatuses([]);
			dispatch(changeUnsavedChanges(false));
		}
	};

	const handleOnSortCreators = (statusNum: number) => {
		if (id.id) {
			if (statusNum === -1) {
				dispatch(getCreatorList(id.id));
			} else {
				dispatch(filterCreatorList(id.id, statusNum));
			}
		}
	};

	const handleDiscardStatusChanges = () => {
		setChangedStatuses([]);
		setTableKey(Math.random());
	};

	useEffect(() => {
		CheckChanges();
	}, [newCreators]);

	const CheckChanges = () => {
		if (newCreators.length > 0) {
			dispatch(changeUnsavedChanges(true));
		} else {
			dispatch(changeUnsavedChanges(false));
		}
	};

	if (currentCreatorList.length === 0 && isLoading) {
		return (
			<div className={styles.dots}>
				<Dots size={40} color={'#3560FF'} />
			</div>
		);
	}

	return (
		<div className={styles.page}>
			<div className={styles.highlight}>
				<span className={styles.title}>Creator List</span>
				<PFCreatorTabs onClick={handleOnSortCreators} />
			</div>
			<div className={styles.contentWrapper}>
				<div className={styles.FaTWrapper}>
					<PFCreatorListTable
						users={currentCreatorList}
						tableKey={tableKey}
						onStatusChange={handleOnStatusChange}
						hasCampaignEnded={hasCampaignEnded}
					/>
				</div>
			</div>
			<div className={styles.bttmRowList}>
				<div className={styles.Btn}>
					<PFPrimaryButton
						onClick={handleDiscardStatusChanges}
						text={'DISCARD'}
						style={styles.discardBtn}
						disabled={changedStatuses.length < 1}
					/>
				</div>

				<div className={styles.Btn}>
					<PFPrimaryButton
						disabled={changedStatuses.length < 1}
						isLoading={isLoading}
						onClick={handleOnApplyStatusChanges}
						text={'APPLY STATUS CHANGES'}
						style={
							changedStatuses.length > 0 ? styles.saveBtn : styles.disabled
						}
					/>
				</div>
			</div>

			<div className={styles.addCreatorHighlight}>
				Add Creators
				{!hasCampaignEnded && (
					<div className={styles.icons} onClick={() => setShowModal(true)}>
						{Icons.PLUS}
					</div>
				)}
			</div>
			<div className={styles.contentWrapper}>
				<div className={styles.FaTWrapper}>
					<PFCreatorListTable
						users={newCreators}
						newCreator={true}
						onRemove={handleOnCreatorRemove}
						hasCampaignEnded={hasCampaignEnded}
					/>
				</div>
			</div>
			<div className={styles.bttmRow}>
				<div className={styles.Btn}>
					<PFPrimaryButton
						onClick={() => {
							setNewCreators([]);
						}}
						text={'DISCARD'}
						style={styles.discardBtn}
						disabled={newCreators.length === 0}
					/>
				</div>
				<div className={styles.Btn}>
					<PFPrimaryButton
						disabled={newCreators.length === 0}
						onClick={handleOnSendCreatorsForApproval}
						text={'SEND CREATORS FOR APPROVAL'}
						style={newCreators.length > 0 ? styles.saveBtn : styles.disabled}
					/>
				</div>
			</div>

			<PFCreatorListModal
				onClick={handleOnCreatorsAdd}
				creators={availableCreators}
				label={CampaignModals.ADD_CREATOR}
				onHide={() => setShowModal(false)}
				show={showModal}
			/>
		</div>
	);
};

export default PFCreatorList;
