// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PFChangesModal_btn__wpkE8 {\n  width: 150px;\n  margin: 10px 20px 0px 20px;\n}\n\n.PFChangesModal_red__K3d5v {\n  background-color: red;\n  border: 2px solid red;\n}\n.PFChangesModal_red__K3d5v:hover {\n  cursor: pointer;\n  background-color: rgb(255, 73, 73);\n  border: 2px solid rgb(255, 73, 73);\n}\n\n.PFChangesModal_body__ImQS6 {\n  width: 420px;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/molecules/ChangesModal/PFChangesModal.module.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,0BAAA;AACD;;AAEA;EACI,qBAAA;EACA,qBAAA;AACJ;AAAI;EACI,eAAA;EACA,kCAAA;EACA,kCAAA;AAER;;AAEA;EACI,YAAA;AACJ","sourcesContent":[".btn {\n\twidth: 150px;\n\tmargin: 10px 20px 0px 20px;\n}\n\n.red{\n    background-color: red;\n    border: 2px solid red;\n    &:hover {\n        cursor: pointer;\n        background-color: rgb(255, 73, 73);\n        border: 2px solid rgb(255, 73, 73);\n    }\n}\n\n.body{\n    width: 420px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "PFChangesModal_btn__wpkE8",
	"red": "PFChangesModal_red__K3d5v",
	"body": "PFChangesModal_body__ImQS6"
};
export default ___CSS_LOADER_EXPORT___;
