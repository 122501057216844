import {toast} from 'react-toastify';
import {Budget} from '../../models/Budget';
import {Campaign} from '../../models/Campaign';
import {CampaignBrief} from '../../models/CampaignBrief';
import {CampaignImage} from '../../models/CampaignImage';
import {CampaignPage} from '../../models/CampaignPage';
import {CreateCampaignRequest} from '../../models/CreateCampaignRequest';
import {DateLive} from '../../models/Date';
import {SearchRequest} from '../../models/SearchRequest';
import {SelectedCreator} from '../../models/SelectedCreator';
import {TablePageModel} from '../../models/TablePageModel';
import {TargetGoal} from '../../models/TargetGoal';
import CampaignServices from '../../services/CampaignServices';
import ContentPlanService from '../../services/ContentPlanService';
import {
	addCampaign,
	setCampaignMetaId,
	setCampaignPage,
	setCampaignStatus,
	setCurrentCampaign,
	setCurrentCampaignBrief,
	setLoading,
	setCampaignUploading,
	setUnsavedChanges,
} from '../reducers/CampaignReducer';
import {setContentPlan} from '../reducers/ContentPlanReducer';
import {updateCreatorList} from '../reducers/CreatorListReducer';

export const getCampaignPage = (data: TablePageModel) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		CampaignServices.getCampaignPage(data).then((res: CampaignPage) => {
			dispatch(setCampaignPage(res));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while reading campaigns from database');
		dispatch(setLoading(false));
	}
};

export const getActiveCampaignPage =
	(data: TablePageModel) => (dispatch: any) => {
		dispatch(setLoading(true));
		try {
			CampaignServices.getActiveCampaignPage(data).then((res: CampaignPage) => {
				dispatch(setCampaignPage(res));
				dispatch(setLoading(false));
			});
		} catch (e) {
			toast.error('Error while reading active campaigns from database');
			dispatch(setLoading(false));
		}
	};

export const getApprovedCampaignPage =
	(data: TablePageModel) => (dispatch: any) => {
		dispatch(setLoading(true));
		try {
			CampaignServices.getApprovedCampaignPage(data).then(
				(res: CampaignPage) => {
					dispatch(setCampaignPage(res));
					dispatch(setLoading(false));
				},
			);
		} catch (e) {
			toast.error('Error while reading active campaigns from database');
			dispatch(setLoading(false));
		}
	};

export const getDeclinedCampaignPage =
	(data: TablePageModel) => (dispatch: any) => {
		dispatch(setLoading(true));
		try {
			CampaignServices.getDeclinedCampaignPage(data).then(
				(res: CampaignPage) => {
					dispatch(setCampaignPage(res));
					dispatch(setLoading(false));
				},
			);
		} catch (e) {
			toast.error('Error while reading active campaigns from database');
			dispatch(setLoading(false));
		}
	};
export const getPendingCampaignPage =
	(data: TablePageModel) => (dispatch: any) => {
		dispatch(setLoading(true));
		try {
			CampaignServices.getPendingCampaignPage(data).then(
				(res: CampaignPage) => {
					dispatch(setCampaignPage(res));
					dispatch(setLoading(false));
				},
			);
		} catch (e) {
			toast.error('Error while reading pending campaings from database');
			dispatch(setLoading(false));
		}
	};

export const getEndedCampaignPage =
	(data: TablePageModel) => (dispatch: any) => {
		dispatch(setLoading(true));
		try {
			CampaignServices.getEndedCampaignPage(data).then((res: CampaignPage) => {
				dispatch(setCampaignPage(res));
				dispatch(setLoading(false));
			});
		} catch (e) {
			toast.error('Error while reading ended campaings from database');
			dispatch(setLoading(false));
		}
	};

export const searchCampaignPage = (data: SearchRequest) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		CampaignServices.searchCampaignPage(data).then((res: CampaignPage) => {
			dispatch(setCampaignPage(res));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while reading campaings from database');
		dispatch(setLoading(false));
	}
};

export const searchActiveCampaignPage =
	(data: SearchRequest) => (dispatch: any) => {
		dispatch(setLoading(true));
		try {
			CampaignServices.searchActiveCampaignPage(data).then(
				(res: CampaignPage) => {
					dispatch(setCampaignPage(res));
					dispatch(setLoading(false));
				},
			);
		} catch (e) {
			toast.error('Error while reading campaings from database');
			dispatch(setLoading(false));
		}
	};

export const searchPendingCampaignPage =
	(data: SearchRequest) => (dispatch: any) => {
		dispatch(setLoading(true));
		try {
			CampaignServices.searchPendingCampaignPage(data).then(
				(res: CampaignPage) => {
					dispatch(setCampaignPage(res));
					dispatch(setLoading(false));
				},
			);
		} catch (e) {
			toast.error('Error while reading campaings from database');
			dispatch(setLoading(false));
		}
	};

export const searchEndedCampaignPage =
	(data: SearchRequest) => (dispatch: any) => {
		dispatch(setLoading(true));
		try {
			CampaignServices.searchEndedCampaignPage(data).then(
				(res: CampaignPage) => {
					dispatch(setCampaignPage(res));
					dispatch(setLoading(false));
				},
			);
		} catch (e) {
			toast.error('Error while reading campaings from database');
			dispatch(setLoading(false));
		}
	};

export const createCampaign =
	(data: CreateCampaignRequest, nav: any) => (dispatch: any) => {
		dispatch(setCampaignUploading(true));

		CampaignServices.createCampaign(data)
			.then(async (res: Campaign) => {
				dispatch(addCampaign(res));
				await CampaignServices.createFile(res.id, data.campaignBriefFile).then(
					() => {},
				);
				if (!data.campaignImages.entries().next().done) {
					await CampaignServices.createImages(res.id, data.campaignImages).then(
						() => {},
					);
				}

				if (!data.contentPlanFile.entries().next().done) {
					data.contentPlanFile.append('Id', res.id.toString());
					await ContentPlanService.createContentPlan(data.contentPlanFile).then(
						res => {
							dispatch(setContentPlan(res));
						},
					);
				}
				dispatch(setCampaignUploading(false));
				nav('/campaigns');
				toast.success('Campaign added successfully!');
			})
			.catch(() => {
				dispatch(setCampaignUploading(false));
				toast.error('Something went wrong. We could not create campaign.');
			});
	};

export const getCampaign = (id: string) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		CampaignServices.getCampaignBrief(id).then((res: CampaignBrief) => {
			dispatch(setCurrentCampaign(res));
			dispatch(setLoading(false));
		});
	} catch (e) {
		toast.error('Error while reading campaign from database');
		dispatch(setLoading(false));
	}
};

export const deleteCampaignBrief =
	(id: number, kindOfFile: string) => (dispatch: any) => {
		dispatch(setLoading(true));
		try {
			CampaignServices.deleteCampaignBrief(id, kindOfFile).then((res: any) => {
				dispatch(setCurrentCampaignBrief(''));
				dispatch(setLoading(false));
			});
		} catch (e) {
			toast.error('Error while reading campaign from database');
			dispatch(setLoading(false));
		}
	};

export const deleteCurrentCampaign = () => (dispatch: any) => {
	const initialState = {
		id: -1,
		name: '',
		clientId: -1,
		campaignBrief: '',
		campaignBriefUrl: '',
		targetGoals: new Array<TargetGoal>(),
		campaignSteps: new Array<number>(),
		campaignImages: new Array<CampaignImage>(),
		budgets: new Array<Budget>(),
		dates: new Array<DateLive>(),
		liveMarkets: new Array<string>(),
	};
	dispatch(setLoading(true));
	dispatch(setCurrentCampaign(initialState));
	dispatch(setLoading(false));
};

export const editCampaign =
	(
		data: CreateCampaignRequest,
		campaignID: number,
		deletedImages: Array<string> | undefined,
	) =>
	(dispatch: any) => {
		dispatch(setCampaignUploading(true));
		try {
			if (deletedImages && deletedImages?.length > 0) {
				CampaignServices.deleteCampaignImages(campaignID, deletedImages);
			}
			CampaignServices.updateCampaignBrief(campaignID, data)
				.then(() => {
					toast.success('Campaign successfully saved!');
				})
				.catch(() => {
					toast.error(`Something went wrong. We couldn't save the campaign.`);
				});

			if (!data.campaignImages.entries().next().done) {
				CampaignServices.updateCampaignImage(campaignID, data.campaignImages)
					.then(() => {
						toast.success('Campaign image successfully saved!');
					})
					.catch(() => {
						toast.success(`Something went wrong. We couldn't save the image.`);
					});
			}
			if (!data.campaignBriefFile.entries().next().done) {
				CampaignServices.updateCampaignBriefFile(
					campaignID,
					data.campaignBriefFile,
				)
					.then(() => {
						toast.success('Campaign brief file successfully saved!');
					})
					.catch(() => {
						toast.error(
							`Something went wrong. We couldn't save the campaign brief file.`,
						);
					});
			}

			if (!data.contentPlanFile.entries().next().done) {
				data.contentPlanFile.append('Id', campaignID.toString());
				ContentPlanService.editContentPlan(data.contentPlanFile)
					.then(res => {
						dispatch(setContentPlan(res));
						toast.success('Content plan successfully changed!');
					})
					.catch(() => {
						toast.error('Error while editing content plan.');
					});
			}
			dispatch(setCampaignUploading(false));
		} catch (e) {
			toast.error('Error when creating campaign');
			dispatch(setCampaignUploading(false));
		}
	};

export const getCampaignMetaId = (campaignId: string) => (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		CampaignServices.getCampaignMetaId(campaignId).then(res => {
			dispatch(setCampaignMetaId(res.metaCampaignId));
			dispatch(setLoading(false));
		});
	} catch (e) {
		dispatch(setLoading(false));
	}
};

export const editCampaignMetaId =
	(campaignId: string, metaId: string) => (dispatch: any) => {
		dispatch(setLoading(true));

		CampaignServices.editCampaignMetaId(campaignId, metaId)
			.then(() => {
				dispatch(setCampaignMetaId(metaId));
				dispatch(setLoading(false));
				toast.success('Campaign ID successfully added!');
			})
			.catch(e => {
				if (e.response.status == 409) {
					dispatch(setLoading(false));
					toast.error('Given Meta campaign ID already exists!');
				} else {
					dispatch(setLoading(false));
					toast.error('Something went wrong. Adding Meta Campaign ID failed!');
				}
			});
	};

export const changeCampaignStatus =
	(campaignId: number, status: number) => (dispatch: any) => {
		dispatch(setLoading(true));
		CampaignServices.changeCampaignStatus(campaignId.toString(), status)
			.then((res: Array<SelectedCreator>) => {
				dispatch(updateCreatorList(res));
				dispatch(setCampaignStatus(status));
				dispatch(setLoading(false));
				toast.success('Campaign status successfully changed!');
			})
			.catch(() => {
				dispatch(setLoading(false));
				toast.error(
					'Something went wrong. Campaign status could not be changed, try again.',
				);
			});
	};

export const changeUnsavedChanges = (condition: boolean) => (dispatch: any) => {
	dispatch(setLoading(true));
	dispatch(setUnsavedChanges(condition));
	dispatch(setLoading(false));
};
