import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Icons} from '../../../../constants/Icons';
import {TargetGoal} from '../../../../models/TargetGoal';
import {RootState} from '../../../../store/reducers/RootReducer';
import {PFPerformanceMetrics} from '../../organisms/PerformanceMetrics/PFPerformanceMetrics';
import {PFFinalAssetExpandedBox} from '../FinalAssetExpandedBox/PFFinalAssetExpandedBox';
import styles from './PFExpandableTableRow.module.scss';

interface PFExpandableTableRowProps {
	fullName?: string;
	facebookLink?: string;
	instagramLink?: string;
	tiktokLink?: string;
	email?: string;
	phoneNumber?: string;
	status?: string;
	id: number;
	campaignId: string;
	isFinalAsset: boolean;
	performance?: Array<TargetGoal>;
	editable?: boolean;
}

export const PFExpandableTableRow: React.FC<PFExpandableTableRowProps> = ({
	fullName,
	facebookLink,
	instagramLink,
	tiktokLink,
	email,
	phoneNumber,
	status,
	campaignId,
	id,
	isFinalAsset,
	performance,
	editable = true,
}) => {
	const instagramValid = instagramLink !== '' && instagramLink !== null;
	const tiktokValid = tiktokLink !== '' && tiktokLink !== null;
	const [expanded, setExpanded] = useState(false);

	const handleInstagramLink = () => {
		window.open(`https://www.instagram.com/${instagramLink}/?hl=en`);
	};
	const handleTiktokLink = () => {
		window.open(`https://www.tiktok.com/@${tiktokLink}?lang=en`);
	};
	const handleOnExpand = () => {
		setExpanded(!expanded);
	};
	const metaCampaignId = useSelector(
		(state: RootState) => state.campaign.currentCampaignMetaId,
	);
	return (
		<>
			<tr className={styles.tr} onClick={handleOnExpand}>
				<td className={`${styles.label}`}>
					<span
						className={`${expanded ? styles.rotate : styles.right}`}
						onClick={handleOnExpand}>
						{Icons.RIGHT}
					</span>
					{fullName}
				</td>
				<td>
					<span
						title="Open profile on Instagram"
						className={instagramValid ? styles.icons : styles.grayIcons}
						style={stylesObject.instagram}
						onClick={instagramValid ? handleInstagramLink : () => {}}>
						{instagramValid ? Icons.INSTAGRAM : Icons.INSTAGRAM_GRAY}
					</span>
					<span
						title="Open profile on TikTok"
						className={tiktokValid ? styles.icons : styles.grayIcons}
						style={stylesObject.tiktok}
						onClick={instagramValid ? handleTiktokLink : () => {}}>
						{tiktokValid ? Icons.TIKTOK : Icons.TIKTOK_GRAY}
					</span>
				</td>
				<td>{email}</td>
				<td>{phoneNumber}</td>
				<td>
					<span
						style={
							status === 'Declined' ||
							status === 'Rejected' ||
							status === 'Not Interested'
								? stylesObject.red
								: status === 'Pending'
								? stylesObject.orange
								: stylesObject.green
						}>
						{status}
					</span>
				</td>
			</tr>

			{expanded && fullName && (
				<tr className={styles.boxTr}>
					<td colSpan={5} className={styles.expandedBox}>
						{isFinalAsset ? (
							<PFFinalAssetExpandedBox
								creatorId={id.toString()}
								creatorName={fullName}
								campaignId={campaignId}
								metaCampaignId={metaCampaignId}
								editable={editable}
							/>
						) : (
							performance && <PFPerformanceMetrics performance={performance} />
						)}
					</td>
				</tr>
			)}
		</>
	);
};

const stylesObject = {
	red: {
		color: 'rgba(234, 67, 53, 1)',
	},
	orange: {
		color: 'rgba(251, 139, 2, 1)',
	},
	green: {
		color: 'rgba(104, 204, 88, 1)',
	},
	pending: {
		paddingRight: '15px',
	},
	facebook: {
		float: 'left' as 'left',
		paddingRight: '12px',
		paddingBottom: '1px',
	},
	instagram: {
		float: 'left' as 'left',
		paddingRight: '12px',
	},
	tiktok: {
		float: 'left' as 'left',
		paddingTop: '-1px',
	},
};

export default PFExpandableTableRow;
