import {ErrorMessage} from 'formik';
import React, {useEffect, useState} from 'react';
import {ReactComponent as EyeIcon} from '../../../../assets/icons/eye.svg';
import {ReactComponent as SlashedEyeIcon} from '../../../../assets/icons/eye_closed.svg';
import {ReactComponent as InputError} from '../../../../assets/icons/input_error.svg';
import {FormikField, FormikForm} from '../../../../models/Formik';
import styles from './PFFormikTextInput.module.scss';

interface PFFormikTextInputProps {
	field: FormikField;
	form: FormikForm;
	label: string;
	editable: boolean;
	placeholder?: string;
	secureTextEntry?: boolean;
	textArea?: boolean;
	style?: string;
	styleForErrorLabel?: string;
	styleForInput?: string;
	styleForErrorIcon?: string;
	value: string;
	number?: boolean;
}

export const PFFormikTextInput: React.FC<PFFormikTextInputProps> = props => {
	const {
		field: {name, onBlur, onChange},
		form: {errors, touched, setFieldTouched},
		label,
		editable = true,
		placeholder,
		secureTextEntry,
		textArea,
		style = {},
		styleForInput = {},
		styleForErrorLabel = {},
		styleForErrorIcon = {},
		value,
		number,
	} = props;

	const [passwordShown, setPasswordShown] = useState(false);
	const [error, setError] = useState(errors[name] && touched[name]);

	useEffect(() => {
		setError(errors[name] && touched[name]);
	}, [errors, name, touched]);

	const handleSecureTextEntry = () => {
		setPasswordShown(!passwordShown);
	};

	return (
		<div>
			<label className={`${styles.label}  ${style}`}>{label}</label>
			<div>
				{textArea ? (
					<textarea
						placeholder={placeholder}
						name={name}
						disabled={!editable}
						defaultValue={value as string}
						autoCapitalize="none"
						className={
							!error
								? `${styles.input} ${styleForInput} ${styles.textArea}`
								: `${styles.inputErrorTextarea} ${styleForInput}`
						}
						onChange={text => onChange(name)(text)}
						onBlur={() => {
							setFieldTouched(name);
							onBlur(name);
						}}
					/>
				) : (
					<input
						placeholder={placeholder}
						name={name}
						disabled={!editable}
						defaultValue={value as string}
						autoCapitalize="none"
						className={
							!error
								? `${styles.input} ${styleForInput} `
								: `${styles.inputError} ${styleForInput}`
						}
						type={
							secureTextEntry && !passwordShown
								? 'password'
								: number
								? 'number'
								: 'text'
						}
						onChange={text => onChange(name)(text)}
						onBlur={() => {
							setFieldTouched(name);
							onBlur(name);
						}}
					/>
				)}
				{name === 'email' && error && (
					<span
						style={stylesObject.errorIconContainer}
						className={`${styleForErrorIcon}`}>
						<InputError />
					</span>
				)}
				{secureTextEntry && (
					<span
						style={stylesObject.eyeIconContainer}
						onClick={handleSecureTextEntry}>
						{passwordShown ? (
							<SlashedEyeIcon width={20} height={16} />
						) : (
							<EyeIcon width={20} height={13} />
						)}
					</span>
				)}
			</div>
			<ErrorMessage
				component="div"
				name={name}
				className={`${styles.error} ${styleForErrorLabel}`}
			/>
		</div>
	);
};

const stylesObject = {
	eyeIconContainer: {
		position: 'absolute' as 'absolute',
		marginLeft: -30,
		marginTop: 10,
		cursor: 'pointer',
	},
	errorIconContainer: {
		position: 'absolute' as 'absolute',
		marginTop: 10,
		marginLeft: -30,
	},
};
