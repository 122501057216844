import {Navigate} from 'react-router-dom';

export type ProtectedRouteProps = {
	isAuth: boolean;
	authPath: string;
	outlet: JSX.Element;
};

export default function ProtectedRoute({
	isAuth: isAuthenticated,
	authPath: authenticationPath,
	outlet,
}: ProtectedRouteProps) {
	if (isAuthenticated) {
		return outlet;
	} else {
		return <Navigate to={{pathname: authenticationPath}} />;
	}
}
