import React from 'react';
import styles from './PFSearchHeader.module.scss';
import PFSearchInput from '../../atoms/SearchInput/PFSearchInput';
import PFTabs from '../../molecules/Tabs/PFTabs';
import PFCampaignsTabs from '../../molecules/CampaignsTabs/PFCampaignsTabs';

interface PFSearchHeaderProps {
	onClick: (retVal: string) => void;
	handleSearch: (value: string) => void;
	label: string;
	selectedTab?: string;
}

const PFSearchHeader: React.FC<PFSearchHeaderProps> = ({
	onClick,
	handleSearch,
	label,
	selectedTab,
}) => {
	return (
		<div className={styles.div}>
			<div className={styles.headerWrapper}>
				<div className={`${styles.header}`}>
					<div className={`${styles.search}`}>
						<PFSearchInput
							onClick={handleSearch}
							handleSearch={handleSearch}
							submitOnEnter={true}
						/>
					</div>
					<div>
						{label === 'Users' ? (
							<PFTabs onClick={onClick} selected={selectedTab} />
						) : (
							<PFCampaignsTabs onClick={onClick} />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PFSearchHeader;
