// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PFAdId_text__IWJsI {\n  font-size: 14px;\n  font-family: \"Bulldog\";\n  line-height: 14px;\n  font-weight: 700;\n}\n\n.PFAdId_container__X7IzR {\n  background-color: rgba(53, 96, 255, 0.05);\n  border-radius: 4px;\n  width: -moz-fit-content;\n  width: fit-content;\n  padding-left: 15px;\n  padding-right: 15px;\n  margin-right: 10px;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n  height: 40px;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n}\n\n.PFAdId_x__IIXre {\n  margin-left: 8px;\n  margin-bottom: 2px;\n}\n.PFAdId_x__IIXre:hover {\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/atoms/AdId/PFAdId.module.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,sBAAA;EACA,iBAAA;EACA,gBAAA;AACD;;AAEA;EACC,yCAAA;EACA,kBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;AACD;;AAEA;EACC,gBAAA;EACA,kBAAA;AACD;AAAC;EACC,eAAA;AAEF","sourcesContent":[".text {\n\tfont-size: 14px;\n\tfont-family: 'Bulldog';\n\tline-height: 14px;\n\tfont-weight: 700;\n}\n\n.container {\n\tbackground-color: rgba(53, 96, 255, 0.05);\n\tborder-radius: 4px;\n\twidth: fit-content;\n\tpadding-left: 15px;\n\tpadding-right: 15px;\n\tmargin-right: 10px;\n\tmargin-bottom: 10px;\n\tdisplay: flex;\n\tflex-direction: row;\n\theight: 40px;\n\tjustify-content: center;\n\talign-items: center;\n\talign-content: center;\n}\n\n.x {\n\tmargin-left: 8px;\n\tmargin-bottom: 2px;\n\t&:hover {\n\t\tcursor: pointer;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "PFAdId_text__IWJsI",
	"container": "PFAdId_container__X7IzR",
	"x": "PFAdId_x__IIXre"
};
export default ___CSS_LOADER_EXPORT___;
