// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PerformancePage_sidebarWrapper__UUKgy {\n  height: 100%;\n  position: fixed;\n}\n\n.PerformancePage_headerWrapper__3HLzL {\n  margin-top: 20px;\n  width: 100%;\n  background-color: rgba(53, 96, 255, 0.05);\n  border-bottom: 1px solid rgba(0, 0, 0, 0.08);\n  position: sticky;\n  top: 0px;\n  z-index: 1000;\n}\n\n.PerformancePage_page__QT5ua {\n  background-color: rgb(250, 250, 250);\n  padding-bottom: 30px;\n  width: 100%;\n  height: 100%;\n  display: flex;\n}\n\n.PerformancePage_contentWrapper__tVu93 {\n  width: 100%;\n  margin-left: 15px;\n  margin-right: 15px;\n  margin-left: 105px;\n}", "",{"version":3,"sources":["webpack://./src/components/pages/performance/PerformancePage.module.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,eAAA;AACD;;AAEA;EACC,gBAAA;EACA,WAAA;EACA,yCAAA;EACA,4CAAA;EACA,gBAAA;EACA,QAAA;EACA,aAAA;AACD;;AAEA;EACC,oCAAA;EACA,oBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AACD;;AAEA;EACC,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;AACD","sourcesContent":[".sidebarWrapper {\n\theight: 100%;\n\tposition: fixed;\n}\n\n.headerWrapper {\n\tmargin-top: 20px;\n\twidth: 100%;\n\tbackground-color: rgba(53, 96, 255, 0.05);\n\tborder-bottom: 1px solid rgba(0, 0, 0, 0.08);\n\tposition: sticky;\n\ttop: 0px;\n\tz-index: 1000;\n}\n\n.page {\n\tbackground-color: rgba(250, 250, 250, 1);\n\tpadding-bottom: 30px;\n\twidth: 100%;\n\theight: 100%;\n\tdisplay: flex;\n}\n\n.contentWrapper {\n\twidth: 100%;\n\tmargin-left: 15px;\n\tmargin-right: 15px;\n\tmargin-left: 105px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebarWrapper": "PerformancePage_sidebarWrapper__UUKgy",
	"headerWrapper": "PerformancePage_headerWrapper__3HLzL",
	"page": "PerformancePage_page__QT5ua",
	"contentWrapper": "PerformancePage_contentWrapper__tVu93"
};
export default ___CSS_LOADER_EXPORT___;
