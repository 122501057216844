/* eslint-disable @typescript-eslint/no-unused-vars */
import {Field, Formik} from 'formik';
import moment from 'moment';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {CampaignModals} from '../../../../constants/CampaignModals';
import {Countries} from '../../../../constants/Countries';
import {Icons} from '../../../../constants/Icons';
import {TargetGoals} from '../../../../constants/TargetGoals';
import {Budget} from '../../../../models/Budget';
import {CampaignBrief} from '../../../../models/CampaignBrief';
import {Country} from '../../../../models/Country';
import {CreateCampaignRequest} from '../../../../models/CreateCampaignRequest';
import {CreateCampaignSubmit} from '../../../../models/CreateCampaignSubmit';
import {DateLive} from '../../../../models/Date';
import {Goal} from '../../../../models/Goal';
import {LiveDate} from '../../../../models/LiveDate';
import {SelectBrand} from '../../../../models/SelectBrand';
import {TargetGoal} from '../../../../models/TargetGoal';
import {
	changeUnsavedChanges,
	createCampaign,
	editCampaign,
} from '../../../../store/actions/CampaignActions';
import {getBrands} from '../../../../store/actions/UserActions';
import {RootState} from '../../../../store/reducers/RootReducer';
import {CampaignValidationScheme} from '../../../../validation/CampaignValidationScheme';
import PFBrandSelectMenu from '../../atoms/BrandSelectMenu/PFBrandSelectMenu';
import PFBudgetCard from '../../atoms/BudgetCard/PFBudgetCard';
import PFDateCard from '../../atoms/DateCard/PFDateCard';
import PFFileButton from '../../atoms/FileButton/PFFileButton';
import {PFFormikTextInput} from '../../atoms/FormikTextInput/PFFormikTextInput';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import PFStepCard from '../../atoms/StepCard/PFStepCard';
import PFAddDateModal from '../../molecules/AddDateModal/PFAddDateModal';
import PFEditDateModal from '../../molecules/EditDateModal/PFEditDateModal';
import PFGoalCard from '../../molecules/GoalCard/PFGoalCard';
import PFLiveMarketModal from '../../molecules/LiveMarketModal/PFLiveMarketModal';
import PFTargetGoalModal from '../../molecules/TargetGoalModal/PFTargetGoalModal';
import styles from './PFSoW.module.scss';
import {toast} from 'react-toastify';
import {Dots} from 'react-activity';
import {Alert} from 'react-bootstrap';

interface PFSoWProps {
	text?: string;
	onClick?: () => void;
	style?: string;
	type?: 'submit' | 'button' | 'reset' | undefined;
}
export const PFSoW: React.FC<PFSoWProps> = ({text}) => {
	const [brandId, setBrandId] = useState<number>(-1);
	const [markets, setMarkets] = useState(Array<Country>);
	const [budgets, setBudgets] = useState(Array<Budget>);
	const [goals, setGoals] = useState(Array<Goal>);
	const [targetGoals, setTargetGoals] = useState(Array<TargetGoal>);
	const [liveDates, setLiveDates] = useState(Array<LiveDate>);
	const [modalShowMarket, setModalShowMarket] = useState(false);
	const [modalShowGoal, setModalShowGoal] = useState(false);
	const [modalShowNewDate, setModalShowNewDate] = useState(false);
	const [modalShowEditDate, setModalShowEditDate] = useState(false);
	const dispatch = useDispatch<any>();
	const imageUploadRef = useRef<HTMLInputElement | null>(null);
	const fileUploadRef = useRef<HTMLInputElement>(null);
	const contentPlanFileUploadRef = useRef<HTMLInputElement>(null);
	const [images, setImages] = useState<Array<any>>();
	const [showImages, setShowImages] = useState<Array<any>>();
	const [file, setFile] = useState<any>();
	const [, setFileName] = useState<string | undefined>();
	const [dateCounter, setDateCounter] = useState<number>(0);
	const [dateFrom, setDateFrom] = useState<string>('');
	const [dateTo, setDateTo] = useState<string>('');
	const [dateName, setDateName] = useState<string>('');
	const [dateId, setDateId] = useState<number>(0);
	const [dateType, setDateType] = useState<number>(1);
	const [deletedImages, setDeletedImages] = useState(Array<string>);
	const [loseFocus, setLoseFocus] = useState(false);
	const [contentPlanFile, setContentPlan] = useState<any>();
	const [, setContentFileName] = useState<string | undefined>();
	const [showFillRequiredFieldsPopup, setShowFillRequiredFieldsPopup] =
		useState(false);

	const contentPlan = useSelector(
		(state: RootState) => state.contentPlan.currentContentPlan,
	);

	const nav = useNavigate();

	const allBrands: Array<SelectBrand> = useSelector(
		(state: RootState) => state.user.allBrands,
	);
	const isLoading = useSelector(
		(state: RootState) => state.campaign.isCampaignUploading,
	);

	const campaign: CampaignBrief = useSelector(
		(state: RootState) => state.campaign.currentCampaign,
	);

	const setSelectedBrand = (brand: number) => {
		setBrandId(brand);
		dispatch(changeUnsavedChanges(true));
	};

	const setLiveMarket = (country: Country) => {
		const newMarkets = [country, ...markets];
		setMarkets(newMarkets);
		setModalShowMarket(false);
		dispatch(changeUnsavedChanges(true));
	};

	const setTargetGoal = (goal: Goal) => {
		const newGoals = [goal, ...goals];
		const newTargetGoals = [
			{
				id: goal.id,
				targetValue: goal.targetValue,
				name: goal.name,
				cost: goal.cost,
				currentValue: goal.currentValue,
			},
			...targetGoals,
		];
		setGoals(newGoals);
		setTargetGoals(newTargetGoals);
		setModalShowGoal(false);
		dispatch(changeUnsavedChanges(true));
	};

	const setLiveDate = (date: LiveDate) => {
		date.id = dateCounter;
		const newLiveDates = [date, ...liveDates];
		setLiveDates(newLiveDates);
		setModalShowNewDate(false);
		setDateCounter(dateCounter + 1);
		dispatch(changeUnsavedChanges(true));
	};

	const editLiveDate = (date: LiveDate) => {
		setLiveDates(current =>
			current.map(obj => {
				if (obj.id === date.id) {
					return {
						...obj,
						name: date.name,
						dateFrom: date.dateFrom,
						dateTo: date.dateTo,
						type: date.type,
					};
				}

				return obj;
			}),
		);
		setModalShowEditDate(false);
		dispatch(changeUnsavedChanges(true));
	};

	const setBudget = (budget: Budget) => {
		setBudgets(current =>
			current.map(obj => {
				if (obj.id === budget.id) {
					return {...obj, currency: budget.currency, amount: budget.amount};
				}
				return obj;
			}),
		);
		dispatch(changeUnsavedChanges(true));
	};

	const deleteLiveDate = (id: number) => {
		const newLiveDates = liveDates.filter(d => d.id !== id);
		setLiveDates(newLiveDates);
		setModalShowEditDate(false);
		dispatch(changeUnsavedChanges(true));
	};

	const deleteLiveMarket = (name: string | null) => {
		const newMarkets = markets.filter(m => m.name !== name);
		setMarkets(newMarkets);
		dispatch(changeUnsavedChanges(true));
	};

	const deleteGoal = (name: string | null) => {
		const newGoals = goals.filter(g => g.name !== name);
		const newTargetGoals = targetGoals.filter(g => g.name !== name);
		setTargetGoals(newTargetGoals);
		setGoals(newGoals);
		dispatch(changeUnsavedChanges(true));
	};

	const setCampaignImage = () => {
		return imageUploadRef.current?.click();
	};
	const setCampaignFile = () => {
		return fileUploadRef.current?.click();
	};
	const setContentPlanFile = () => {
		return contentPlanFileUploadRef.current?.click();
	};

	const handleImageUpload = (e: any) => {
		const newUrl = e.target.files[0];
		const newUrlShow = URL.createObjectURL(e.target.files[0]);
		const newImages = [newUrl, ...(images || [])];
		const newImagesShow = [newUrlShow, ...(showImages || [])];
		setImages(newImages);
		setShowImages(newImagesShow);
		imageUploadRef.current!.value = '';
		dispatch(changeUnsavedChanges(true));
	};

	const handleFileUpload = (e: any) => {
		setFile(e.target.files[0]);
		setFileName(e.target.files[0].name);
		dispatch(changeUnsavedChanges(true));
	};

	const handleContentPlanFileUpload = (e: any) => {
		setContentPlan(e.target.files[0]);
		setContentFileName(e.target.files[0].name);
		dispatch(changeUnsavedChanges(true));
	};

	const getDate = () => {
		var date = new Date();
		date.setDate(date.getDate() + 30);
		return date;
	};

	const deleteImage = (deleteImage: string) => {
		setDeletedImages(prevState => [...prevState, deleteImage]);
		const newShowImages = showImages?.filter(image => image !== deleteImage);
		setShowImages(newShowImages);
		imageUploadRef.current!.value = '';
		dispatch(changeUnsavedChanges(true));
	};

	const handleEditDate = (date: LiveDate) => {
		setDateId(date.id);
		setDateFrom(date.dateFrom);
		setDateTo(date.dateTo);
		setDateType(date.type);
		setDateName(date.name);
		setModalShowEditDate(true);
		dispatch(changeUnsavedChanges(true));
	};

	const getImagesFormData = () => {
		var formDataImages = new FormData();
		images?.forEach(i => {
			if (i.id === undefined)
				formDataImages.append('CampaignImages', i as File);
		});
		return formDataImages;
	};

	const getFileFormData = () => {
		const formDataFile = new FormData();
		if (file.lastModified) {
			formDataFile.append('CampaignBriefFile', file);
		}
		return formDataFile;
	};

	const getContentPlanFileFormData = () => {
		const fd = new FormData();
		if (contentPlanFile?.lastModified && contentPlanFile) {
			fd.append('ContentPlanFile', contentPlanFile);
		}
		return fd;
	};

	const getMarkets = (markets: Array<Country>) => {
		var returnValue: Array<number | undefined> = [];
		markets.map(m => returnValue.push(m.id));
		return returnValue;
	};

	const getDates = useMemo(() => {
		const newDates: Array<DateLive | undefined> = [];
		liveDates.map(ld =>
			newDates.push({
				id: ld.id,
				dateFrom: new Date(ld.dateFrom),
				dateTo: new Date(ld.dateTo),
				name: ld.name,
				type: ld.type,
			}),
		);
		return newDates;
	}, [liveDates]);

	const checkIfBudgetIsNull = () => {
		for (let index = 0; index < budgets.length; index++) {
			if (budgets[index].amount === undefined) {
				return true;
			}
		}
		return false;
	};

	const checkIsValid = () => {
		if (
			brandId === -1 ||
			markets.length === 0 ||
			targetGoals.length === 0 ||
			getDates.length === 0 ||
			markets.length === 0 ||
			file === undefined ||
			images === undefined ||
			fileUploadRef === null ||
			imageUploadRef === null ||
			checkIfBudgetIsNull() ||
			hasCampaignEnded ||
			isLoading
		) {
			return false;
		}

		return true;
	};

	const createInitialValues = () => {
		if (text === 'edit') {
			return {
				name: campaign.name,
				campaignBrief: campaign.campaignBrief,
			};
		}
		return {name: '', campaignBrief: ''};
	};

	const setValues = (name: string, campaignBrief: string) => {
		return {
			name: name,
			campaignBrief: campaignBrief,
			clientId: brandId,
			campaignBriefFile: getFileFormData(),
			contentPlanFile: getContentPlanFileFormData(),
			targetGoals: targetGoals,
			campaignSteps: [0, 1, 2, 3],
			campaignImages: getImagesFormData(),
			budgets: budgets,
			dates: getDates,
			liveMarkets: getMarkets(markets),
		};
	};

	useEffect(() => {
		dispatch(changeUnsavedChanges(false));
		dispatch(getBrands());
	}, [campaign.id, dispatch]);

	useEffect(() => {
		dispatch(changeUnsavedChanges(false));

		setLiveDate({
			id: 0,
			dateFrom: moment(new Date()).format('DD MMM YYYY'),
			dateTo: moment(getDate()).format('DD MMM YYYY'),
			name: '1st Live Date',
			type: 0,
		});
		const initalBudgets = [
			{id: 0, name: 'Creator Budget', amount: undefined, currency: 0},
			{id: 1, name: 'Media Budget', amount: undefined, currency: 0},
			{id: 2, name: 'Agency Fees', amount: undefined, currency: 0},
			{id: 3, name: 'Additional Production', amount: undefined, currency: 0},
		];
		setBudgets(initalBudgets);
	}, []);

	const getMarkedNumber = (markets: Array<string>): Array<Country> => {
		var names: Array<Country> = [];
		markets.map(m => {
			var countries = Countries.filter(
				c => c.label.toLowerCase() === m.toLowerCase(),
			);
			if (countries.length > 0) {
				var newCountrey: Country = {
					id: countries[0].value,
					name: countries[0].label,
				};
				names.push(newCountrey);
			}
		});
		return names;
	};

	const resetFormik = () => {
		setGoals(campaign?.targetGoals);
		setTargetGoals(campaign.targetGoals);
		setShowImages(campaign?.campaignImages.map(x => x.imageUrl));
		setImages(campaign?.campaignImages);
		setBrandId(campaign?.clientId);
		setMarkets(getMarkedNumber(campaign?.liveMarkets));
		setBudgets(campaign?.budgets);
		var dates = campaign?.dates.map(x => {
			var newDate: LiveDate = {
				dateFrom: x.dateFrom.toString(),
				dateTo: x.dateTo.toString(),
				id: x.id,
				name: x.name,
				type: x.type,
			};
			return newDate;
		});
		setLiveDates(dates);

		const parts = campaign?.campaignBriefUrl.split('Campaign-briefs/');
		if (parts.length > 0 && parts[1] !== undefined) {
			let fileName = decodeURIComponent(parts[1].split('?')[0]);
			const file = {name: fileName, url: campaign?.campaignBriefUrl};
			setFile(file);
		}

		const contentPlanParts = contentPlan?.split('Content-plans/');
		if (contentPlanParts.length > 0 && contentPlanParts[1] !== undefined) {
			let fileNameCP = decodeURIComponent(contentPlanParts[1].split('?')[0]);
			const cp = {name: fileNameCP, url: contentPlan};
			setContentPlan(cp);
		}
	};

	useEffect(() => {
		dispatch(changeUnsavedChanges(false));

		if (text === 'edit') {
			resetFormik();
		}

		createInitialValues();
	}, [campaign]);

	const handleDownload = (type: string) => {
		if (type === 'contentPlan') {
			window.open(contentPlanFile.url);
		} else {
			window.open(file.url);
		}
	};

	const handleDelete = (type: string) => {
		if (type === 'contentPlan') {
			setContentPlan(undefined);
		} else {
			setFile(undefined);
		}
		dispatch(changeUnsavedChanges(true));
	};

	const handleGoalChange = (value: number, name: string) => {
		setTargetGoals(current =>
			current.map(obj => {
				if (obj.name === name) {
					return {...obj, currentValue: value};
				}
				return obj;
			}),
		);
		dispatch(changeUnsavedChanges(true));
	};

	const handleAddMarkets = () => {
		if (markets.length + 1 > 3) {
			setModalShowMarket(false);
			toast.error('You can add maximum 3 live markets');
		} else {
			setModalShowMarket(true);
		}
		dispatch(changeUnsavedChanges(true));
	};

	const hasCampaignEnded =
		campaign && campaign.campaignStatus ? campaign.campaignStatus === 4 : false;

	const handleAddTargetGoal = () => {
		if (!hasCampaignEnded) {
			setModalShowGoal(true);
		}
	};
	const handleAddImages = () => {
		if (!hasCampaignEnded) {
			setCampaignImage();
		}
	};

	useEffect(() => {
		if (showFillRequiredFieldsPopup) {
			alert('Please fill in all required fields.');
			setShowFillRequiredFieldsPopup(false);
		}
	}, [showFillRequiredFieldsPopup]);

	if (campaign.id === -1 && text === 'edit') {
		return (
			<div className={styles.dots}>
				<Dots size={40} color={'#3560FF'} />
			</div>
		);
	}

	return (
		<div>
			<Formik
				enableReinitialize={true}
				initialValues={createInitialValues()}
				validationSchema={CampaignValidationScheme}
				onSubmit={(values: CreateCampaignSubmit) => {
					const data: CreateCampaignRequest = setValues(
						values.name,
						values.campaignBrief,
					);

					if (text === 'edit') {
						dispatch(editCampaign(data, campaign.id, deletedImages));
					} else {
						dispatch(createCampaign(data, nav));
					}

					setDeletedImages([]);
					dispatch(changeUnsavedChanges(false));
				}}>
				{({handleSubmit, dirty, isValid, validateForm}) => (
					<>
						{!!dirty && dispatch(changeUnsavedChanges(true))}
						<div className={styles.head}>
							<div className={styles.left}>
								<div className={styles.textTargetFirst}>Campaign Overview</div>
								<div className={styles.tabWrapper}>
									<div className={styles.separator}>
										<Field
											label="Campaign Name *"
											component={PFFormikTextInput}
											placeholder="Enter Campaign Name"
											name="name"
											editable={!hasCampaignEnded}
											loseFocus={loseFocus}
											type="text"
											value={text === 'edit' ? campaign?.name : ''}
											style={styles.brand}
											styleForInput={styles.inputName}
											styleForErrorLabel={styles.styleForErrorLabel}
										/>
									</div>
									<div className={styles.brand}>Link Brand *</div>
									<div className={styles.separator}>
										<div className={`${styles.row} ${styles.select}`}>
											<PFBrandSelectMenu
												onChange={setSelectedBrand}
												list={allBrands}
												selected={brandId}
												isDisabled={hasCampaignEnded}
											/>
										</div>
										<div className={styles.brand}>Content Plan File</div>

										<div className={styles.contentPlan}>
											<div className={styles.file}>
												{text === 'edit' && contentPlanFile ? (
													<div
														title={`Open ${contentPlanFile?.name}`}
														className={`${styles.fileName} ${styles.edit}`}>
														<div className={styles.fileNameWrapper}>
															<span className={styles.fileNameText}>
																{contentPlanFile?.name}
															</span>
															<span
																className={styles.download}
																title="Download file"
																onClick={() => handleDownload('contentPlan')}>
																{Icons.DOWNLOAD}
															</span>
															{!hasCampaignEnded && (
																<span
																	className={styles.x}
																	title="Delete file"
																	onClick={() => handleDelete('contentPlan')}>
																	{Icons.X}
																</span>
															)}
														</div>
													</div>
												) : (
													<div className={styles.fileName}>
														{contentPlanFile?.name
															? contentPlanFile.name
															: 'No Content Plan uploaded.'}
													</div>
												)}
												<div className={styles.btnAfile}>
													<PFFileButton
														onClick={setContentPlanFile}
														text="Upload Content Plan File"
														disabled={hasCampaignEnded}
													/>
												</div>
												<input
													id={'contentPlanFile'}
													ref={contentPlanFileUploadRef}
													type={'file'}
													style={{display: 'none'}}
													accept=".doc,.txt,.pdf"
													onChange={handleContentPlanFileUpload}
												/>
												<div className={styles.supp}>
													Supported: pdf, txt, doc
												</div>
											</div>
										</div>
										<div className={styles.brand}>Campaign Brief File *</div>
										<div className={styles.row}>
											<div className={styles.file}>
												{text === 'edit' && file ? (
													<div
														title={`Open ${file?.name}`}
														className={`${styles.fileName} ${styles.edit}`}>
														<div className={styles.fileNameWrapper}>
															<span className={styles.fileNameText}>
																{file?.name}
															</span>
															<span
																className={styles.download}
																title="Download file"
																onClick={() => handleDownload('brief')}>
																{Icons.DOWNLOAD}
															</span>
															{!hasCampaignEnded && (
																<span
																	className={styles.x}
																	title="Delete file"
																	onClick={() => handleDelete('brief')}>
																	{Icons.X}
																</span>
															)}
														</div>
													</div>
												) : (
													<div className={styles.fileName}>
														{file?.name
															? file.name
															: 'No Campaign Brief uploaded.'}
													</div>
												)}
												<div className={styles.btnAfile}>
													<PFFileButton
														onClick={setCampaignFile}
														text="Upload Campaign Brief File"
														disabled={hasCampaignEnded}
													/>
												</div>
												<input
													id={'file'}
													ref={fileUploadRef}
													type={'file'}
													style={{display: 'none'}}
													accept=".doc,.txt,.pdf"
													onChange={handleFileUpload}
												/>
												<div className={styles.supp}>
													Supported: pdf, txt, doc
												</div>
											</div>
										</div>
									</div>
									<div className={styles.separator}>
										<Field
											label="Campaign Brief *"
											component={PFFormikTextInput}
											placeholder="Enter Campaign Brief"
											name="campaignBrief"
											type="text"
											value={text === 'edit' ? campaign?.campaignBrief : ''}
											style={styles.brand}
											styleForErrorLabel={styles.styleForErrorLabel}
											editable={!hasCampaignEnded}
											textArea
										/>
									</div>
								</div>
								<div className={styles.separatorTop}>
									<div className={styles.textTargetGoals}>
										Target Goals *
										{goals.length <= TargetGoals.length && (
											<div
												className={styles.icons}
												onClick={handleAddTargetGoal}>
												{Icons.PLUS}
											</div>
										)}
									</div>
								</div>
								<div style={styleCards} className={styles.cards}>
									{goals.map((g, index) => (
										<div className={styles.oneCard} key={index}>
											<PFGoalCard
												cost={g.cost}
												name={g.name}
												targetValue={String(g.targetValue)}
												currentValue={g.currentValue}
												onClick={deleteGoal}
												editable={!hasCampaignEnded}
												onChange={handleGoalChange}
											/>
										</div>
									))}
								</div>
								<div className={styles.textTargetFirst}>
									Campaign Images *
									{
										<div className={styles.icons} onClick={handleAddImages}>
											{Icons.PLUS}
										</div>
									}
								</div>
								<input
									ref={imageUploadRef}
									type={'file'}
									style={{display: 'none'}}
									accept="image/png, image/jpeg"
									onChange={handleImageUpload}
								/>
								<div style={styleCards} className={styles.cards}>
									{showImages?.map((image, index) => (
										<div title="Delete image" key={index}>
											<img
												className={styles.image}
												src={image}
												alt={'Campaign'}></img>{' '}
											{!hasCampaignEnded && (
												<div
													className={styles.bin}
													onClick={() => deleteImage(image)}>
													{Icons.BIN}
												</div>
											)}
										</div>
									))}
								</div>
							</div>

							<div className={styles.right}>
								<div className={styles.textTargetFirst}>Campaign Steps</div>
								<div className={styles.separator}>
									<div className={styles.cardWrapper}>
										{' '}
										<div className={styles.separator}>
											<PFStepCard
												text="Creator Brief"
												type="CreatorBrief"
												onDelete={() => {}}
											/>
										</div>
										<div className={styles.separator}>
											<PFStepCard
												text="Creator List"
												type="CreatorList"
												style={styles.list}
												onDelete={() => {}}
											/>
										</div>
										<div className={styles.separator}>
											<PFStepCard
												text="Content Plan"
												type="ContentPlan"
												onDelete={() => {}}
											/>
										</div>
										<div className={styles.separator}>
											<PFStepCard
												text="Final Assets"
												type="FinalAssets"
												onDelete={() => {}}
											/>
										</div>
									</div>
								</div>

								<div className={styles.textTargetFirst}>
									Live Markets *
									{markets.length <= 3 && !hasCampaignEnded && (
										<div
											className={styles.icons}
											onClick={() => handleAddMarkets()}>
											{Icons.PLUS}
										</div>
									)}
								</div>
								<div className={styles.cardWrapper}>
									{markets.map((m, index) => (
										<div className={styles.separator} key={index}>
											<PFStepCard
												text={m.name}
												type="LiveMarket"
												onDelete={deleteLiveMarket}
												editable={!hasCampaignEnded}
											/>
										</div>
									))}
								</div>
								<div className={styles.textTargetFirst}>Campaign Budget *</div>
								{budgets.map((b, index) => (
									<div className={styles.cardWrapper} key={index}>
										<div className={styles.separator}>
											<PFBudgetCard
												text={b.name}
												budget={b}
												onChange={setBudget}
												editable={!hasCampaignEnded}
											/>
										</div>
									</div>
								))}
								<div className={styles.textTargetFirst}>
									Campaign Live Dates *
									{!hasCampaignEnded && (
										<div
											className={styles.icons}
											onClick={() => setModalShowNewDate(true)}>
											{Icons.PLUS}
										</div>
									)}
								</div>
								<div className={styles.cardWrapper}>
									{liveDates.map((date, index) => (
										<div className={styles.separator} key={index}>
											<PFDateCard
												onClick={() => handleEditDate(date)}
												text={date.name}
												startDate={moment(date.dateFrom).format('DD MMM YYYY')}
												endDate={moment(date.dateTo).format('DD MMM YYYY')}
												editable={!hasCampaignEnded}
											/>
										</div>
									))}
								</div>
								{campaign.campaignStatus === 2 &&
									campaign.briefComment &&
									campaign.briefComment !== '' && (
										<div className={styles.commentContainer}>
											<div className={styles.commentLabel}>
												{Icons.WARNING} Clients comment
											</div>
											<div className={styles.comment}>
												{campaign.briefComment}
											</div>
										</div>
									)}
							</div>
						</div>
						<div className={styles.bttmRow}>
							<div className={styles.leftBtn}>
								<PFPrimaryButton
									onClick={() => {
										window.location.reload();
									}}
									text={'DISCARD'}
									type={'reset'}
									style={styles.discardBtn}
								/>
							</div>
							<div className={styles.rightBtn}>
								<PFPrimaryButton
									onClick={() => {
										if (!(checkIsValid() && isValid)) {
											setShowFillRequiredFieldsPopup(true);
										} else {
											validateForm();
											handleSubmit();
										}
									}}
									isLoading={isLoading}
									text={'SAVE CAMPAIGN'}
									style={
										checkIsValid() && isValid ? styles.saveBtn : styles.disabled
									}
								/>
							</div>
						</div>
					</>
				)}
			</Formik>
			<PFLiveMarketModal
				size={'sm'}
				markets={markets}
				onClick={setLiveMarket}
				onHide={() => setModalShowMarket(false)}
				show={modalShowMarket}
				label={CampaignModals.LIVE_MARKET}
			/>
			<PFTargetGoalModal
				size={'sm'}
				goals={goals}
				onClick={setTargetGoal}
				onHide={() => setModalShowGoal(false)}
				show={modalShowGoal}
				label={CampaignModals.TARGET_GOAL}
			/>
			<PFAddDateModal
				onClick={setLiveDate}
				onHide={() => setModalShowNewDate(false)}
				show={modalShowNewDate}
				label={CampaignModals.LIVE_DATE}
			/>
			<PFEditDateModal
				dateFrom={dateFrom}
				dateTo={dateTo}
				id={dateId}
				name={dateName}
				type={dateType}
				onClick={editLiveDate}
				onDelete={deleteLiveDate}
				onHide={() => setModalShowEditDate(false)}
				show={modalShowEditDate}
				label={CampaignModals.EDIT_DATE}
			/>
		</div>
	);
};

const styleCards = {
	display: 'flex',
	flexWrap: 'wrap' as 'wrap',
};
export default PFSoW;
