import moment from 'moment';

export const getCreatorStatus = (val: number | undefined) => {
	switch (val) {
		case 0:
			return 'Suggested';
		case 1:
			return 'Not Interested';
		case 2:
			return 'Interested';
		case 3:
			return 'Pending';
		case 4:
			return 'Declined';
		case 5:
			return 'Accepted';
		case 6:
			return 'Rejected';
		case 7:
			return 'Live';
		default:
			break;
	}
};

export const getUserStatus = (val: number | undefined) => {
	switch (val) {
		case 0:
			return 'Pending';
		case 1:
			return 'Active';
		case 2:
			return 'Declined';
		case 3:
			return 'Deleted';
		default:
			break;
	}
};

export const getCampaignStatus = (val: number | undefined) => {
	switch (val) {
		case 0:
			return 'Pending';
		case 1:
			return 'Approved';
		case 2:
			return 'Declined';
		case 3:
			return 'Active';
		case 4:
			return 'Ended';
		default:
			return 'Pending';
	}
};

export const renderDate = (currentDate: string) => {
	const date = moment(currentDate);
	const today = moment(new Date());

	const diffDays = today.diff(date, 'days');
	const diffWeeks = today.diff(date, 'weeks');
	if (diffDays > 6) {
		const suffix = diffWeeks > 1 ? 'weeks' : 'week';
		return `${diffWeeks} ${suffix} ago`;
	}

	return moment(currentDate).fromNow();
};
