import React, {useEffect, useState} from 'react';
import {Icons} from '../../../../constants/Icons';
import {Budget} from '../../../../models/Budget';
import PFCurrencyMenu from '../CurrencyMenu/PFCurrencyMenu';
import styles from './PFBudgetCard.module.scss';

interface PFBudgetCardProps {
	text: string;
	budget: Budget;
	onChange: (budget: Budget) => void;
	editable?: boolean;
}
export const PFBudgetCard: React.FC<PFBudgetCardProps> = ({
	text,
	budget,
	onChange,
	editable = true,
}) => {
	const [amount, setAmount] = useState(budget.amount);
	const [currency, setCurrency] = useState(0);

	const setBudgetValue = (event: any) => {
		if (event.target.value === '') {
			setAmount(0);
			event.target.value = 0;
		} else {
			setAmount(Number(event.target.value));
		}

		let newBudget: Budget = {
			id: budget.id,
			amount: Number(event.target.value),
			currency: currency,
			name: budget.name,
		};

		onChange(newBudget);
		if (Number(event.target.value) === 0) {
			event.target.select();
		}
	};

	const handleFocus = (event: any) => event.target.select();

	const setBudgetCurrency = (value: number) => {
		setCurrency(value);
		let newBudget: Budget = {
			id: budget.id,
			amount: amount,
			currency: value,
			name: budget.name,
		};
		onChange(newBudget);
	};

	useEffect(() => {
		if (budget.amount) {
			setAmount(budget.amount);
		}
		setCurrency(budget.currency);
	}, []);

	return (
		<div className={styles.split}>
			<div className={styles.card}>
				<div className={styles.icon}>{Icons.BUDGET}</div>
				<div className={styles.text}>{text}</div>
			</div>
			<div className={styles.row}>
				<div className={styles.curr}>Value:</div>
				<input
					min="0"
					disabled={!editable}
					pattern="\d+"
					onFocus={handleFocus}
					type="number"
					onChange={e => setBudgetValue(e)}
					className={`${styles.input}`}
					placeholder={'Enter value'}
					value={amount !== undefined ? amount : ''}></input>

				<div className={styles.select}>
					<PFCurrencyMenu
						editable={editable}
						defaultValue={0}
						onChange={setBudgetCurrency}
						budget={budget}
					/>{' '}
				</div>
			</div>
		</div>
	);
};
export default PFBudgetCard;
