import React from 'react';
import Select from 'react-select';
import {DateTypes} from '../../../../constants/DateTypes';
import styles from './PFDateTypeMenu.module.scss';

interface PFDateTypeMenuProps {
	onChange: (retVal: number) => void;
	defaultValue?: number;
}

export const PFDateTypeMenu: React.FC<PFDateTypeMenuProps> = ({
	onChange,
	defaultValue = 0,
}) => {
	const customStyles = {
		control: (provided: any) => ({
			...provided,
			border: '1px solid rgba(0, 0, 0, 0.08)',
			'&:hover': {
				cursor: 'pointer',
			},
		}),
		indicatorSeparator: (provided: any) => ({
			...provided,
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
		}),
		dropdownIndicator: (provided: any) => ({
			...provided,
			color: 'rgba(0, 0, 0, 0.6)',
		}),
	};
	return (
		<Select
			styles={customStyles}
			className={styles.select}
			options={DateTypes}
			defaultValue={DateTypes[defaultValue]}
			onChange={e => onChange(e!.value)}
		/>
	);
};

export default PFDateTypeMenu;
