import React, {useState} from 'react';
import styles from './PFSearchInput.module.scss';
import {Icons} from '../../../../constants/Icons';

interface PFSearchInputProps {
	onClick: (retVal: string) => void;
	placeholder?: string;
	handleSearch: (value: string) => void;
	submitOnEnter?: boolean;
}

export const PFSearchInput: React.FC<PFSearchInputProps> = ({
	onClick,
	placeholder = 'Search',
	handleSearch,
	submitOnEnter,
}) => {
	const [returnValue, setReturnValue] = useState('');
	const [focused, setFocused] = useState(false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setReturnValue(event.target.value);
	};

	const onEnter = (event: any) => {
		if (submitOnEnter) {
			if (event.keyCode === 13) {
				handleSearch(event.target.value);
			}
		} else {
			handleChange(event.target.value);
		}
	};

	return (
		<div
			className={`${styles.inputContainer} ${focused ? styles.focused : ''}`}>
			<input
				className={`${styles.input}`}
				type="text"
				value={returnValue}
				onChange={handleChange}
				onKeyDown={submitOnEnter ? e => onEnter(e) : () => {}}
				placeholder={placeholder}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
			/>
			<span
				style={stylesObject.searchContainer}
				onClick={() => handleSearch(returnValue)}
				title="Search">
				{Icons.SEARCH}
			</span>
		</div>
	);
};

const stylesObject = {
	searchContainer: {
		cursor: 'pointer',
	},
};

export default PFSearchInput;
