import 'bootstrap/dist/css/bootstrap.css';
import {Field, Formik} from 'formik';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {CampaignModals} from '../../../../constants/CampaignModals';
import {LiveDate} from '../../../../models/LiveDate';
import {AddLiveDateValidationScheme} from '../../../../validation/AddLiveDateValidationScheme';
import PFDateTypeMenu from '../../atoms/DateTypeMenu/PFDateTypeMenu';
import {PFFormikTextInput} from '../../atoms/FormikTextInput/PFFormikTextInput';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import PFModal from '../../organisms/Modal/PFModal';
import styles from './PFEditDateModal.module.scss';

interface PFEditDateModalProps {
	onClick: (date: LiveDate) => void;
	onDelete: (id: number) => void;
	onHide: () => void;
	show: boolean;
	label?: string;
	name: string;
	dateFrom: string;
	dateTo: string;
	id: number;
	type: number;
}
export const PFEditDateModal: React.FC<PFEditDateModalProps> = ({
	onClick,
	onDelete,
	onHide,
	show = false,
	label,
	name,
	dateFrom,
	dateTo,
	id,
	type,
}) => {
	const [from, setFrom] = useState<string>('');
	const [to, setTo] = useState<string>('');
	const [newName, setNewName] = useState<string>('');
	const [typeOfDate, setTypeOfDate] = useState<number>(2);
	const [fromDate, setFromDate] = useState<Date>(new Date());
	const [currentName, setCurrentName] = useState('');

	const setDateFrom = (e: any) => {
		setFromDate(new Date(e.target.value));
		setFrom(moment(e.target.value).format('YYYY-MM-DD'));
	};

	const setDateTo = (e: any) => {
		setTo(moment(e.target.value).format('YYYY-MM-DD'));
	};

	const getMinDate = () => {
		return fromDate.toISOString().split('T')[0];
	};

	const countDateTo = () => {
		if (
			from !== '' &&
			to === '' &&
			new Date(parseDate(from)) > new Date(parseDate(dateTo))
		)
			return from;
		else if (to === '') return dateTo;
		return to;
	};

	const getMonth = (m: string) => {
		if (m === 'Jan') return '01';
		else if (m === 'Feb') return '02';
		else if (m === 'Mar') return '03';
		else if (m === 'Apr') return '04';
		else if (m === 'May') return '05';
		else if (m === 'Jun') return '06';
		else if (m === 'Jul') return '07';
		else if (m === 'Aug') return '08';
		else if (m === 'Sep') return '09';
		else if (m === 'Oct') return '10';
		else if (m === 'Nov') return '11';
		return '12';
	};

	const parseDate = (date: string) => {
		var parts = date.split(' ');
		return `${parts[2]}-${getMonth(parts[1])}-${parts[0]}`;
	};

	const createInitalValues = () => {
		return {name: name, dateFrom: dateFrom, dateTo: dateTo, id: id, type: type};
	};

	const setValues = (name: string) => {
		return {
			name: name,
			dateFrom: from === '' ? dateFrom : from,
			dateTo: countDateTo(),
			id: id,
			type: typeOfDate === 2 ? type : typeOfDate,
		};
	};

	// useEffect(() => {
	// 	setCurrentName(name);
	// }, [name, dateTo, dateFrom]);

	useEffect(() => {
		var dan = new Date(dateTo);
		setTo(parseDate(moment(dan).format('DD MMM YYYY')));
	}, [dateTo]);

	useEffect(() => {
		var dan = new Date(dateFrom);
		setFrom(parseDate(moment(dan).format('DD MMM YYYY')));
	}, [dateFrom]);

	return (
		<PFModal show={show} onHide={onHide} label={CampaignModals.EDIT_DATE}>
			<div>
				<Formik
					initialValues={createInitalValues()}
					validationSchema={AddLiveDateValidationScheme}
					onSubmit={(values: LiveDate) => {
						onClick(setValues(values.name));
					}}>
					{formik => (
						<>
							<div className={styles.divFlex}>
								<div className={styles.firstRow}>
									<div className={styles.leftFirst}>
										<Field
											onChange={setNewName}
											label="Event Name"
											placeholder="Enter Event Name"
											component={PFFormikTextInput}
											name="name"
											type="text"
											value={currentName}
											style={styles.bull2}
											styleForInput={styles.inp}
										/>
									</div>
									<div className={styles.rightFirst}>
										<div className={styles.bull}>Date Type</div>
										<div>
											<PFDateTypeMenu
												defaultValue={type}
												onChange={setTypeOfDate}
											/>
										</div>
									</div>
								</div>
								<div className={styles.firstRow}>
									<div className={styles.leftSecond}>
										<div className={styles.bull}>From</div>
										<div>
											<input
												style={{paddingRight: 10}}
												type="date"
												value={from}
												onChange={e => setDateFrom(e)}></input>
										</div>
									</div>
									<div className={styles.rightSecond}>
										<div className={styles.bull}>To</div>
										<div>
											<input
												style={{paddingRight: 10}}
												type="date"
												value={to}
												min={getMinDate()}
												onChange={e => setDateTo(e)}></input>
										</div>
									</div>
								</div>
								<div>
									<PFPrimaryButton
										onClick={() => formik.handleSubmit()}
										text={'MODIFY LIVE DATE'}
										type="submit"
										style={styles.btn2}></PFPrimaryButton>
								</div>
								<div>
									<PFPrimaryButton
										onClick={() => onDelete(id)}
										text={'DELETE LIVE DATE'}
										type="submit"
										style={styles.btn}></PFPrimaryButton>
								</div>
							</div>
						</>
					)}
				</Formik>
			</div>
		</PFModal>
	);
};

export default PFEditDateModal;
