import axios from 'axios';
import {AuthModel} from '../models/AuthModel';
import {ForgotPasswordModel} from '../models/ForgotPasswordModel';
import {LoginUserModel} from '../models/LoginUserModel';
import {LogOutModel} from '../models/LogOutModel';
import {ResetPasswordModel} from '../models/ResetPasswordModel';

interface AuthService {
	login(data: LoginUserModel): Promise<AuthModel>;
	refreshToken(): Promise<any>;
}

const ENDPOINTS = {
	LOGIN: `${process.env.REACT_APP_BASE_URL}/api/User/authenticate`,
	LOGOUT: `${process.env.REACT_APP_BASE_URL}/api/Notifications/token`,
	REFRESH_TOKEN: `${process.env.REACT_APP_BASE_URL}/api/User/refresh-token`,
	FORGOT_PASSWORD: `${process.env.REACT_APP_BASE_URL}/api/User/forgot-password`,
	RESET_PASSWORD: `${process.env.REACT_APP_BASE_URL}/api/User/reset-password`,
};

class AuthAxiosService implements AuthService {
	async login(data: LoginUserModel): Promise<AuthModel> {
		try {
			const response = await axios.post(ENDPOINTS.LOGIN, data, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
			});
			return response.data;
		} catch (error: any) {
			return Promise.reject(error);
		}
	}

	async logoutUser(data: LogOutModel): Promise<any> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.post(ENDPOINTS.LOGOUT, data, {
				headers: {
					Authorization: 'Bearer ' + Token,
				},
			});
			return response.data;
		} catch (error) {
			return {error: error};
		}
	}

	async refreshToken(): Promise<any> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			axios.defaults.withCredentials = true;
			const response = await axios.post(ENDPOINTS.REFRESH_TOKEN, null, {
				headers: {Authorization: `Bearer ${Token}`},
				withCredentials: true,
			});
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	}

	async forgotPassword(data: ForgotPasswordModel): Promise<any> {
		try {
			const response = await axios.post(ENDPOINTS.FORGOT_PASSWORD, data, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			return {error: error};
		}
	}

	async resetPassword(data: ResetPasswordModel): Promise<any> {
		try {
			const response = await axios.post(ENDPOINTS.RESET_PASSWORD, data, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			return {error: error};
		}
	}
}

export default new AuthAxiosService();
