import React, {useCallback, useEffect, useState} from 'react';
import styles from './CampaignsPage.module.scss';
import {Dots} from 'react-activity';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-activity/dist/Dots.css';

import {useNavigate} from 'react-router-dom';
import GlobalFooter from '../../UI/molecules/GlobalFooter/GlobalFooter';
import GlobalHeader from '../../UI/molecules/globalHeader/GlobalHeader';
import Sidebar from '../../UI/molecules/sidebar/Sidebar';
import PFSearchHeader from '../../UI/organisms/SearchHeader/PFSearchHeader';
import PFCampaignCard from '../../UI/molecules/CampaignCard/PFCampaignCard';
import {CampaignStatus} from '../../../constants/CampaignStatus';
import {useDispatch, useSelector} from 'react-redux';
import {
	getActiveCampaignPage,
	getApprovedCampaignPage,
	getCampaign,
	getCampaignPage,
	getDeclinedCampaignPage,
	getEndedCampaignPage,
	getPendingCampaignPage,
	searchActiveCampaignPage,
	searchCampaignPage,
	searchEndedCampaignPage,
	searchPendingCampaignPage,
} from '../../../store/actions/CampaignActions';
import {Campaign} from '../../../models/Campaign';
import {RootState} from '../../../store/reducers/RootReducer';
import moment from 'moment';
import {
	setCampaignMetaId,
	setCurrentCampaign,
} from '../../../store/reducers/CampaignReducer';
import {TargetGoal} from '../../../models/TargetGoal';
import {CampaignImage} from '../../../models/CampaignImage';
import {Budget} from '../../../models/Budget';
import {DateLive} from '../../../models/Date';
import {setCreatorBrief} from '../../../store/reducers/CreatorBriefReducer';
import {UsageTime} from '../../../models/UsageTime';
import {CreatorBriefAsset} from '../../../models/CreatorBriefAsset';
import {getCreatorBrief} from '../../../store/actions/CreatorBriefActions';
import {getCreators} from '../../../store/actions/UserActions';
import {getContentPlan} from '../../../store/actions/ContentPlanActions';
import {
	setCreatorList,
	setFinalAssets,
} from '../../../store/reducers/CreatorListReducer';

const countMin = (val1: number, val2: number) => {
	if (val1 <= val2) return val1;
	return val2;
};

const CampaignsPage: React.FC = () => {
	const [clicked, setClicked] = useState('All');
	const [currentFirstRow, setCurrentFirstRow] = useState(1);
	const [currentLastRow, setCurrentLastRow] = useState(10);
	const [campaignsPerPage, setCampaignsPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [isSearched, setIsSearched] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const nav = useNavigate();
	const dispatch = useDispatch<any>();

	const campaigns: Array<Campaign> = useSelector(
		(state: RootState) => state.campaign.campaigns,
	);
	const isLoading: boolean = useSelector(
		(state: RootState) => state.campaign.isLoading,
	);
	const totalData: number = useSelector(
		(state: RootState) => state.campaign.totalData,
	);

	const handleClick = (val: string, page?: number) => {
		setClicked(val);
		setCards(val);
	};

	const searchCards = (clicked: string, value: string, page?: number) => {
		if (clicked === CampaignStatus.ALL) {
			dispatch(
				searchCampaignPage({
					value: value,
					pageNumber: page ?? currentPage,
					pageSize: campaignsPerPage,
				}),
			);
		} else if (clicked === CampaignStatus.ACTIVE) {
			dispatch(
				searchActiveCampaignPage({
					value: value,
					pageNumber: page ?? currentPage,
					pageSize: campaignsPerPage,
				}),
			);
		} else if (clicked === CampaignStatus.PENDING) {
			dispatch(
				searchPendingCampaignPage({
					value: value,
					pageNumber: page ?? currentPage,
					pageSize: campaignsPerPage,
				}),
			);
		} else if (clicked === CampaignStatus.ENDED) {
			dispatch(
				searchEndedCampaignPage({
					value: value,
					pageNumber: page ?? currentPage,
					pageSize: campaignsPerPage,
				}),
			);
		}
	};

	const setCards = useCallback(
		(val: string, page?: number) => {
			if (val === CampaignStatus.ALL) {
				dispatch(
					getCampaignPage({
						pageNumber: page ?? currentPage,
						pageSize: campaignsPerPage,
					}),
				);
			} else if (val === CampaignStatus.ACTIVE) {
				dispatch(
					getActiveCampaignPage({
						pageNumber: page ?? currentPage,
						pageSize: campaignsPerPage,
					}),
				);
			} else if (val === CampaignStatus.APPROVED) {
				dispatch(
					getApprovedCampaignPage({
						pageNumber: page ?? currentPage,
						pageSize: campaignsPerPage,
					}),
				);
			} else if (val === CampaignStatus.PENDING) {
				dispatch(
					getPendingCampaignPage({
						pageNumber: page ?? currentPage,
						pageSize: campaignsPerPage,
					}),
				);
			} else if (val === CampaignStatus.ENDED) {
				dispatch(
					getEndedCampaignPage({
						pageNumber: page ?? currentPage,
						pageSize: campaignsPerPage,
					}),
				);
			} else if (val === CampaignStatus.DECLINED) {
				dispatch(
					getDeclinedCampaignPage({
						pageNumber: page ?? currentPage,
						pageSize: campaignsPerPage,
					}),
				);
			}
		},
		[campaignsPerPage, currentPage, dispatch],
	);

	useEffect(() => {
		setCards(clicked, currentPage);
	}, [campaignsPerPage, clicked, currentPage, setCards]);

	const handleRightClick = () => {
		var newPage = currentPage + 1;
		var newLastRow = countMin(currentLastRow + campaignsPerPage, totalData);
		var newFirstRow = currentLastRow + 1;
		setCurrentFirstRow(newFirstRow);
		setCurrentLastRow(newLastRow);
		setCurrentPage(newPage);
		if (!isSearched) {
			setCards(clicked, newPage);
		} else {
			searchCards(clicked, searchValue, newPage);
		}
	};

	const handleLeftClick = () => {
		var newPage = currentPage - 1;
		var newLastRow = currentFirstRow - 1;
		var newFirstRow =
			currentFirstRow - campaignsPerPage <= 0
				? 1
				: currentFirstRow - campaignsPerPage;
		setCurrentFirstRow(newFirstRow);
		setCurrentLastRow(newLastRow);
		setCurrentPage(newPage);
		if (!isSearched) {
			setCards(clicked, newPage);
		} else {
			searchCards(clicked, searchValue, newPage);
		}
	};

	const handleSearch = (value: string, page?: number) => {
		if (value !== '') {
			setIsSearched(true);
			setSearchValue(value);
			searchCards(clicked, value, 1);
			setCurrentPage(1);
			setCurrentFirstRow(1);
			setCurrentLastRow(countMin(campaignsPerPage, totalData));
		} else {
			handleClick(clicked);
		}
	};

	const handleSelectChange = (val: string) => {
		const newVal: number = Number(val) > totalData ? totalData : Number(val);
		setCurrentLastRow(Number(newVal));
		setCurrentFirstRow(1);
		setCampaignsPerPage(Number(val));
	};
	useEffect(() => {}, [campaigns]);
	function getDate(campaign: Campaign) {
		return moment(
			campaign.dates.find(date => date.name.includes('1st Live Date'))
				?.dateFrom,
		).format('DD MMM YYYY');
	}

	useEffect(() => {
		setTimeout(() => {
			handleClick(CampaignStatus.ALL);
		}, 500);
	}, []);

	useEffect(() => {
		setCurrentLastRow(countMin(campaignsPerPage, totalData));
	}, [totalData]);

	const handleRedirect = (val: string) => {
		nav(val);
	};

	useEffect(() => {
		dispatch(
			setCurrentCampaign({
				id: -1,
				name: '',
				clientId: -1,
				campaignBrief: '',
				campaignBriefUrl: '',
				targetGoals: new Array<TargetGoal>(),
				campaignSteps: new Array<number>(),
				campaignImages: new Array<CampaignImage>(),
				budgets: new Array<Budget>(),
				dates: new Array<DateLive>(),
				liveMarkets: new Array<string>(),
			}),
		);
		dispatch(
			setCreatorBrief({
				creatorBrief: {
					summary: '',
					payment: '',
					platform: '',
					objective: '',
					shootTime: '',
					usageTime: new Array<UsageTime>(),
					creativeFramework: '',
					assets: new Array<CreatorBriefAsset>(),
					campaignId: -1,
				},
				creatorBriefUrl: '',
			}),
		);
		dispatch(setCampaignMetaId(''));
		dispatch(setCreatorList([]));
	}, []);

	const handleCardClick = (id: number) => {
		dispatch(getCampaign(id.toString()));
		dispatch(getContentPlan(id.toString()));
		dispatch(getCreatorBrief(id.toString()));
		dispatch(getCreators());

		nav(`/SoW/${id}`);
	};

	return (
		<div className={styles.page}>
			<div className={styles.sidebarWrapper}>
				<Sidebar activePage="campaigns" />
			</div>
			<div className={styles.contentWrapper}>
				<div className={styles.headerWrapper}>
					<GlobalHeader label="Campaigns" onClick={handleRedirect} />
				</div>
				<div className={styles.searchWrapper}>
					<PFSearchHeader
						onClick={handleClick}
						handleSearch={handleSearch}
						label="Campaigns"
					/>
				</div>
				<div className={styles.FaTWrapper}>
					<div style={styleCards}>
						{!isLoading ? (
							<>
								{campaigns.length > 0 ? (
									campaigns.map(campaign => {
										return (
											<div className={styles.oneCard} key={campaign.id}>
												<PFCampaignCard
													onChange={() => {}}
													id={campaign.id}
													name={campaign.name}
													brandName={campaign.BrandName}
													date={getDate(campaign)}
													status={campaign.campaignStatus}
													imageUrl={
														campaign.campaignImages.length > 0
															? campaign.campaignImages[0].imageUrl
															: ''
													}
													onClick={handleCardClick}
												/>
											</div>
										);
									})
								) : (
									<div className={styles.empty}>There are no campaigns</div>
								)}
							</>
						) : (
							<div className={styles.dots}>
								<Dots size={40} color={'#3560FF'} />
							</div>
						)}
					</div>

					<div className={styles.footerWrapper}>
						<GlobalFooter
							firstRow={currentFirstRow}
							lastRow={currentLastRow}
							disabledLeft={currentFirstRow === 1 ? true : false}
							disabledRight={currentLastRow >= totalData ? true : false}
							totalData={totalData}
							campaign={true}
							handleRightClick={handleRightClick}
							handleLeftClick={handleLeftClick}
							rowsPerPage={campaignsPerPage}
							handleSelectChange={handleSelectChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const styleCards = {
	display: 'flex',
	flexWrap: 'wrap' as 'wrap',
};

export default CampaignsPage;
