import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FinalAsset} from '../../models/FinalAsset';
import {SelectedCreator} from '../../models/SelectedCreator';

interface CreatorListState {
	currentCreatorList: Array<SelectedCreator>;
	isLoading: boolean;
	currentFinalAssets: Array<FinalAsset>;
}

const initialState: CreatorListState = {
	isLoading: false,
	currentCreatorList: [],
	currentFinalAssets: [],
};

const creatorListSlice = createSlice({
	initialState,
	name: 'creatorBriefReducer',
	reducers: {
		setCreatorList(state, {payload}: PayloadAction<Array<SelectedCreator>>) {
			state.currentCreatorList = payload;
		},
		updateCreatorList(state, {payload}: PayloadAction<Array<SelectedCreator>>) {
			for (let creator of payload) {
				let index = state.currentCreatorList.findIndex(
					cr => cr.id === creator.id,
				);
				if (index !== -1) {
					state.currentCreatorList[index].status = creator.status;
				}
			}
		},
		setLoading(state, {payload}: PayloadAction<boolean>) {
			state.isLoading = payload;
		},
		setFinalAssets(
			state,
			{payload}: PayloadAction<{assets: Array<FinalAsset>; creatorId: string}>,
		) {
			state.currentCreatorList = state.currentCreatorList.map(
				(creator: SelectedCreator) => {
					if (creator.id === Number(payload.creatorId)) {
						creator.assets = payload.assets;
					}
					return creator;
				},
			);
		},
		editFinalAssets(
			state,
			{payload}: PayloadAction<{assets: Array<FinalAsset>; creatorId: string}>,
		) {
			state.currentCreatorList = state.currentCreatorList.map(
				(creator: SelectedCreator) => {
					if (creator.id === Number(payload.creatorId)) {
						creator.assets = payload.assets;
					}
					return creator;
				},
			);
		},
		setAds(
			state,
			{payload}: PayloadAction<{creatorId: string; ads: Array<string>}>,
		) {
			state.currentCreatorList = state.currentCreatorList.map(
				(creator: SelectedCreator) => {
					if (creator.id === Number(payload.creatorId)) {
						creator.ads = payload.ads;
					}
					return creator;
				},
			);
		},
	},
});

export const {
	setCreatorList,
	setLoading,
	setFinalAssets,
	editFinalAssets,
	setAds,
	updateCreatorList,
} = creatorListSlice.actions;

export default creatorListSlice.reducer;
