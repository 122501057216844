import {useEffect, useState} from 'react';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import PFModal from '../../organisms/Modal/PFModal';
import styles from './PFChangesModal.module.scss';
interface PFChangesModalProps {
	show: boolean;
	onHide: () => void;
	label?: string;
	onLeave?: () => void;
	onCancel?: () => void;
}

function PFChangesModal(props: PFChangesModalProps) {
	const {show, onHide, label, onLeave, onCancel} = props;
	const [showModal, setShowModal] = useState<boolean>(false);

	const handleCancel = () => {
		setShowModal(false);
		if (onCancel !== undefined) onCancel();
	};

	useEffect(() => {
		setShowModal(show);
	}, [show]);

	const handleLeave = () => {
		if (onLeave !== undefined) onLeave();
	};

	return (
		<div>
			<PFModal
				show={showModal}
				onHide={onHide}
				label={label}
				dialogClassName={styles.body}>
				<div className={styles.body}>
					<p>You have unsaved changes. Are you sure you want to leave?</p>
					<PFPrimaryButton
						onClick={handleLeave}
						style={`${styles.btn} `}
						text="Yes"
						type="submit"
					/>
					<PFPrimaryButton
						onClick={handleCancel}
						style={`${styles.btn} ${styles.red}`}
						text="Cancel"
						type="submit"
					/>
				</div>
			</PFModal>
		</div>
	);
}

export default PFChangesModal;
