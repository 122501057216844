import React from 'react';
import {Images} from '../../../constants/Images';
import styles from './WarningLayer.module.scss';

export const WarningLayer = () => {
	return (
		<div className={styles.bg}>
			<div className={styles.box}>
				<img src={Images.Logo} alt="logo-2" />
			</div>
			<div className={styles.text}>
				Turn orientation to landscape for better UX or open website on your
				desktop.
			</div>
		</div>
	);
};
