import axios from 'axios';
import {AdminPage} from '../models/AdminPage';
import {AdminRequest} from '../models/AdminRequest';
import {AdminsSearch} from '../models/AdminsSearch';
import {BrandPage} from '../models/BrandPage';
import {BrandsSearch} from '../models/BrandsSearch';
import {Creator} from '../models/Creator';
import {CreatorPage} from '../models/CreatorPage';
import {CreatorsSearch} from '../models/CreatorsSearch';
import {EditUser} from '../models/EditUser';
import {EditUserResponse} from '../models/EditUserResponse';
import {NewUser} from '../models/NewUser';
import {SearchRequest} from '../models/SearchRequest';
import {SelectBrand} from '../models/SelectBrand';
import {TablePageModel} from '../models/TablePageModel';
import {User} from '../models/User';
import '../interceptors/axiosInterceptor';

interface UserService {
	getAdminPage(data: TablePageModel): Promise<AdminPage | Error>;
	getCreatorPage(data: TablePageModel): Promise<CreatorPage | Error>;
	getBrandPage(data: TablePageModel): Promise<BrandPage | Error>;
	searchAdminPage(data: SearchRequest): Promise<AdminsSearch>;
	searchBrandPage(data: SearchRequest): Promise<BrandsSearch>;
	searchCreatorPage(data: SearchRequest): Promise<CreatorsSearch>;
	inviteUser(data: AdminRequest): any;
	editUser(data: EditUser): Promise<EditUserResponse>;
	deleteUser(data: User): any;
	getAllBrands(): Promise<Array<SelectBrand>>;
	getAllCreators(): Promise<Array<Creator>>;
	getDataDeletionDate(token: string): Promise<string>;
	getFilteredCreators(
		filter: string,
		orderBy: string,
		pageSize: number,
		pageNumber: number,
	): Promise<CreatorPage>;
}

const ENDPOINTS = {
	BRANDS: `${process.env.REACT_APP_BASE_URL}/api/Client/clients`,
	ADMINS: `${process.env.REACT_APP_BASE_URL}/api/Admin/admins`,
	CREATORS: `${process.env.REACT_APP_BASE_URL}/api/Creator/creators`,
	ALL_CREATORS: `${process.env.REACT_APP_BASE_URL}/api/Creator/all-creators`,
	SEARCH: `${process.env.REACT_APP_BASE_URL}/api/User/search`,
	ADD_ADMIN: `${process.env.REACT_APP_BASE_URL}/api/Admin/register-system-admin`,
	INVITE_USER: `${process.env.REACT_APP_BASE_URL}/api/User/invite`,
	EDIT_USER: `${process.env.REACT_APP_BASE_URL}/api/User/user-status`,
	DELETE_USER: `${process.env.REACT_APP_BASE_URL}/api/Admin/user`,
	ACTIVATE_USER: `${process.env.REACT_APP_BASE_URL}/api/Admin/user/`,
	GET_BRANDS: `${process.env.REACT_APP_BASE_URL}/api/Client/all-clients`,
	DATA_DELETION_DATE: `${process.env.REACT_APP_BASE_URL}/api/Facebook/data-deletion/`,
	FILTER_CREATORS: `${process.env.REACT_APP_BASE_URL}/api/Creator/creators-filtered`,
	FILTER_BRANDS: `${process.env.REACT_APP_BASE_URL}/api/Client/clients-filtered`,
	FILTER_ADMINS: `${process.env.REACT_APP_BASE_URL}/api/Admin/admins-filtered`,
};

class UserAxiosService implements UserService {
	async getAllCreators(): Promise<Creator[]> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(ENDPOINTS.ALL_CREATORS, {
				headers: {
					Authorization: 'Bearer ' + Token,
				},
			});
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}
	async getAdminPage(data: TablePageModel): Promise<AdminPage> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.ADMINS +
					'?pageNumber=' +
					data.pageNumber +
					'&pageSize=' +
					data.pageSize,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}
	async getCreatorPage(data: TablePageModel): Promise<CreatorPage> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.CREATORS +
					'?pageNumber=' +
					data.pageNumber +
					'&pageSize=' +
					data.pageSize,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}
	async getBrandPage(data: TablePageModel): Promise<BrandPage> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.BRANDS +
					'?pageNumber=' +
					data.pageNumber +
					'&pageSize=' +
					data.pageSize,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async searchAdminPage(data: SearchRequest): Promise<AdminsSearch> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.SEARCH +
					'?input=' +
					data.value +
					'&role=0' +
					'&pageNumber=' +
					data.pageNumber +
					'&pageSize=' +
					data.pageSize,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async searchBrandPage(data: SearchRequest): Promise<BrandsSearch> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.SEARCH +
					'?input=' +
					data.value +
					'&role=2' +
					'&pageNumber=' +
					data.pageNumber +
					'&pageSize=' +
					data.pageSize,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async searchCreatorPage(data: SearchRequest): Promise<CreatorsSearch> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.SEARCH +
					'?input=' +
					data.value +
					'&role=3' +
					'&pageNumber=' +
					data.pageNumber +
					'&pageSize=' +
					data.pageSize,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async addAdmin(data: AdminRequest) {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.post(ENDPOINTS.ADD_ADMIN, data, {
				headers: {
					Authorization: 'Bearer ' + Token,
				},
			});
			return response.data;
		} catch (error: any) {
			return {error: error};
		}
	}

	async inviteUser(data: NewUser): Promise<any> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.post(ENDPOINTS.INVITE_USER, data, {
				headers: {
					Authorization: 'Bearer ' + Token,
				},
			});
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async editUser(data: EditUser): Promise<EditUserResponse> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.put(ENDPOINTS.EDIT_USER, data, {
				headers: {
					Authorization: 'Bearer ' + Token,
				},
			});
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async deleteUser(data: User) {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.delete(
				ENDPOINTS.DELETE_USER + '?id=' + data.id,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async activateUser(data: User) {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.put(
				ENDPOINTS.ACTIVATE_USER + data.id,
				{},
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async getAllBrands(): Promise<Array<SelectBrand>> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(ENDPOINTS.GET_BRANDS, {
				headers: {
					Authorization: 'Bearer ' + Token,
				},
			});
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async getDataDeletionDate(token: string): Promise<string> {
		try {
			const response = await axios.get(
				ENDPOINTS.DATA_DELETION_DATE + token,
				{},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async getFilteredCreators(
		filter: string,
		orderBy: string,
		pageSize: number,
		pageNumber: number,
	): Promise<CreatorPage> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.FILTER_CREATORS +
					'?pageNumber=' +
					pageNumber +
					'&pageSize=' +
					pageSize +
					'&filter=' +
					filter +
					'&orderBy=' +
					orderBy,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async getFilteredBrands(
		filter: string,
		orderBy: string,
		pageSize: number,
		pageNumber: number,
	): Promise<BrandPage> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.FILTER_BRANDS +
					'?pageNumber=' +
					pageNumber +
					'&pageSize=' +
					pageSize +
					'&filter=' +
					filter +
					'&orderBy=' +
					orderBy,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async getFilteredAdmins(
		filter: string,
		orderBy: string,
		pageSize: number,
		pageNumber: number,
	): Promise<AdminPage> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');
		try {
			const response = await axios.get(
				ENDPOINTS.FILTER_ADMINS +
					'?pageNumber=' +
					pageNumber +
					'&pageSize=' +
					pageSize +
					'&filter=' +
					filter +
					'&orderBy=' +
					orderBy,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}
}

export default new UserAxiosService();
