import {Formik, Field} from 'formik';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {Images} from '../../../constants/Images';
import {ForgotPasswordModel} from '../../../models/ForgotPasswordModel';
import {PFFormikTextInput} from '../../UI/atoms/FormikTextInput/PFFormikTextInput';
import PFPrimaryButton from '../../UI/atoms/PrimaryButton/PFPrimaryButton';
import {forgotPassword} from '../../../store/actions/AuthActions';
import styles from './ForgotPassword.module.scss';
import {ForgotPasswordValidationScheme} from '../../../validation/ForgotPasswordValidationScheme';
interface Props {}

function ForgotPassword(props: Props) {
	const {} = props;
	const dispatch = useDispatch<any>();
	const getInitialValues = () => {
		return {email: ''} as ForgotPasswordModel;
	};

	return (
		<div className={styles.body}>
			<div className={styles.form}>
				<div>
					<div className={styles.logoWrapper1}>
						<img src={Images.FourDots} alt="logo" />
					</div>
					<div className={styles.logoWrapper2}>
						<img
							src={Images.Performative}
							alt="logo-2"
							className={styles.logo}
						/>
					</div>
					<p className={styles.moto}>Social works with creators.</p>
					<div className={styles.itemsWrapper}>
						<Formik
							initialValues={getInitialValues()}
							validationSchema={ForgotPasswordValidationScheme}
							onSubmit={(values: any) => {
								const data: ForgotPasswordModel = {
									email: values.email,
								};
								dispatch(forgotPassword(data));
							}}>
							{formik => (
								<div>
									<Field
										label="Email"
										component={PFFormikTextInput}
										placeholder="Enter your email"
										name="email"
										type="text"
										styleForInput={styles.inputBox}
										value=""
									/>
									<li>
										<Link to="/login" className={styles.back}>
											&lt; Back to login
										</Link>
									</li>
									<li className={`${styles.buttonWrapper}  ${styles.formItem}`}>
										<PFPrimaryButton
											onClick={() => formik.handleSubmit()}
											text="RESET PASSWORD"
											type="submit"
										/>
									</li>
								</div>
							)}
						</Formik>
					</div>
				</div>
				<img
					src={Images.Warmstorm}
					alt="warmstorm-logo"
					className={styles.bottomLogo}
				/>
			</div>
			<div className={styles.background}></div>
		</div>
	);
}

export default ForgotPassword;
