// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DeleteUserModal_body__dDI8C {\n  width: 420px;\n}\n\n.DeleteUserModal_btn__hNTS\\+ {\n  width: 150px;\n  margin: 10px 20px 0px 20px;\n}\n\n.DeleteUserModal_red__zIhVN {\n  background-color: red;\n  border: 2px solid red;\n}\n.DeleteUserModal_red__zIhVN:hover {\n  cursor: pointer;\n  background-color: rgb(255, 73, 73);\n  border: 2px solid rgb(255, 73, 73);\n}", "",{"version":3,"sources":["webpack://./src/components/UI/molecules/DeleteUserModal/DeleteUserModal.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACC,YAAA;EACA,0BAAA;AACD;;AAEA;EACI,qBAAA;EACA,qBAAA;AACJ;AAAI;EACI,eAAA;EACA,kCAAA;EACA,kCAAA;AAER","sourcesContent":[".body{\n    width: 420px;\n}\n\n.btn {\n\twidth: 150px;\n\tmargin: 10px 20px 0px 20px;\n}\n\n.red{\n    background-color: red;\n    border: 2px solid red;\n    &:hover {\n        cursor: pointer;\n        background-color: rgb(255, 73, 73);\n        border: 2px solid rgb(255, 73, 73);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "DeleteUserModal_body__dDI8C",
	"btn": "DeleteUserModal_btn__hNTS+",
	"red": "DeleteUserModal_red__zIhVN"
};
export default ___CSS_LOADER_EXPORT___;
