import React, {useRef, useState} from 'react';
import {Icons} from '../../../../constants/Icons';
import styles from './PFTextInput.module.scss';

interface PFTextInputProps {
	label: string;
	placeholder?: string;
	style?: string;
	styleForInput?: string;
	styleForErrorIcon?: string;
	value: string;
	tooltip?: string;
	onChange: (text: string) => void;
	submitOnEnter?: boolean;
	editable?: boolean;
}

export const PFTextInput: React.FC<PFTextInputProps> = props => {
	const {
		onChange,
		label,
		placeholder,
		style = {},
		styleForInput = {},
		value,
		tooltip,
		submitOnEnter = false,
		editable = true,
	} = props;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [focused, setFocused] = useState(false);
	const [val, setVal] = useState(value);
	const inputRef = useRef(null);

	const handleOnFocus = () => {
		setFocused(true);
	};

	const something = (event: any) => {
		if (submitOnEnter) {
			if (event.keyCode === 13) {
				onChange(event.target.value);
				// @ts-ignore
				inputRef.current.value = '';
			}
		} else {
			onChange(event.target.value);
		}
	};

	const handleOnArrowClick = () => {
		if (val !== '') {
			onChange(val);
			// @ts-ignore
			inputRef.current.value = '';
		}
	};

	return (
		<div className={styles.col}>
			<label className={`${styles.label}  ${style}`}>{label}</label>
			<div className={styles.row}>
				{submitOnEnter ? (
					<input
						ref={inputRef}
						title={tooltip}
						disabled={!editable}
						placeholder={placeholder}
						defaultValue={value as string}
						autoCapitalize="none"
						className={`${styles.input} ${styleForInput}`}
						onFocus={handleOnFocus}
						type={'text'}
						onChange={e => {
							setVal(e.target.value);
						}}
						onKeyDown={e => something(e)}
						onBlur={() => {
							setFocused(false);
						}}
					/>
				) : (
					<input
						title={tooltip}
						disabled={!editable}
						placeholder={placeholder}
						defaultValue={value as string}
						autoCapitalize="none"
						className={`${styles.input} ${styleForInput}`}
						onFocus={handleOnFocus}
						type={'text'}
						onChange={e => onChange(e.target.value)}
						onBlur={() => {
							setFocused(false);
						}}
					/>
				)}
				{submitOnEnter && (
					<span onClick={handleOnArrowClick} className={styles.arrowIcon}>
						{Icons.ARROW_ICON}
					</span>
				)}
			</div>
		</div>
	);
};
