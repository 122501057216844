class JwtUtilServices {
	parseJwt = (): boolean => {
		try {
			let tokenValid = false;
			const token: string | null = localStorage.getItem('token');
			if (token !== null) {
				const decodedJWT = JSON.parse(atob(token.split('.')[1]));
				if (decodedJWT.exp * 1000 > Date.now()) {
					tokenValid = true;
					return tokenValid;
				}
			}
			return tokenValid;
		} catch (e) {
			return false;
		}
	};
}

export default new JwtUtilServices();
