import {Field, Formik} from 'formik';
import React from 'react';
import PFModal from '../../organisms/Modal/PFModal';
import styles from './PFUserModal.module.scss';
import {PFFormikTextInput} from '../../atoms/FormikTextInput/PFFormikTextInput';
import 'bootstrap/dist/css/bootstrap.css';
import {UserModalValidationScheme} from '../../../../validation/UserModalValidationScheme';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import {NewUser} from '../../../../models/NewUser';
import {Users} from '../../../../constants/Users';

interface PFUserModalProps {
	onClick: (email: string) => void;
	onHide: () => void;
	show: boolean;
	label?: string;
}
export const PFUserModal: React.FC<PFUserModalProps> = ({
	onClick,
	onHide,
	show = false,
	label,
}) => {
	return (
		<PFModal show={show} onHide={onHide} label={label}>
			<div>
				<Formik
					initialValues={{email: ''}}
					validationSchema={UserModalValidationScheme}
					onSubmit={(values: NewUser) => {
						onClick(values.email);
					}}>
					{formik => (
						<>
							<div className={styles.divPadd}>
								<Field
									label="Email Address"
									component={PFFormikTextInput}
									placeholder="Enter Email"
									name="email"
									type="text"
									value=""
									style={styles.formikInput}
									styleForInput={styles.styleForInput}
									styleForErrorLabel={styles.styleForErrorLabel}
									styleForErrorIcon={styles.styleForErrorIcon}
								/>
							</div>
							<div>
								<PFPrimaryButton
									onClick={() => formik.handleSubmit()}
									text={label === Users.ADMIN ? 'CREATE USER' : 'INVITE USER'}
									type="submit"
									style={styles.btn}></PFPrimaryButton>
							</div>
						</>
					)}
				</Formik>
			</div>
		</PFModal>
	);
};

export default PFUserModal;
