import React from 'react';
import styles from './PFLiveMarketSelectMenu.module.scss';
import Select from 'react-select';
import {Countries} from '../../../../constants/Countries';
import {Country} from '../../../../models/Country';

interface PFLiveMarketSelectMenuProps {
  onChange: (retVal: string | null) => void;
  chosenCountries: Array<Country>;
}

export const PFLiveMarketSelectMenu: React.FC<PFLiveMarketSelectMenuProps> = ({
  onChange,
  chosenCountries,
}) => {
  let validCountries = Countries;
  chosenCountries.map(
    chosen =>
      (validCountries = validCountries.filter(c => c.label !== chosen.name)),
  );

  return (
    <Select
      className={styles.select}
      options={validCountries}
      onChange={e => onChange(e!.label)}
    />
  );
};

export default PFLiveMarketSelectMenu;
