import React from 'react';
import styles from './PFSelectMenu.module.scss';

interface PFSelectMenuProps {
	onChange: (retVal: string) => void;
	defaultValue: number;
}

export const PFSelectMenu: React.FC<PFSelectMenuProps> = ({
	onChange,
	defaultValue,
}) => {
	return (
		<select
			className={styles.box}
			onChange={e => onChange(e.target.value)}
			defaultValue={defaultValue}>
			<option value="5" className={styles.option}>
				5
			</option>
			<option value="10" className={styles.option}>
				10
			</option>
			<option value="15" className={styles.option}>
				15
			</option>
		</select>
	);
};

export default PFSelectMenu;
