import 'bootstrap/dist/css/bootstrap.css';
import {Field, Formik} from 'formik';
import React, {useRef, useState} from 'react';
import {CampaignModals} from '../../../../constants/CampaignModals';
import {Icons} from '../../../../constants/Icons';
import {Images} from '../../../../constants/Images';
import {PFFormikTextInput} from '../../atoms/FormikTextInput/PFFormikTextInput';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';
import PFModal from '../../organisms/Modal/PFModal';
import styles from './PFFinalAssetModal.module.scss';

interface FinalAssetFormik {
	description: string;
}

interface PFFinalAssetModalProps {
	onClick: (
		asset: FormData,
		description: string,
		file: any,
		type: string,
	) => void;
	onHide: () => void;
	show: boolean;
	size?: 'sm' | 'lg';
}
export const PFFinalAssetModal: React.FC<PFFinalAssetModalProps> = ({
	onClick,
	onHide,
	show = false,
	size,
}) => {
	const [image, setImage] = useState<any>();
	const [showImage, setShowImage] = useState<any>();
	const [typeOfAsset, setTypeOfAsset] = useState('');

	const imageUploadRef = useRef<HTMLInputElement>(null);

	const initialValues: FinalAssetFormik = {description: ''};

	const handleImageUpload = (e: any) => {
		setImage(e.target.files[0]);
		const type = e.target.files[0].type.split('/')[0];
		setTypeOfAsset(type === 'image' ? 'Photo' : 'Video');
		setShowImage(URL.createObjectURL(e.target.files[0]));
		imageUploadRef.current!.value = '';
	};

	const setAssetImage = () => {
		return imageUploadRef.current?.click();
	};

	const handleOnRemoveAsset = () => {
		setImage('');
		setShowImage(undefined);
	};
	const handleOnHide = () => {
		imageUploadRef.current!.value = '';
		setImage('');
		setShowImage(undefined);
		onHide();
	};

	return (
		<PFModal
			size={size}
			show={show}
			onHide={handleOnHide}
			label={CampaignModals.ADD_ASSET}>
			<div>
				<Formik
					initialValues={initialValues}
					onSubmit={(values: FinalAssetFormik) => {
						const formData = new FormData();
						formData.append('Asset', image as File);
						formData.append('description', values.description);

						onClick(formData, values.description, image, typeOfAsset);
						imageUploadRef.current!.value = '';
						setImage('');
						setShowImage(undefined);
					}}>
					{formik => (
						<>
							<div className={styles.col}>
								{showImage ? (
									<>
										Asset Preview
										<div style={styleCards} className={styles.cards}>
											{typeOfAsset === 'Video' ? (
												<video
													controls
													className={styles.image}
													height={270}
													width={240}>
													<source src={showImage}></source>
												</video>
											) : (
												<img
													className={styles.image}
													src={showImage}
													alt={'Final Asset'}></img>
											)}
											<div className={styles.bin} onClick={handleOnRemoveAsset}>
												{Icons.BIN}
											</div>
										</div>
									</>
								) : (
									<div
										className={styles.btnAfile}
										onClick={() => setAssetImage()}>
										<img
											alt={'Choose'}
											src={Images.Upload}
											className={styles.upload}></img>
									</div>
								)}

								<input
									ref={imageUploadRef}
									type={'file'}
									style={{display: 'none'}}
									accept="image/png, image/jpeg, video/mp4, video/*"
									onChange={handleImageUpload}
								/>

								<div className={styles.input}>
									<Field
										label="Description *"
										component={PFFormikTextInput}
										placeholder="Enter asset description"
										name="description"
										type="string"
										value=""
										textArea={true}
									/>
								</div>
								<div>
									<PFPrimaryButton
										onClick={() => formik.handleSubmit()}
										text={'ADD FINAL ASSET'}
										disabled={formik.values.description === '' || image === ''}
										type="submit"
										style={
											formik.values.description === '' || image === ''
												? styles.disabled
												: styles.btn
										}></PFPrimaryButton>
								</div>
							</div>
						</>
					)}
				</Formik>
			</div>
		</PFModal>
	);
};

const styleCards = {
	display: 'flex',
	flexWrap: 'wrap' as 'wrap',
};
export default PFFinalAssetModal;
