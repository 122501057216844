export const creatorTabs = [
	'All',
	'Suggested',
	'Pending',
	'Interested',
	'Not Interested',
	'Accepted',
	'Declined',
	'Rejected',
	'Live',
];
