// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PFBrandTableRow_checkbox__FGdwI {\n  box-sizing: border-box;\n  width: 16px;\n  height: 16px;\n  /* white */\n  background: #ffffff;\n  /* black8 */\n  border: 1px solid rgba(0, 0, 0, 0.08);\n  /* Inside auto layout */\n  flex: none;\n  order: 0;\n  flex-grow: 0;\n}\n\n:checked {\n  background: #284bcb;\n}\n\n.PFBrandTableRow_icons__YCAuc {\n  cursor: pointer;\n}\n\n.PFBrandTableRow_status__0qt3S {\n  font-weight: bold;\n}\n\n.PFBrandTableRow_tr__g2Xju {\n  height: 40px;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/atoms/BrandTableRow/PFBrandTableRow.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EAEA,WAAA;EACA,YAAA;EAEA,UAAA;EAEA,mBAAA;EACA,WAAA;EAEA,qCAAA;EAEA,uBAAA;EAEA,UAAA;EACA,QAAA;EACA,YAAA;AALD;;AAOA;EACC,mBAAA;AAJD;;AAOA;EACC,eAAA;AAJD;;AAOA;EACC,iBAAA;AAJD;;AAOA;EACC,YAAA;AAJD","sourcesContent":[".checkbox {\n\tbox-sizing: border-box;\n\n\twidth: 16px;\n\theight: 16px;\n\n\t/* white */\n\n\tbackground: #ffffff;\n\t/* black8 */\n\n\tborder: 1px solid rgba(0, 0, 0, 0.08);\n\n\t/* Inside auto layout */\n\n\tflex: none;\n\torder: 0;\n\tflex-grow: 0;\n}\n:checked {\n\tbackground: #284bcb;\n}\n\n.icons {\n\tcursor: pointer;\n}\n\n.status {\n\tfont-weight: bold;\n}\n\n.tr {\n\theight: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": "PFBrandTableRow_checkbox__FGdwI",
	"icons": "PFBrandTableRow_icons__YCAuc",
	"status": "PFBrandTableRow_status__0qt3S",
	"tr": "PFBrandTableRow_tr__g2Xju"
};
export default ___CSS_LOADER_EXPORT___;
