import React, {useState, useEffect} from 'react';
import {Icons} from '../../../../constants/Icons';
import styles from './PFCreatorListTableRow.module.scss';
import Select from 'react-select';
import {Option} from 'react-multi-select-component';
import {creatorTabs} from '../../../../constants/CreatorTabs';

interface PFCreatorListTableRowProps {
	onStatusChange?: (id: number, val: string) => void;
	onRemove?: (email: string) => void;
	fullName?: string;
	facebookLink?: string;
	instagramLink?: string;
	tiktokLink?: string;
	email?: string;
	phoneNumber?: string;
	status?: string;
	id: number;
	newCreator?: boolean;
	editable?: boolean;
}

export const PFCreatorListTableRow: React.FC<PFCreatorListTableRowProps> = ({
	onStatusChange,
	onRemove,
	fullName,
	facebookLink,
	instagramLink,
	tiktokLink,
	email,
	phoneNumber,
	status,
	id,
	newCreator = false,
	editable = true,
}) => {
	const instagramValid = instagramLink !== '' && instagramLink !== null;
	const tiktokValid = tiktokLink !== '' && tiktokLink !== null;
	const handleInstagramLink = () => {
		window.open(`https://www.instagram.com/${instagramLink}/?hl=en`);
	};
	const handleTiktokLink = () => {
		window.open(`https://www.tiktok.com/@${tiktokLink}?lang=en`);
	};
	const [select, setSelect] = useState(false);
	const [currStatus, setStatus] = useState(status);

	const handleOnSelectChange = (e: any) => {
		setStatus(e.value);
		setSelect(false);
		onStatusChange && onStatusChange(id, e.value);
	};

	useEffect(() => {
		setStatus(status);
	}, [status]);

	const getOptions = () => {
		let options: Array<Option> = [];
		creatorTabs.forEach(c => {
			if (c !== 'All') {
				options.push({value: c, label: c});
			}
		});
		return options;
	};

	const handleOnCreatorRemove = () => {
		onRemove && email && onRemove(email);
	};

	return (
		<tr className={styles.tr}>
			<td className={`${styles.label}`}>{fullName}</td>
			<td>
				<span
					title="Open profile on Instagram"
					className={instagramValid ? styles.icons : styles.grayIcons}
					style={stylesObject.instagram}
					onClick={instagramValid ? handleInstagramLink : () => {}}>
					{instagramValid ? Icons.INSTAGRAM : Icons.INSTAGRAM_GRAY}
				</span>
				<span
					title="Open profile on TikTok"
					className={tiktokValid ? styles.icons : styles.grayIcons}
					style={stylesObject.tiktok}
					onClick={instagramValid ? handleTiktokLink : () => {}}>
					{tiktokValid ? Icons.TIKTOK : Icons.TIKTOK_GRAY}
				</span>
			</td>
			<td>{email}</td>
			<td>{phoneNumber}</td>
			<td className={styles.status}>
				{!select ? (
					<>
						<span
							style={
								currStatus === 'Declined' ||
								currStatus === 'Rejected' ||
								currStatus === 'Not Interested'
									? stylesObject.red
									: currStatus === 'Pending'
									? stylesObject.orange
									: stylesObject.green
							}>
							{currStatus}
						</span>
						{!newCreator && editable && (
							<span
								className={styles.edit}
								onClick={() => setSelect(true)}
								title="Edit creator status">
								{Icons.EDIT}
							</span>
						)}
					</>
				) : (
					<Select
						onChange={handleOnSelectChange}
						options={getOptions()}
						className={styles.select}
					/>
				)}
			</td>
			{newCreator && (
				<td>
					<span className={styles.edit} onClick={handleOnCreatorRemove}>
						{Icons.BIN}
					</span>
				</td>
			)}
		</tr>
	);
};

const stylesObject = {
	red: {
		color: 'rgba(234, 67, 53, 1)',
	},
	orange: {
		color: 'rgba(251, 139, 2, 1)',
	},
	green: {
		color: 'rgba(104, 204, 88, 1)',
	},
	pending: {
		paddingRight: '15px',
	},
	facebook: {
		float: 'left' as 'left',
		paddingRight: '12px',
		paddingBottom: '1px',
	},
	instagram: {
		float: 'left' as 'left',
		paddingRight: '12px',
	},
	tiktok: {
		float: 'left' as 'left',
		paddingTop: '-1px',
	},
};

export default PFCreatorListTableRow;
