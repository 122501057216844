import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {NotificationPage} from '../../models/NotificationPage';
import {Notification} from '../../models/Notifications';
interface NotificationState {
	token: string | null;
	isFound: boolean;
	notifications: NotificationPage;
	hasUnreadNotifications: boolean;
}

const initialState: NotificationState = {
	token: 'None',
	isFound: false,
	notifications: {
		currentPage: 0,
		notifications: [],
		totalData: 0,
		totalPages: 0,
	},
	hasUnreadNotifications: false,
};

const notificationSlice = createSlice({
	initialState,
	name: 'notificationReducer',
	reducers: {
		setToken(
			state,
			{payload}: PayloadAction<{token: string | null; isFound: boolean}>,
		) {
			state.token = payload.token;
			state.isFound = payload.isFound;
		},
		setNotifications(state, {payload}: PayloadAction<NotificationPage>) {
			state.notifications = payload;
		},
		removeToken(state) {
			state.token = null;
			state.isFound = false;
		},
		setHasUnreadNotifications(state, {payload}: PayloadAction<boolean>) {
			state.hasUnreadNotifications = payload;
		},
		addNewNotification(state, {payload}: PayloadAction<Notification>) {
			state.notifications.notifications.pop();
			state.notifications.notifications = [
				payload,
				...state.notifications.notifications,
			];
		},
	},
});

export const {
	setToken,
	removeToken,
	setNotifications,
	setHasUnreadNotifications,
	addNewNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;
