import {useEffect} from 'react';
import PFSoW from '../../UI/organisms/SoW/PFSoW';
import PFCampaignHeader from '../../UI/organisms/CampaignHeader/PFCampaignHeader';
import Sidebar from '../../UI/molecules/sidebar/Sidebar';
import styles from './EditCampaignPage.module.scss';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers/RootReducer';
import {CampaignBrief} from '../../../models/CampaignBrief';
import {useParams} from 'react-router-dom';
import {getCampaign} from '../../../store/actions/CampaignActions';
import {getContentPlan} from '../../../store/actions/ContentPlanActions';
import {getCreatorList} from '../../../store/actions/CreatorListActions';
import {getCreatorBrief} from '../../../store/actions/CreatorBriefActions';

interface Props {}

function EditCampaignPage(props: Props) {
	const campaign: CampaignBrief = useSelector(
		(state: RootState) => state.campaign.currentCampaign,
	);
	const id = useParams();

	const dispatch = useDispatch<any>();

	useEffect(() => {
		if (id.id !== undefined) {
			dispatch(getCampaign(id.id));
			dispatch(getContentPlan(id.id.toString()));
			dispatch(getCreatorList(id.id));
			dispatch(getCreatorBrief(id.id));
		}
	}, [dispatch, id.id]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			{campaign !== undefined && (
				<div className={styles.page}>
					<div className={styles.sidebarWrapper}>
						<Sidebar activePage="campaigns" />
					</div>
					<div className={styles.contentWrapper}>
						<div className={styles.headerWrapper}>
							<PFCampaignHeader
								onClick={() => {}}
								handleSearch={() => {}}
								label="Edit Campaign"
							/>
						</div>
						<div className={styles.FaTWrapper}>
							<PFSoW text={'edit'} />
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default EditCampaignPage;
