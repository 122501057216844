import React from 'react';
import {Icons} from '../../../../constants/Icons';
import {CreatorBriefAsset} from '../../../../models/CreatorBriefAsset';
import styles from './PFCreatorBriefAssetCard.module.scss';

interface PFCreatorBriefAssetCardProps {
	asset: CreatorBriefAsset;
	onDelete: (id: number) => void;
	editable?: boolean;
}

export const PFCreatorBriefAssetCard: React.FC<
	PFCreatorBriefAssetCardProps
> = ({asset, onDelete, editable = true}) => {
	const title = () => {
		if (asset.type === 0) {
			return 'VIDEO';
		} else if (asset.type === 1) {
			return 'COVER IMAGE';
		} else {
			return 'STILL IMAGE';
		}
	};
	const icon = () => {
		if (asset.type === 0) {
			return Icons.VIDEO;
		}
		return Icons.IMAGE;
	};

	const renderLength = () => {
		if (asset.length) {
			return asset.length.map((l, index) => (
				<span key={index}>
					{`${l}'`}
					{asset.length && index < asset.length?.length - 1 && <span>,</span>}
				</span>
			));
		}
		return;
	};
	const handleDelete = () => {
		asset.id && onDelete(asset.id);
	};
	return (
		<div className={styles.container}>
			<div className={styles.row}>
				<span className={styles.title}>
					{title()} {icon()}
				</span>

				{editable && (
					<div
						className={styles.bin}
						title="Delete asset"
						onClick={handleDelete}>
						{Icons.X}
					</div>
				)}
			</div>
			<div>
				<span className={styles.itemTitle}>
					quantity: <span className={styles.itemText}>{asset.quantity}</span>
				</span>
			</div>
			<div>
				<span className={styles.itemTitle}>
					format: <span className={styles.itemText}>{asset.format}</span>
				</span>
			</div>
			{asset.length && asset.length.length > 0 && (
				<div>
					<span className={styles.itemTitle}>
						length: <span className={styles.itemText}>{renderLength()}</span>
					</span>
				</div>
			)}
		</div>
	);
};

export default PFCreatorBriefAssetCard;
