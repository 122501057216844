import React from 'react';
import styles from './GlobalHeader.module.scss';
import PFPrimaryButton from '../../atoms/PrimaryButton/PFPrimaryButton';

interface GlobalHeaderProps {
	label: string;
	hasButton?: boolean;
	onClick?: (path: string) => void;
}

const GlobalHeader: React.FC<GlobalHeaderProps> = ({
	label = '',
	hasButton = true,
	onClick,
}) => {
	const handleOnClick = () => {
		onClick && onClick('/create-campaign');
	};

	return (
		<div className={styles.headerWrapper}>
			<div className={styles.header}>
				<div className={styles.label}>{label}</div>
				{hasButton && (
					<ul className={styles.itemsWrapper}>
						<li className={`${styles.buttonWrapper}  ${styles.formItem}`}>
							<PFPrimaryButton
								onClick={handleOnClick}
								text={label === 'Campaigns' ? 'CREATE CAMPAIGN' : 'ADD USERS'}
								type="submit"
								style={styles.addBtn}
							/>
						</li>
					</ul>
				)}
			</div>
		</div>
	);
};

export default GlobalHeader;
