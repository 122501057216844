export const Currency = [
	{value: 0, label: '€'},
	{value: 1, label: '$'},
	{value: 2, label: '£'},
];

export const IntToCurrency = (value: number) => {
	switch (value) {
		case 0:
			return Currency[0].label;
		case 1:
			return Currency[1].label;
		case 2:
			return Currency[2].label;
		default:
			return Currency[0].label;
	}
};
