export const Colors = {
    BLACK: '#000000',
    BLACK_10: 'rgba(0,0,0,0.1)',
    BLACK_60: 'rgba(0,0,0,0.6)',
    BLACK_5: 'rgba(0,0,0,0.05)',
    BLACK_8: 'rgba(0,0,0,0.08)',
    BLACK_16: 'rgba(0,0,0,0.16)',
    DARK_GREY: '#1E1E1E',
    GREY: '#555555',
    WARNING_YELLOW: '#fdf4b0',
    LIGHT_GREY: '#818181',
    PALE_GREY: '#C4C4C4',
    BACKGROUND_GREY: '#F5F5F5',
    WHITE: '#FFFFFF',
    WHITE_20: 'rgba(255,255,255,0.2)',
    DARK_ORANGE: '#D5573B',
    STORM_BLUE: '#60A2B6',
    SAND_ORANGE: '#E58032',
    PENDING_ORANGE: 'rgba(255,155,49,0.1)',
    GREEN: '#00FF5C',
    PURPLE: '#5C00FF',
    MAGENTA: '#BD2799',
    PINK: '#E428B7',
    RED: '#EA4335',
    RED_50: '#C3000080',
    RED_80: 'rgba(195,0,0,0.8)',
    RED_8: 'rgba(195,0,0,0.08)',
    NOTIFICATION: '#EF5A5A',
    RADIAL_LG: ['#274ACA', '#DD46B2'],
    LIGHT_RED: '#C300000A',
    PASTEL_PURPLE: '#BECCFF',
    ORANGE: '#FF9B31',
    ACTIVE_GREEN: '#68CC58',
    ACCEPTED_GREEN: 'rgba(104,204,88,0.08)',
    BG_GRAY: '#FAFAFA',
    BLUE: '#284BCB',
    SUCCESS_GREEN: 'rgba(104, 204, 88, 1)',
  };
  