import React, {useState} from 'react';
import {getCreatorStatus} from '../../../../helper/helper';
import {SelectedCreator} from '../../../../models/SelectedCreator';
import PFCreatorListTableRow from '../../atoms/CreatorListTableRow/PFCreatorListTableRow';
import PFSortButton from '../../atoms/SortButton/PFSortButton';
import PFExpandableTableRow from '../ExpandableTableRow/PFExpandableTableRow';
import styles from './PFCreatorListTable.module.scss';

interface PFCreatorListTableProps {
	users: Array<SelectedCreator>;
	onStatusChange?: (id: number, value: string) => void;
	onRemove?: (email: string) => void;
	newCreator?: boolean;
	tableKey?: number;
	isExpandable?: boolean;
	isFinalAsset?: boolean;
	campaignId?: string;
	hasCampaignEnded: boolean;
}

type SortKeys = 'email' | 'fullName' | 'status';
type SortOrder = 'asc' | 'desc';

const PFCreatorListTable: React.FC<PFCreatorListTableProps> = ({
	users,
	onStatusChange,
	onRemove,
	tableKey,
	newCreator = false,
	isExpandable = false,
	isFinalAsset = false,
	campaignId,
	hasCampaignEnded,
}) => {
	const [sortKey, setSortKey] = useState<SortKeys>('email');
	const [sortOrder, setSortOrder] = useState<SortOrder>('asc');

	const sortedData = () => {
		if (!sortKey) return users;
		const sortedData = [...users].sort((a, b) => {
			return a[sortKey!]! >= b[sortKey!]! ? 1 : -1;
		});
		if (sortOrder === 'desc') {
			return sortedData.reverse();
		}
		return sortedData;
	};

	const changeSort = (key: SortKeys) => {
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
		setSortKey(key);
	};

	return (
		<div className={`${styles.table}`} key={tableKey}>
			<table id={styles.users}>
				<thead>
					<tr>
						<th>
							Full Name
							<PFSortButton
								columnKey="Name"
								onClick={() => changeSort('fullName')}
								{...{
									sortOrder,
									sortKey,
								}}
							/>
						</th>
						<th>Social Media</th>
						<th>
							Email
							<PFSortButton
								columnKey="email"
								onClick={() => changeSort('email')}
								{...{
									sortOrder,
									sortKey,
								}}
							/>
						</th>
						<th>Phone Number</th>
						<th>
							Status
							<PFSortButton
								columnKey="status"
								onClick={() => changeSort('status')}
								{...{
									sortOrder,
									sortKey,
								}}
							/>
						</th>
					</tr>
				</thead>
				<tbody>
					{isExpandable
						? sortedData().map(user => {
								return (
									<PFExpandableTableRow
										isFinalAsset={isFinalAsset}
										id={user.id}
										key={user.id}
										fullName={user.fullName}
										facebookLink=""
										instagramLink={user.instagramHandle}
										tiktokLink={user.tikTokHandle}
										email={user.email}
										phoneNumber={user.phoneNumber}
										status={getCreatorStatus(user.status)}
										campaignId={campaignId ? campaignId : ''}
										performance={user.creatorPerformance}
										editable={!hasCampaignEnded}
									/>
								);
						  })
						: sortedData().map(user => {
								return (
									<PFCreatorListTableRow
										onRemove={onRemove}
										onStatusChange={onStatusChange}
										id={user.id}
										key={user.id}
										fullName={user.fullName}
										facebookLink=""
										instagramLink={user.instagramHandle}
										tiktokLink={user.tikTokHandle}
										email={user.email}
										phoneNumber={user.phoneNumber}
										status={getCreatorStatus(user.status)}
										newCreator={newCreator}
										editable={!hasCampaignEnded}
									/>
								);
						  })}
				</tbody>
			</table>
			{users.length === 0 && (
				<div className={styles.noContent}>
					There is no creators in Creator List.
				</div>
			)}
		</div>
	);
};

export default PFCreatorListTable;
