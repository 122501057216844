import React from 'react';
import Select from 'react-select';
import {TargetGoals} from '../../../../constants/TargetGoals';
import {Goal} from '../../../../models/Goal';
import styles from './PFTargetGoalSelectMenu.module.scss';

interface PFTargetGoalSelectMenuProps {
	onChange: (retVal: string) => void;
	list: Array<Goal>;
}

export const PFTargetGoalSelectMenu: React.FC<PFTargetGoalSelectMenuProps> = ({
	onChange,
	list,
}) => {
	var validGoals = TargetGoals;
	list.map(g => (validGoals = validGoals.filter(tg => tg.label !== g.name)));
	if (
		list.filter(
			g => g.name === 'Sales' || g.name === 'Requests' || g.name === 'Sign-Ups',
		).length >= 1
	) {
		validGoals = validGoals.filter(
			g =>
				g.label !== 'Sign-Ups' && g.label !== 'Requests' && g.label !== 'Sales',
		);
	}

	const customStyles = {
		control: (provided: any) => ({
			...provided,
			border: '1px solid rgba(0, 0, 0, 0.08)',
			'&:hover': {
				cursor: 'pointer',
			},
		}),
		indicatorSeparator: (provided: any) => ({
			...provided,
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
		}),
		dropdownIndicator: (provided: any) => ({
			...provided,
			color: 'rgba(0, 0, 0, 0.6)',
		}),
	};

	return (
		<Select
		    styles={customStyles}
			className={styles.select}
			options={validGoals}
			onChange={e => onChange(e!.label)}
		/>
	);
};

export default PFTargetGoalSelectMenu;
