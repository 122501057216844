import {toast} from 'react-toastify';
import {CreatorBriefRequest} from '../../models/CreatorBriefRequest';
import {CreatorBriefResponse} from '../../models/CreatorBriefResponse';
import CreatorBriefService from '../../services/CreatorBriefService';
import {
	setCreatorBrief,
	setCreatorBriefFile,
	setLoading,
} from '../reducers/CreatorBriefReducer';

export const getCreatorBrief = (id: string) => (dispatch: any) => {
	CreatorBriefService.getCreatorBrief(id)
		.then((res: CreatorBriefResponse) => {
			dispatch(setCreatorBrief(res));
		})
		.catch(error => {
			if (error.response.data.StatusCode !== 404) {
				toast.error('Error while reading creator brief from database');
			}
		});
};

export const editCreatorBrief =
	(id: string, data: CreatorBriefRequest) => (dispatch: any) => {
		dispatch(setLoading(true));

		try {
			const hasNewFile = !data.creatorBriefFile.entries().next().done;

			CreatorBriefService.updateCreatorBrief(id, data.creatorBrief).then(
				(res: CreatorBriefResponse) => {
					dispatch(setCreatorBrief(res));
				},
			);
			if (hasNewFile) {
				CreatorBriefService.updateCreatorBriefFile(id, data.creatorBriefFile)
					.then(res => {
						dispatch(setCreatorBriefFile(res.creatorBriefUrl));
						toast.success('Creator brief saved!');
					})
					.catch(() => {
						toast.error('Error while uploading brief pdf.');
					});
			} else {
				toast.success('Creator brief saved!');
			}

			dispatch(setLoading(false));
		} catch (e) {
			toast.error('Error while reading creator brief from database');
			dispatch(setLoading(false));
		}
	};
