import React from 'react';
import {Icons} from '../../../../constants/Icons';
import styles from './PFAdminTableRow.module.scss';

interface PFAdminTableRowProps {
	onDelete: (val: number, fullName: string | undefined) => void;
	onActivate: (val: number) => void;
	fullName?: string;
	email?: string;
	id: number;
	status?: string;
}
export const PFAdminTableRow: React.FC<PFAdminTableRowProps> = ({
	onDelete,
	onActivate,
	fullName,
	email,
	id,
	status,
}) => {
	return (
		<tr className={styles.tr}>
			<td style={status === 'Deleted' ? stylesObject.deleted : {}}>
				{fullName}
			</td>
			<td style={status === 'Deleted' ? stylesObject.deleted : {}}>{email}</td>
			<td>
				<span
					className={styles.icon}
					style={stylesObject.edit}
					onClick={() =>
						status === 'Deleted' ? onActivate(id) : onDelete(id, fullName)
					}
					title={status === 'Deleted' ? 'Activate admin' : 'Delete admin'}>
					{status === 'Deleted' ? Icons.RELOAD : Icons.BIN}
				</span>
			</td>
		</tr>
	);
};

const stylesObject = {
	edit: {
		paddingLeft: '17px',
	},
	deleted: {
		color: 'rgba(234, 67, 53, 1)',
	},
};

export default PFAdminTableRow;
