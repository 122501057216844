import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CreatorBriefAsset} from '../../models/CreatorBriefAsset';
import {CreatorBriefResponse} from '../../models/CreatorBriefResponse';
import {UsageTime} from '../../models/UsageTime';

interface CreatorBriefState {
	currentCreatorBrief: CreatorBriefResponse;
	isLoading: boolean;
}

const initialState: CreatorBriefState = {
	isLoading: false,
	currentCreatorBrief: {
		creatorBrief: {
			summary: '',
			payment: '',
			platform: '',
			objective: '',
			shootTime: '',
			usageTime: new Array<UsageTime>(),
			creativeFramework: '',
			assets: new Array<CreatorBriefAsset>(),
			campaignId: -1,
		},
		creatorBriefUrl: '',
	},
};

const creatorBriefSlice = createSlice({
	initialState,
	name: 'creatorBriefReducer',
	reducers: {
		setCreatorBrief(state, {payload}: PayloadAction<CreatorBriefResponse>) {
			state.currentCreatorBrief = payload;
		},
		setCreatorBriefFile(state, {payload}: PayloadAction<string>) {
			state.currentCreatorBrief.creatorBriefUrl = payload;
		},
		setLoading(state, {payload}: PayloadAction<boolean>) {
			state.isLoading = payload;
		},
	},
});

export const {setCreatorBrief, setLoading, setCreatorBriefFile} =
	creatorBriefSlice.actions;

export default creatorBriefSlice.reducer;
