import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {CampaignTabs} from '../../../constants/CampaignTabs';
import {CampaignBrief} from '../../../models/CampaignBrief';
import {getCampaign} from '../../../store/actions/CampaignActions';
import {getCreators} from '../../../store/actions/UserActions';
import {RootState} from '../../../store/reducers/RootReducer';
import Sidebar from '../../UI/molecules/sidebar/Sidebar';
import PFCampaignHeader from '../../UI/organisms/CampaignHeader/PFCampaignHeader';
import PFCreatorList from '../../UI/organisms/CreatorList/PFCreatorList';
import styles from './CreatorListPage.module.scss';

const CreatorListPage = () => {
	const id = useParams();
	const dispatch = useDispatch<any>();

	const campaign: CampaignBrief = useSelector(
		(state: RootState) => state.campaign.currentCampaign,
	);

	useEffect(() => {
		dispatch(getCreators());
		if (id.id) {
			dispatch(getCampaign(id.id));
		}
	}, [dispatch, id.id]);

	return (
		<>
			{campaign !== undefined && (
				<div className={styles.page}>
					<div className={styles.sidebarWrapper}>
						<Sidebar activePage="campaigns" />
					</div>
					<div className={styles.contentWrapper}>
						<div className={styles.headerWrapper}>
							<PFCampaignHeader
								onClick={() => {}}
								handleSearch={() => {}}
								label="Creator List"
								currentTab={CampaignTabs.CREATOR_LIST}
							/>
						</div>
						<PFCreatorList />
					</div>
				</div>
			)}
		</>
	);
};

export default CreatorListPage;
