// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PFPrimaryButton_loginBtn__x6V7a {\n  width: 100%;\n  min-width: -moz-fit-content;\n  min-width: fit-content;\n  height: 40px;\n  padding-top: 8px;\n  padding-bottom: 10px;\n  background-color: #3560ff;\n  border: 2px solid #3560ff;\n  border-radius: 7px;\n  font-family: \"Bulldog\";\n  font-size: 14px;\n  color: white;\n}\n.PFPrimaryButton_loginBtn__x6V7a:hover {\n  cursor: pointer;\n  background-color: rgb(92, 127, 253);\n  border: 2px solid rgb(92, 127, 253);\n}\n\n.PFPrimaryButton_disabled__82cFZ {\n  opacity: 0.5;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/atoms/PrimaryButton/PFPrimaryButton.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,2BAAA;EAAA,sBAAA;EACA,YAAA;EACA,gBAAA;EACA,oBAAA;EACA,yBAAA;EACA,yBAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;EACA,YAAA;AACD;AAAC;EACC,eAAA;EACA,mCAAA;EACA,mCAAA;AAEF;;AAEA;EACC,YAAA;AACD","sourcesContent":[".loginBtn {\n\twidth: 100%;\n\tmin-width: fit-content;\n\theight: 40px;\n\tpadding-top: 8px;\n\tpadding-bottom: 10px;\n\tbackground-color: #3560ff;\n\tborder: 2px solid #3560ff;\n\tborder-radius: 7px;\n\tfont-family: 'Bulldog';\n\tfont-size: 14px;\n\tcolor: white;\n\t&:hover {\n\t\tcursor: pointer;\n\t\tbackground-color: rgb(92, 127, 253);\n\t\tborder: 2px solid rgb(92, 127, 253);\n\t}\n}\n\n.disabled{\n\topacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginBtn": "PFPrimaryButton_loginBtn__x6V7a",
	"disabled": "PFPrimaryButton_disabled__82cFZ"
};
export default ___CSS_LOADER_EXPORT___;
