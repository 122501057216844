import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface ContentPlanState {
	currentContentPlan: string;
	isLoading: boolean;
}

const initialState: ContentPlanState = {
	isLoading: false,
	currentContentPlan: '',
};

const contentPlanSlice = createSlice({
	initialState,
	name: 'contentPlanReducer',
	reducers: {
		setLoading(state, {payload}: PayloadAction<boolean>) {
			state.isLoading = payload;
		},
		setContentPlan(state, {payload}: PayloadAction<string>) {
			state.currentContentPlan = payload;
		},
	},
});

export const {setLoading, setContentPlan} = contentPlanSlice.actions;

export default contentPlanSlice.reducer;
