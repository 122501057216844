import React from 'react';
import styles from './GlobalFooter.module.scss';
import PFSelectMenu from '../../atoms/SelectMenu/PFSelectMenu';
import PFIconButton from '../../atoms/IconButton/PFIconButton';
import {Icons} from '../../../../constants/Icons';
import PFCampaignSelectMenu from '../../atoms/CampaignSelectMenu/PFCampaignSelectMenu';

interface GlobalFooterProps {
	totalData: number;
	lastRow: number;
	firstRow: number;
	rowsPerPage: number;
	disabledLeft?: boolean;
	disabledRight?: boolean;
	campaign?: boolean;
	handleSelectChange: (val: string) => void;
	handleRightClick: () => void;
	handleLeftClick: () => void;
}

const GlobalFooter: React.FC<GlobalFooterProps> = ({
	totalData,
	lastRow,
	firstRow,
	rowsPerPage,
	disabledLeft,
	disabledRight,
	campaign = false,
	handleSelectChange,
	handleRightClick,
	handleLeftClick,
}) => {
	return (
		<div>
			<div className={styles.footer}>
				<ul className={styles.itemsWrapper}>
					<li className={`${styles.labelWrapper}`}>
						Showing {firstRow}-{lastRow} of {totalData}
					</li>
					<div className={styles.divWrapper}>
						<li className={`${styles.row}`}>
							{campaign ? 'Campaigns per page' : 'Rows per page'}
						</li>
						<li className={`${styles.button}`}>
							{campaign ? (
								<PFCampaignSelectMenu
									onChange={handleSelectChange}
									defaultValue={rowsPerPage}
								/>
							) : (
								<PFSelectMenu
									onChange={handleSelectChange}
									defaultValue={rowsPerPage}
								/>
							)}
						</li>
						<li className={`${styles.btn}`}>
							<PFIconButton
								title="Previous page"
								onClick={handleLeftClick}
								clicked={false}
								type="submit"
								name="left"
								activeIcon={disabledLeft ? Icons.GRAY_LEFT : Icons.LEFT}
								inactiveIcon={disabledLeft ? Icons.GRAY_LEFT : Icons.LEFT}
								style={disabledLeft ? styles.disabledBtn : styles.buttonSides}
								disabled={disabledLeft}
							/>
						</li>
						<li className={`${styles.btn}`}>
							<PFIconButton
								title="Next page"
								onClick={handleRightClick}
								clicked={false}
								type="submit"
								name="right"
								activeIcon={Icons.RIGHT}
								inactiveIcon={Icons.RIGHT}
								style={disabledRight ? styles.disabledBtn : styles.buttonSides}
								disabled={disabledRight}
							/>
						</li>
					</div>
				</ul>
			</div>
		</div>
	);
};

export default GlobalFooter;
