import axios from 'axios';
import {NotificationPage} from '../models/NotificationPage';

interface NotificationService {
	getNotifications(
		pageNumber: number,
		pageSize: number,
	): Promise<NotificationPage>;
}

const ENDPOINTS = {
	NOTIFICATIONS: `${process.env.REACT_APP_BASE_URL}/api/Notifications/notifications`,
	HAS_NOTIFICATIONS: `${process.env.REACT_APP_BASE_URL}/api/Notifications/has-notifications`,
	READ_NOTIFICATION: `${process.env.REACT_APP_BASE_URL}/api/Notifications/notification-read`,
	READ_NOTIFICATIONS: `${process.env.REACT_APP_BASE_URL}/api/Notifications/read-all-notifications`,
};

class NotificationAxiosService implements NotificationService {
	async getNotifications(
		pageNumber: number,
		pageSize: number,
	): Promise<NotificationPage> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');

		try {
			const response = await axios.get(
				ENDPOINTS.NOTIFICATIONS +
					`?pageNumber=${pageNumber}&pageSize=${pageSize}`,
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}
	async hasNotifications(): Promise<boolean> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');

		try {
			const response = await axios.get(ENDPOINTS.HAS_NOTIFICATIONS, {
				headers: {
					Authorization: 'Bearer ' + Token,
				},
			});
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async readNotification(
		notificationId: number,
		pageNumber: number,
		pageSize: number,
	): Promise<NotificationPage> {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');

		try {
			const response = await axios.post(
				ENDPOINTS.READ_NOTIFICATION,
				{
					notificationId: notificationId,
					pageNumber: pageNumber,
					pageSize: pageSize,
				},
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
			return response.data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	async readNotifications(notificationIds: Array<number>) {
		const Token =
			sessionStorage.getItem('token') === null
				? localStorage.getItem('token')
				: sessionStorage.getItem('token');

		try {
			const response = await axios.post(
				ENDPOINTS.READ_NOTIFICATIONS,
				{
					notificationIds: notificationIds,
				},
				{
					headers: {
						Authorization: 'Bearer ' + Token,
					},
				},
			);
		} catch (error: any) {
			throw new Error(error.message);
		}
	}
}

export default new NotificationAxiosService();
